import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RiskPolicyComponent } from '../risk-policy/risk-policy.component'
import { IdVerificationComponent } from '../id-verification/id-verification.component';
import { AddressVerificationComponent } from '../address-verification/address-verification.component';
import { AmlSummaryDetailsComponent } from '../aml-summary-details/aml-summary-details.component';
import { AllDocUploadComponent } from '../all-doc-upload/all-doc-upload.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SelfieVerificationComponent } from '../selfie-verification/selfie-verification.component';
import { LivelinessVerificationComponent } from '../liveliness-verification/liveliness-verification.component';
import { ShowImageModule } from "../../directives/show-image/show-image.module";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import {NgxPaginationModule} from 'ngx-pagination';

import { QuestionaireComponent } from '../questionaire/questionaire.component';
import { NewAmlSummaryComponent } from '../new-aml-summary/new-aml-summary.component';

@NgModule({
  declarations: [RiskPolicyComponent, NewAmlSummaryComponent, IdVerificationComponent, AddressVerificationComponent, AmlSummaryDetailsComponent, AllDocUploadComponent, SelfieVerificationComponent, LivelinessVerificationComponent,QuestionaireComponent,],
  imports: [
    CommonModule,
    FormsModule,
    ShowImageModule,
    NgxPaginationModule,
    BsDatepickerModule,
    ReactiveFormsModule,
    ImageCropperModule
  ],
  exports: [RiskPolicyComponent, NewAmlSummaryComponent, IdVerificationComponent, AddressVerificationComponent, AmlSummaryDetailsComponent, AllDocUploadComponent, SelfieVerificationComponent, LivelinessVerificationComponent,QuestionaireComponent],
})
export class ComponentModuleModule { }
