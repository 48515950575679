import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../../app/services/data.service';
import { DataInteractionService } from '../../services/data-interaction.service';
import { LegalOnboardingService } from '../../services/legal-onboarding.service';
import { AddDetailsComponent } from './add-details/add-details.component';

@Component({
  selector: 'app-control-officers',
  templateUrl: './control-officers.component.html',
  styleUrls: ['./control-officers.component.scss']
})
export class ControlOfficersComponent implements OnInit {
  @Input() accountId;
  @Input() name;
  manualList: any[];
  public bsModalRef: BsModalRef;
  public modalRef: BsModalRef;
  systemList: any[];
  isUBOReport = null;
  isOutFromReport: boolean = false;
  officer: any[] = [
    { name: 'UBO' },
    { name: 'DIRECTOR' }
  ];
  addOfficersFrom: FormGroup = this.formBuilder.group({
    customerId: ['', Validators.required],
    officerType: ['', Validators.required],
  });

  constructor(private formBuilder: FormBuilder, private legalServices: LegalOnboardingService, private modalService: BsModalService,
    private dataInteraction: DataInteractionService, private dataService: DataService) { }

  ngOnInit() {
    this.legalServices.getManualUbo(this.accountId).subscribe((res) => {
      this.manualList = res;
    });
    this.legalServices.getSystemUbo(this.accountId).subscribe((res) => {
      this.systemList = res;
    });
  }

  addOfficers() {
    this.modalRef.hide()
    const payload = {
      "kysNo": this.addOfficersFrom.value.customerId,
      "officerType": this.addOfficersFrom.value.officerType
    }
    this.dataService.changeLoaderVisibility(true);
    this.legalServices.linkOfficerToLegal(this.accountId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.dataService.toastrShow(`Officer added successfully`, 'success');
      this.legalServices.getManualUbo(this.accountId).subscribe((res) => {
        this.manualList = res;
      });
    });
  }

  editDetails(item: Object) {
    const initialState = {
      accountId: this.accountId
    }
    this.dataInteraction.officers(item);
    this.bsModalRef = this.modalService.show(AddDetailsComponent, { initialState, class: 'modal-lg' });
    this.bsModalRef.content.onClose.subscribe((result) => {
      if (result == true) {
        this.legalServices.getManualUbo(this.accountId).subscribe((res) => {
          this.manualList = res;
        });
      }
    })
  }

  openDialogue(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' })
  }

  isControlOfficerOut(event) {
    if (event === 'controlOfficerOut') {
      this.isOutFromReport = true;
    }
    else {
      this.isOutFromReport = false;
    }
  }

  generateUBOs() {
    this.dataService.changeLoaderVisibility(true);
    this.legalServices.generateCorporateUbo(this.accountId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.dataService.changeLoaderVisibility(false)
      this.legalServices.getSystemUbo(this.accountId).subscribe((res) => {
        this.systemList = res;
      });
    });
  }

}
