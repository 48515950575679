export class filterArray {
  key: string;
  value: string;
}
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import { RetailOnboardingComponent } from '../retail-onboarding/retail-onboarding.component';
import { RetailOnboardingService } from '../services/retail-onboarding.service'

@Component({
  selector: 'app-retail-list',
  templateUrl: './retail-list.component.html',
  styleUrls: ['./retail-list.component.scss']
})
export class RetailListComponent implements OnInit {
  filterArray: filterArray[] = [
    { key: 'Processing', value: 'PROCESSING' },
    { key: 'Verified', value: 'VERIFIED' },
    { key: 'Failed', value: 'FAILED' },
    { key: 'Onboarded', value: 'ONBOARDED' }
  ];
  AccountfilterArray: filterArray[] = [
    { key: 'Individual', value: 'INDIVIDUAL' },
    { key: 'Joint', value: 'JOINT' },
  ];
  list: any[] = [];
  total: any;
  searchKey: any;
  retailDetails: any = null;
  overallStatus: any = null;
  isTrue: boolean = false;
  isFilterByStatus: boolean = false;
  byStatus: any = '';
  byAccount: any = '';
  isFilterByAccount: boolean;
  public bsModalRef: BsModalRef;
  array = [];
  sum: number = 5;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  start: number = 1;
  isDirectlyReport: boolean = false;
  rowPage: any[] = [
    { key: 5 },
    { key: 10 },
    { key: 15 },
    { key: 25 },
    { key: 50 },
  ]
  rowPageNo: number = 5;
  initialNumberElement: number = 1;
  secondElement: number = 5;

  constructor(private dataService: DataService, private retailServices: RetailOnboardingService, private modalService: BsModalService) { }

  ngOnInit() {
    this.getList('', this.start - 1, this.sum);
  }

  getList(status = '', page, size) {
    this.dataService.changeLoaderVisibility(true);
    this.retailServices
      .getIndividualOnboardingFilterByAccount(status, page, size)
      .pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
      .subscribe((res) => {
        this.list = res.content;
        this.total = res['totalElements'];
        if (this.total <= this.secondElement) {
          this.secondElement = this.total
        }
      });
  }

  pageChange($event) {

    if ($event == 0) {
      this.start = this.start - 1;
    }
    else {
      this.start = $event;
    }


    this.initialNumberElement = (this.start * this.sum) + 1 - this.sum
    if (this.start * this.sum > this.total) {
      this.secondElement = this.total
    }
    else {
      this.secondElement = this.start * this.sum;
    }
    this.secondElement = this.start * this.sum;
    if (this.isFilterByAccount == true) {
      this.getFilterByAccountChange(this.start - 1, this.sum)
    }
    else if (this.isFilterByStatus == true) {
      this.getFilterByChange(this.start - 1, this.sum)
    }
    else if (this.isTrue == true) {
      this.filterBySearch(this.start - 1, this.sum)
    }
    else {
      this.getList('', this.start - 1, this.sum);
    }
  }

  gotoReport() {
    this.isDirectlyReport = true;
  }

  backFromReport(event) {
    if (event === 'retailOut') {
      this.isDirectlyReport = false;
    }
    else {
      this.isDirectlyReport = true;
    }
  }

  filterBySearch(start, page) {
    this.isTrue = true
    const payload = {
      "name": this.searchKey,
      "page": start,
      "size": page,
      "orderBy": null,
      "order": 'DESC'
    }
    this.retailServices.retailFilterBySearch(payload).subscribe((res) => {
      this.list = res.content;
      this.total = res['totalElements'];

    })
  }

  getFilter(filter) {
    this.isFilterByStatus = true;
    this.isFilterByAccount = false;
    this.byStatus = filter
    this.start = 0;
    this.retailServices
      .getIndividualOnboardingFilterByStatus(filter, this.start, this.sum)
      .pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
      .subscribe((res) => {
        this.list = res.content;
        this.total = res['totalElements'];
      });
  }

  getFilterByChange(page, sum) {
    this.retailServices
      .getIndividualOnboardingFilterByStatus(this.byStatus, page - 1, sum)
      .pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
      .subscribe((res) => {
        this.list = res.content;
        this.total = res['totalElements'];
      });
  }

  getFilterByAccount(filter) {
    this.isFilterByAccount = true;
    this.isFilterByStatus = false
    this.byAccount = filter
    this.retailServices
      .getIndividualOnboardingFilterAccount(filter, this.start, this.sum)
      .pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
      .subscribe((res) => {
        this.list = res.content;
        this.total = res['totalElements'];
      });
  }

  getFilterByAccountChange(page, sum) {
    this.retailServices
      .getIndividualOnboardingFilterAccount(this.byAccount, page, sum)
      .pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
      .subscribe((res) => {
        this.list = res.content;
        this.total = res['totalElements'];
      });
  }

  clearAll() {
    this.start = 0;
    this.sum = 5;
    this.initialNumberElement = 1;
    this.secondElement = 5
    this.searchKey = ''
    this.getList('', this.start, this.sum);
    this.filterArray = new Array(
      { key: 'Processing', value: 'PROCESSING' },
      { key: 'Verified', value: 'VERIFIED' },
      { key: 'Failed', value: 'FAILED' },
      { key: 'Onboarded', value: 'ONBOARDED' }
    );
    this.AccountfilterArray = new Array(
      { key: 'Individual', value: 'INDIVIDUAL' },
      { key: 'Joint', value: 'JOINT' },
    );
  }

  rowPageSet(rowPageNo) {
    this.rowPageNo = Number(rowPageNo);
    this.sum = rowPageNo;
    this.initialNumberElement = (this.start - 1) * this.rowPageNo + 1;
    this.secondElement = this.initialNumberElement + this.rowPageNo - 1
    if (this.isFilterByAccount == true) {
      this.getFilterByAccountChange(this.start - 1, this.sum)
    }
    else if (this.isFilterByStatus == true) {
      this.getFilterByChange(this.start - 1, this.sum)
    }
    else if (this.isTrue == true) {
      this.filterBySearch(this.start - 1, this.sum)
    }
    else {
      this.getList('', this.start - 1, this.sum);
    }
  }

}


