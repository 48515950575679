import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ApiUploadService } from '../api-upload.service'
import { from, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

export interface UploadFile {
    name: string;
    file: File;
    status: 'started' | 'completed' | 'pending' | 'error';
}

@Component({
  selector: 'app-api-upload-view',
  templateUrl: './api-upload-view.component.html',
  styleUrls: ['./api-upload-view.component.scss']
})
export class ApiUploadViewComponent implements OnInit {
  files$ = new Subject<UploadFile[]>();
  private queue: UploadFile[] = [];

  constructor(private apiUploadService: ApiUploadService, private dataService: DataService,) { }

  ngOnInit() {
  }


  addFilesToQueue(files: any[]) {
    const _file = files.map((file) => <UploadFile>{ name: file.name, status: 'pending', file });
    this.queue.push(..._file);
    this.updateFiles();
  }

  updateFiles() {
      this.files$.next(this.queue);
  }

  processQueue() {
      const pending = this.queue.find(({ status }) => status === 'pending');
      if (pending) {
          this.processFile(pending);
      }
  }

  processFile(uploadFile: UploadFile) {
      uploadFile.status = 'started';
      this.updateFiles();

      from(this.fileToJSON(uploadFile.file))
          .pipe(switchMap((file) => this.apiUploadService.bulkOnBoardUser(file)))
          .subscribe(
              () => {
                  uploadFile.status = 'completed';
                  this.updateFiles();
                  this.processQueue();
              },
              () => {
                  uploadFile.status = 'error';
                  this.updateFiles();
                  this.processQueue();
              }
          );
  }

  getFileDetails(e) {
      if (e.target.files.length > 10) {
          alert('Only 10 files accepted at a time');
          return;
      }

      this.addFilesToQueue(Array.from(e.target.files));
      this.processQueue();
  }

  fileToJSON(file): Promise<Object> {
      return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = (event: any) => resolve(JSON.parse(event.target.result));
          fileReader.onerror = (error) => reject(error);
          fileReader.readAsText(file);
      });
  }

}
