export class BankDocumentInfo {
  key: string;
  value: string;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KycModalComponent } from '../../../../../shared/kyc-modal/kyc-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ManualOnboardingService } from 'src/app/services/manual-onboarding.service';
import { LegalOnboardingService } from '../../../services/legal-onboarding.service';

@Component({
  selector: 'app-corp-docs',
  templateUrl: './corp-docs.component.html',
  styleUrls: ['./corp-docs.component.scss']
})
export class CorpDocsComponent implements OnInit {
  @Input() state: any;
  @Output() step = new EventEmitter<any>();
  countryCode: any;
  states: any[];
  getAllDocuments: any;
  companyData: any;
  @Input() accountId: any;
  activeCard: any;
  docName: any;
  showUpload: boolean = false;
  isTrue: boolean = false;

  docArray: BankDocumentInfo[] = [
    { key: "LEGAL", value: 'LEGAL' },
    { key: "FINANCE", value: 'FINANCE' },
    { key: "CORPORATE", value: 'CORPORATE' },
  ];
  docNameArray: any;
  uploadDocument: any;

  docForm: FormGroup = this.formBuilder.group({
    documentNo: [''],
    documentName: [''],
    stateCode: ['']
  });
  docType: any;
  checkId: any;
  public bsModalRef: BsModalRef;

  constructor(private legalService: LegalOnboardingService,
    public router: Router, private formBuilder: FormBuilder,
    private manualService: ManualOnboardingService, private modalService: BsModalService) { }

  ngOnInit() {
    const initialState = {
      title: `Doc Info`,
      type: "doc-info",
      docMsg: "All the documents are not mandatory."
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-sm",
    });
    
    this.legalService.getAllChecks(this.accountId).subscribe((res) => {
      this.getAllDocuments = res
    });
    this.legalService.getAllInfo(this.accountId).subscribe((res) => {
      this.companyData = res;
      this.countryCode = res.country.countryCode
      this.legalService.getAllProvince(this.countryCode).subscribe((res) => {
        this.states = res
      });
    });

  }


  gotoNext() {
    let state = {
      step: 3
    }
    this.step.emit(state);
  }

  gotoBack() {
    let state = {
      step: 1
    }
    this.step.emit(state);
  }

  uploadBack(event) {
    this.showUpload = false;
    this.isTrue = true;
    this.legalService.getAllChecks(this.accountId).subscribe((res) => {
      this.getAllDocuments = res
    });
  }

  uploadedDoc(event) {
    console.log("upload event: ", event);
  }

  setStateAsActive(state) {
    this.docType = state.docType;
    this.uploadDocument = state;
    this.docName = state.docName;
    this.activeCard = state.docName;
  }

  getCheckId(checkId) {
    this.checkId = checkId;
  }

  gotoComp(comp) {
    console.log(comp);
    this.showUpload = true;
  }

  checkType(type) {
    this.docType = type;
    this.isTrue = false;
    this.manualService.getDocName(this.countryCode, type).subscribe((res) => {
      this.docNameArray = res
    })
  }

  checkName(docName) {
    let selDoc = this.docNameArray.find((item) => item.docName == docName);
    this.isTrue = true
    this.uploadDocument = selDoc;
    this.docName = selDoc.docName;
  }

  openUploadModal(template) {
    this.bsModalRef = this.modalService.show(template, {
      class: 'modal-lg',
      keyboard: false,
      backdrop: 'static'
    });
  }
}
