export class filterArray {
  key: string;
  value: string;
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../services/data.service';
import { DevOpsService } from '../../services/dev-ops.service';

@Component({
  selector: 'app-retail-list',
  templateUrl: './retail-list.component.html',
  styleUrls: ['./retail-list.component.scss']
})
export class RetailListComponent implements OnInit {
  filterArray: filterArray[] = [
    { key: 'Processing', value: 'PROCESSING' },
    { key: 'Verified', value: 'VERIFIED' },
    { key: 'Failed', value: 'FAILED' },
];
AccountfilterArray: filterArray[] = [
    { key: 'Individual', value: 'INDIVIDUAL' },
    { key: 'Joint', value: 'JOINT' },
];
  list: any;
  total: any;
  page = 0;
  itemsPerPage = 10;
  maxSize = 5;
  jump: any;
  searchKey: any;
  isTrue: boolean = false;
  isFilterByStatus: boolean = false;
  byStatus: any = '';
  byAccount: any = '';
  isFilterByAccount: boolean;
  public bsModalRef: BsModalRef;
  clientId: any;
  userName: any;

  constructor(private dataService: DataService, private devOpsService: DevOpsService, private modalService: BsModalService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(({ clientID, userName }) => {
      this.clientId = clientID;
      this.userName = userName;
    })
    this.getList('', this.page, this.itemsPerPage);
  }

  getList(status = '', page, size) {
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService
        .getIndividualOnboardingFilterByAccount(this.clientId,status, page, size)
        .pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
        .subscribe((res) => {
            this.list = res.content;
            this.total = res.totalElements;
        });
  }

  filterBySearchByChange(page, itemsPerPage){
    const payload = {
        "name": this.searchKey,
        "page": page,
        "size": itemsPerPage,
        "orderBy": null,
        "order": 'DESC'
    }
    this.devOpsService.retailFilterBySearch(this.clientId, payload).subscribe((res) =>{
        this.list = res.content;
        this.total = res.totalElements;
    })
    
    
  }

  filterBySearch(key){
    this.searchKey = key
    this.isTrue = true
    const payload = {
        "name": this.searchKey,
        "page": 0,
        "size": 10,
        "orderBy": null,
        "order": 'DESC'
    }
    this.devOpsService.retailFilterBySearch(this.clientId, payload).subscribe((res) =>{
        this.list = res.content;
        this.total = res.totalElements;
    })
  }

  getFilter(filter) {
    this.isFilterByStatus = true;
    this.isFilterByAccount = false;
    this.byStatus = filter
    this.devOpsService
        .getIndividualOnboardingFilterByStatus(this.clientId, filter, this.page, this.itemsPerPage)
        .pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
        .subscribe((res) => {
            this.list = res.content;
            console.log('List: ', this.list);
            this.total = res.totalElements;
        });
  }

  getFilterByChange(page, itemsPerPage) {
    this.devOpsService
        .getIndividualOnboardingFilterByStatus(this.clientId, this.byStatus, page, itemsPerPage)
        .pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
        .subscribe((res) => {
            this.list = res.content;
            console.log('List: ', this.list);
            this.total = res.totalElements;
        });
  }

  getFilterByAccount(filter) {
    this.isFilterByAccount = true;
    this.isFilterByStatus = false
    this.byAccount = filter
    this.devOpsService
        .getIndividualOnboardingFilterAccount(this.clientId, filter, this.page, this.itemsPerPage)
        .pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
        .subscribe((res) => {
            this.list = res.content;
            console.log('List: ', this.list);
            this.total = res.totalElements;
        });
  }

  getFilterByAccountChange(page, itemsPerPage) {
    this.devOpsService
        .getIndividualOnboardingFilterAccount(this.clientId, this.byAccount, page, itemsPerPage)
        .pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
        .subscribe((res) => {
            this.list = res.content;
            console.log('List: ', this.list);
            this.total = res.totalElements;
        });
  }

  onPageChange({ page, itemsPerPage }: PageChangedEvent) {
    this.itemsPerPage = itemsPerPage;
    this.page = page-1;
    if(this.isTrue){
      this.filterBySearchByChange(this.page, this.itemsPerPage)
    }else if(this.isFilterByStatus){
      this.getFilterByChange(this.page, this.itemsPerPage)
    }else if(this.isFilterByAccount){
      this.getFilterByAccountChange(this.page, this.itemsPerPage)
    }else{
      this.getList('', this.page, this.itemsPerPage);
    }
  }

  jumpTo(){
    this.page = this.jump-1
    if(this.isTrue){
      this.filterBySearchByChange(this.jump, this.itemsPerPage)
    }else if(this.isFilterByStatus){
      this.getFilterByChange(this.jump, this.itemsPerPage)
    }else if(this.isFilterByAccount){
      this.getFilterByAccountChange(this.jump, this.itemsPerPage)
    }else{
      this.getList('', this.jump, this.itemsPerPage);
    }
  }

  clearAll(){
    this.jump = '';
    this.page = 0;
    this.byStatus = '';
    this.byAccount = '';
    this.isFilterByStatus = false;
    this.isFilterByAccount = false;
    this.getList('', this.jump, this.itemsPerPage)
  }

}


