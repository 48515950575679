import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../../../../services/app.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../services/data.service';
import { LegalOnboardingService } from '../../services/legal-onboarding.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  list: any[] = [];
  viewDoc: any = null;
  corpDoc: any = null;
  showUpload: boolean = false;
  activeCard: any;
  indexCard: any;
  docName: any;
  uploadDoc: any;
  docType: any;
  checkId: any;
  states: any[] = [];
  @Input() accountId;
  docListArray: any[] = [];
  currentTheme: string = localStorage.getItem('currentTheme');

  public bsModalRef: BsModalRef;

  constructor(private legalServices: LegalOnboardingService, private activatedRoute: ActivatedRoute, 
    private dataService: DataService,private modalService: BsModalService, private _app: AppService) { }

  ngOnInit() {
    this.legalServices.getDocList(this.accountId).subscribe((res) => {
      this.list = res;
      this.list.forEach((element) => {
        if(element.status !== 'CREATED' && element.docType !== 'LIVELINESS' && element.docType !== 'SELFIE'){
          this.docListArray.push(element);
        }
      });
    });
    this._app.currentTheme.subscribe(theme => {
      this.currentTheme = theme;
    });
  }

  reVerifyDocument(item) {
    if (item.docType === 'IDENTITY' || item.docType === 'ADDRESS') {
      this.dataService.changeLoaderVisibility(true);
      this.legalServices.reverifyDocument(item.checkId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
        this.dataService.toastrShow(`Re-verification initiated`, 'success');
      });
    }
    else {
      this.dataService.toastrShow(`Please select only ID or Address document to initiate Re-verification process`, 'warning');
    }
  }

  uploadBack(event) {
    this.docListArray = []
    this.showUpload = false;
    this.legalServices.getDocList(this.accountId).subscribe((res) => {
      this.list = res;
      this.list.forEach((element) => {
        if(element.status !== 'CREATED' && element.docType !== 'LIVELINESS' && element.docType !== 'SELFIE'){
          this.docListArray.push(element);
        }
      });
    })
  }

  uploadedDoc(event) {
    console.log("upload event: ", event);
  }

  setStateAsActive(state) {
    console.log(state);
    this.docName = state.docName;
    this.uploadDoc = state;
    this.docType = state.docType;
    if (state.tpe !== null && state.tpe !== undefined) {
      this.legalServices.getIndividualInformationByKysNo(state.tpe.kysNo).subscribe((data) => {
        this.legalServices.getAllStates(data.country.countryCode).subscribe((res) => this.states = res);
      });
    }
  }

  getCheckId(checkId) {
    this.showUpload = true;
    this.checkId = checkId;
  }
  openUploadModal(template){
    this.bsModalRef = this.modalService.show(template, { 
      class: 'modal-lg',
      keyboard:false,
      backdrop:'static' 
    });
  }

}
