import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RetailOnboardingService } from '../../services/retail-onboarding.service';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit {
  accountInfo: any[];
  primaryData: any[];
  docData: any[];
  secondaryData: any[];
  docDataSecondary: any[];
  @Input() accountId;
  @Input() activityLog?: boolean = false;

  constructor(private retailServices: RetailOnboardingService, private activatedRoute: ActivatedRoute) { }

  ngOnChanges(){
  }

  ngOnInit() {
      this.retailServices.getAccountInfoById(this.accountId).subscribe((res) => this.accountInfo = res);
      this.retailServices.getIndividualInfoByAccountID(this.accountId).subscribe((res) => {
        if(res[0]!==undefined && res[0].length !== 0){
          this.primaryData = res[0]
        }
        if(res[1]!==undefined && res[1].length !== 0){
          this.secondaryData = res[1]
        }
      });
      this.retailServices.getdigitalUploadList(this.accountId).subscribe((res) => {
        for(let item of res){
          if(item.docType === 'IDENTITY'){
            if(item.tpe !== null && item.tpe !== undefined){
              if(item.tpe.kysTpeAccount === 'PRIMARY'){
                this.docData = item
              }
              if(item.tpe.kysTpeAccount === 'SECONDARY'){
                this.docDataSecondary = item
              }
            }
          }
        }
      });
  }

}
