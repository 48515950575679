import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LegalOnboardingService } from '../services/legal-onboarding.service';
import { AppService } from '../../../services/app.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-overall-status',
  templateUrl: './overall-status.component.html',
  styleUrls: ['./overall-status.component.scss']
})
export class OverallStatusComponent implements OnInit {
  accountId: any;
  name: any;
  docInfo: any;
  overAllStatus: any;
  controlOfficersList: any = [];
  officerType: any = 'Control Officers';
  list: any[];
  isType: boolean = false;
  officersList: any;
  officersDueDiligenceType: any = '';
  officersOnboardDate: any = '';
  isName: boolean = false;
  storeValueOfOfficerType: any;
  controlOfficersDocList: any[] = [];
  isDocType: boolean = false;
  anyOfficerFound: boolean = false;
  @Input() item;
  @Output() close = new EventEmitter<any>();
  verificationAvailabilty: any[] = [
    { name: 'Adress Verification', check: 'NA' },
    { name: 'Selfie Check', check: 'NA' },
    { name: 'Liveliness Check', check: 'NA' },
    { name: 'Additional Documents', check: 'NA' }
  ];
  docListArray: any[] = [];
  checkListArray: any = [];
  currentTheme: string=localStorage.getItem('currentTheme');
  numberData: number = 0;
  score$: Observable<number>;
  riskData: any;

  constructor(private legalServices: LegalOnboardingService, private activatedRoute: ActivatedRoute, public app: AppService) { }

  ngOnInit() {
    this.app.currentTheme.subscribe(theme=>{
      this.currentTheme=theme;
    })
    this.accountId = this.item.account.accountId;
    this.legalServices.getLegalRiskScore(this.accountId).subscribe((res) => {
      if (res) {
        this.riskData = res;
      }
    });
    this.legalServices.getDocumentsInfo(this.accountId).subscribe((res) => this.docInfo = res);
    this.legalServices.getOverallStatus(this.accountId).subscribe((res) => this.overAllStatus = res);
    this.score$ = this.legalServices.getOverAllScore(this.accountId);
    this.legalServices.getCorporateDetails(this.accountId).subscribe((res) => {
      if (res.controlOfficer.length !== 0 && res.controlOfficer !== null && res.controlOfficer !== undefined) {
        this.anyOfficerFound =  true;
        this.controlOfficersList = res.controlOfficer  || [];
      }
      for(let item of this.controlOfficersList){
        this.numberData +=1;
        this.getIndexValue(this.numberData-1);
      }
      if (res.entityDocuments.length !== 0 && res.entityDocuments !== null && res.entityDocuments !== undefined) {
        this.list = res.entityDocuments;
        this.list.forEach((element) => {
          if(element.status !== 'CREATED'){
            this.docListArray.push(element)
          }
        });
      }
    });
  }

  getIndexValue(i){
    if(this.checkListArray.indexOf(i) > -1){
      this.checkListArray.splice(this.checkListArray.indexOf(i), 1)
    }
    else{
      this.checkListArray.push(i)
    }    
  }

  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.close.emit();
  }

  getOfficerType(officerType) {
    this.isDocType = false
    this.isType = true;
    this.isName = false;
    this.officerType = officerType;
    this.storeValueOfOfficerType = officerType;
    this.legalServices.getCorporateDetails(this.accountId).subscribe((res) => {
      if (res.controlOfficer.length !== 0 && res.controlOfficer !== null && res.controlOfficer !== undefined) {
        for (let item of res.controlOfficer) {
          if (item.type === this.officerType) {
            this.officersList = item.list
          }
        }
      }
    });
  }

  getOfficer(officers) {
    this.isType = false;
    this.isName = true;
    this.officerType = officers
    this.legalServices.getCorporateDetails(this.accountId).subscribe((res) => {
      if (res.controlOfficer.length !== 0 && res.controlOfficer !== null && res.controlOfficer !== undefined) {
        for (let data of res.controlOfficer) {
          if (data.type === this.storeValueOfOfficerType) {
            for (let item of data.list) {
              if (item.name === officers) {
                this.officersDueDiligenceType = item.dueDiligenceType
                this.officersOnboardDate = item.onBoardingDate
                this.controlOfficersDocList = item.entries
              }
            }
          }
        }
      }
    });
  }

  getDocList() {
    this.isDocType = true;
  }

}
