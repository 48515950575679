import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from '../../../../../services/data.service';
import { finalize } from 'rxjs/operators';
import { LegalOnboardingService } from '../../../services/legal-onboarding.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-officers',
  templateUrl: './add-officers.component.html',
  styleUrls: ['./add-officers.component.scss']
})
export class AddOfficersComponent implements OnInit {
  @Input() state: any;
  @Output() step = new EventEmitter<any>();
  @Output() submit = new EventEmitter<any>();
  companyData: any;
  officer: any[] = [
    { name: 'UBO' },
    { name: 'DIRECTOR' }
  ]
  public bsModalRef: BsModalRef;
  addOfficersFrom: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    officerType: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]]
  });
  officerType: any;
  @Input() accountId: any;
  list: any[] = new Array();

  constructor(private legalService: LegalOnboardingService, private formBuilder: FormBuilder,
    private modalService: BsModalService, private dataService: DataService, private route: Router) { }

  ngOnInit() {
    this.legalService.getAllInfo(this.accountId).subscribe((res) => {
      this.companyData = res;
    });
    this.legalService.getOfficersList(this.accountId).subscribe((res) => {
      if (res.DIRECTOR) {
        for (let director of res.DIRECTOR) {
          this.list.push(director);
        }
      }
      if (res.UBO) {
        for (let ubo of res.UBO) {
          this.list.push(ubo);
        }
      }
    });
  }

  add(template) {
    this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
    this.addOfficersFrom.get('name').setValue('');
    this.addOfficersFrom.get('email').setValue('');
  }

  onSelectOfficerType(officer) {
    this.officerType = officer
  }

  addNewOfficers() {
    this.bsModalRef.hide();
    const payload = {
      "firstName": this.addOfficersFrom.value.name,
      "lastName": null,
      "controlOfficerType": this.officerType,
      "maritalStatus": null,
      "dob": null,
      "phoneNumber": null,
      "email": this.addOfficersFrom.value.email,
      "residenceAddress": null,
      "ssn": null,
      "taxId": null,
      "percentageOfOwnership": null,
      "currentStatus": null,
      "designation": null
    }
    this.legalService.addOfficers(this.accountId, payload).subscribe((res) => {
      let popup = {
        message: this.officerType + " Added",
        status: 'success'
      }
      this.dataService.toastrShow(popup.message, popup.status);
      let state = {
        state: {
          name: res.firstName,
          email: res.email,
          id: res.id,
          type: res.controlOfficerType
        },
        step: 4
      }
      this.step.emit(state);
      const payload = {
        "message": state.state['name'] + ' has been added as ' + this.officerType,
        "page": "Officer Add",
        "geoLocation": null
      }
      this.legalService.addActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Officer Add",
          "geoLocation": null
        }
        this.legalService.addActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    )
  }

  gotoBack() {
    let state = {
      step: 2
    }
    this.step.emit(state);
  }

  finalSubmit() {
    let obj;
    this.dataService.changeLoaderVisibility(true);
    this.legalService.corpSubmit(this.accountId, obj).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.dataService.toastrShow(`Submitted Successfully`, 'success');
      this.submit.emit('goToList');
      const payload = {
        "message": 'Company Details have been submitted successfully',
        "page": "Corporate Final Submit",
        "geoLocation": null
      }
      this.legalService.addActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Corporate Final Submit",
          "geoLocation": null
        }
        this.legalService.addActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    )
  }

}
