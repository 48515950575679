import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import { Observable } from 'rxjs';
// import { identifierModuleUrl } from '@angular/compiler';
@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  token: any;
  constructor(private http: HttpClient, private _data: DataService, @Inject('API_URL') public KYS_API_URL: any) {
      this.token = this._data.getCookiesByName('token');
      console.log(this.token);
  }
  overrideRiskReport(obj){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/ir/dashboard/override`, obj).pipe(map((res: any) => res.data));
  }
  getIrResult(obj){  
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/ir/dashboard/filter`, obj).pipe(
        map((res: any) => res.data
        ));
  }
  intermediateRiskSearch(obj){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/ir/dashboard/fincen/search`, obj).pipe(map((res: any) => res.data));
  }
  getIrOtherResult(obj){  
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/ir/dashboard/source/filter`, obj).pipe(
        map((res: any) => res.data
        ));
  }
  getAllKysCountries() {
    return this.http.get<any>(`${this.KYS_API_URL}/country/all `).pipe(
        map((res) => {
            return res;
        })
    );
  }
  getQuickRiskHistory(obj){ 
      return this.http.post(`${this.KYS_API_URL}/kys/v2.0/ir/dashboard/quick/risk-check/history`, obj).pipe(
          map((res: any) => res.data
          ));
  }
  quickRiskReport(obj){
      return this.http.post(`${this.KYS_API_URL}/kys/v2.0/ir/dashboard/quick/risk-check`, obj).pipe(
          map((res: any) => res.data
          ));
  }
  overrideDocExtraction(id, id2,obj){
      return this.http.post(`${this.KYS_API_URL}/kys/v2.0/devops/override-doc-extraction/${id}/${id2}`, obj).pipe(
          map((res: any) => res.data
          ));
  }

  //report page
  ittsArticle(id){
    return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/article/${id}`).pipe(map((res: any) => res.data));
  }

  csvFileUpload(body){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/ir/dashboard/fincen/upload`, body);
  }

  fincenHistory(page: number, itemsPerPage: number){
      return this.http.post(`${this.KYS_API_URL}/kys/v2.0/ir/dashboard/potential`, {
          "size" : itemsPerPage,
          "page" : page,
          "order":"DESC"
      }).pipe(map((res: any) => res.data));
  }

}
