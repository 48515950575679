import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RiskReportRoutingModule } from './risk-report-routing.module';
import { RiskReportComponent } from './risk-report.component';
import { PartialMatchListComponent } from './partial-match-list/partial-match-list.component';
import { ReportComponent } from './report/report.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { QuickIndivisualRiskComponent } from './quick-indivisual-risk/quick-indivisual-risk.component';
import { HistoryComponent } from './history/history.component';
import { FincenComponent } from './fincen/fincen.component';
import { UploadCsvComponent } from './upload-csv/upload-csv.component';

@NgModule({
  declarations: [RiskReportComponent, PartialMatchListComponent, ReportComponent, QuickIndivisualRiskComponent, HistoryComponent, FincenComponent, UploadCsvComponent],
  imports: [
    CommonModule,
    RiskReportRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
    TabsModule.forRoot()
  ],
  entryComponents: [UploadCsvComponent],
})
export class RiskReportModule { }
