import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LegalOnboardingService } from '../../../services/legal-onboarding.service';

@Component({
  selector: 'app-ubo-report',
  templateUrl: './ubo-report.component.html',
  styleUrls: ['./ubo-report.component.scss']
})
export class UboReportComponent implements OnInit {
  @Input() item;
  @Output() close = new EventEmitter<any>();
  baselIndex: any = "The Basel AML Index is an independent annual ranking that assesses risks of money laundering and terrorist financing (ML/TF) around the world.Published by the Basel Institute on Governance since 2012, the Basel AML Index provides risk scores based on data from 17 publicly available sources such as the Financial Action Task Force (FATF), Transparency International, the World Bank and the World Economic Forum.";
  entityName: string;
  firstName: string = '';
  lastName: string = '';
  checkAMLType: any = ['CRIME', 'PEP'];
  pepDetails: any[] = new Array();
  pepLength: number = 0;
  crimeDetails: any[] = new Array();
  sancDetails: any[] = new Array();
  crimeLength: number = 0;
  sancLength: number = 0;
  adverseMediaArray: any[] = new Array();
  tagsLength: number = 0;
  getAllCheckData: any;
  uboAddress: any = [];
  uboID: any = [];
  uboSelfie: any = [];
  uboLiveliness: any = [];
  identityCheck: any = [];
  idNumberData: number = 0;
  addressNumberData: number = 0;
  addressCheck: any = [];
  selfieNumberData: number = 0;
  selfieCheck: any = [];
  livelinessNumberData: number = 0;
  livelinessCheck: any = [];
  crimeDetailsCorp:any=[];
  sancDetailsCorp:any=[];
  constructor(private legalService: LegalOnboardingService) { }

  amlCheck:boolean= true;
  adverseMediaCheck:boolean=  true;
  nationalityRisk:boolean=  true;
  idDoc:boolean=  true;
  addressDoc:boolean=  true;
  selfie:boolean=  true;
  liveliness:boolean=  true;

  ngOnInit(): void {
    if (this.item) {
      this.getAccessList();
      this.legalService.getAllCheckForUBO(this.item.id).subscribe((res) => {
        this.getAllCheckData = res;
        if (res.amlChecks.length > 0) {
          // AML Summary
          if (res.amlChecks[0].amlChecks && res.amlChecks[0].amlChecks !== null && res.amlChecks[0].amlChecks !== undefined) {
            for (let item of res.amlChecks[0].amlChecks) {
              if (item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'PEP') {
                for (let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details) {
                  this.pepDetails.push(pep);
                  for(let allPep of pep.category){
                    if (allPep.name.toUpperCase() === 'PEP') {
                      this.pepLength += 1;
                    }
                  }
                  
                  
                }
              }
              if (item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'CRIME') {
                for (let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details) {
                  this.crimeDetails.push(pep);
                  for(let allPep of pep.category){
                    if (allPep.name.toUpperCase() === 'CRIME') {
                      this.crimeLength += 1;
                    }
                  }

                  
                }
              }
              if (item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'SANCTION') {
                for (let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details) {
                  this.sancDetails.push(pep);
                  this.sancLength += 1;
                }
              }
            }
          }

          // Adverse Media
          if (res.amlChecks[0].adversemediaCheck.length !== 0
            && res.amlChecks[0].adversemediaCheck.length !== null
            && res.amlChecks[0].adversemediaCheck.length !== undefined) {
            if (res.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData !== null) {
              if (res.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags.length !== 0) {
                const adverseDataCorporate = res.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags;
                this.adverseMediaArray.push(adverseDataCorporate);
                this.tagsLength = adverseDataCorporate.length
              }
            }
          }
        }

        if (res.idvChecks.length > 0) {
          // Identity Check
          if (res.idvChecks[0].IDENTITY) {
            this.identityCheck = res.idvChecks[0].IDENTITY;
            for (let item of res.idvChecks[0].IDENTITY) {
              this.idNumberData += 1;
              this.getIndexValueForID(this.idNumberData - 1);
            }
          }
          // Address Check
          if (res.idvChecks[0].ADDRESS) {
            this.addressCheck = res.idvChecks[0].ADDRESS;
            for (let item of res.idvChecks[0].ADDRESS) {
              this.addressNumberData += 1;
              this.getIndexValueForAddress(this.addressNumberData - 1);
            }
          }
          // Selfie Check
          if (res.idvChecks[0].SELFIE) {
            this.selfieCheck = res.idvChecks[0].SELFIE;
            for (let item of res.idvChecks[0].SELFIE) {
              this.selfieNumberData += 1;
              this.getIndexValueForSelfie(this.selfieNumberData - 1);
            }
          }

          // Liveliness Check
          if (res.idvChecks[0].LIVELINESS) {
            this.livelinessCheck = res.idvChecks[0].LIVELINESS;
            console.log(this.livelinessCheck);
            
            for (let item of res.idvChecks[0].LIVELINESS) {
              this.livelinessNumberData += 1;
              this.getIndexValueForLiveliness(this.livelinessNumberData - 1);
            }
          }
        }
      });
      if (this.item.firstName !== null) {
        this.firstName = this.item.firstName;
      }
      if (this.item.lastName !== null) {
        this.lastName = this.item.lastName;
      }
      this.entityName = this.firstName + ' ' + this.lastName;
    }
  }

  getIndexValueForAddress(i) {
    if (this.uboAddress.indexOf(i) > -1) {
      this.uboAddress.splice(this.uboAddress.indexOf(i), 1)
    }
    else {
      this.uboAddress.push(i)
    }
  }

  getIndexValueForSelfie(i) {
    if (this.uboSelfie.indexOf(i) > -1) {
      this.uboSelfie.splice(this.uboSelfie.indexOf(i), 1)
    }
    else {
      this.uboSelfie.push(i)
    }
  }

  getIndexValueForLiveliness(i) {
    if (this.uboLiveliness.indexOf(i) > -1) {
      this.uboLiveliness.splice(this.uboLiveliness.indexOf(i), 1)
    }
    else {
      this.uboLiveliness.push(i)
    }
  }

  getIndexValueForID(i) {
    if (this.uboID.indexOf(i) > -1) {
      this.uboID.splice(this.uboID.indexOf(i), 1)
    }
    else {
      this.uboID.push(i)
    }
  }

  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.close.emit('controlOfficerOut');
  }

  
  getAccessList(){
    this.legalService.getAccessList(this.item.companyTpe.account.accountId).subscribe((res:any)=>{
      if(res.data!==null){
        if(this.item.tpe.kysTpeAccount=="UBO"){
          this.amlCheck= res.data.uboCheckContainer.amlCheck;
          this.adverseMediaCheck= res.data.uboCheckContainer.adverseMediaCheck;
          this.nationalityRisk= res.data.uboCheckContainer.nationalityRisk;
          this.idDoc= res.data.uboCheckContainer.idvCheckContainer.idvCheckList[0].idDoc;
          this.addressDoc= res.data.uboCheckContainer.idvCheckContainer.idvCheckList[0].addressDoc;
          this.selfie= res.data.uboCheckContainer.idvCheckContainer.idvCheckList[0].selfie;
          this.liveliness= res.data.uboCheckContainer.idvCheckContainer.idvCheckList[0].liveliness;
        }else{
          this.amlCheck= res.data.directorCheckContainer.amlCheck;
          this.adverseMediaCheck= res.data.directorCheckContainer.adverseMediaCheck;
          this.nationalityRisk= res.data.directorCheckContainer.nationalityRisk;
          this.idDoc= res.data.directorCheckContainer.idvCheckContainer.idvCheckList[0].idDoc;
          this.addressDoc= res.data.directorCheckContainer.idvCheckContainer.idvCheckList[0].addressDoc;
          this.selfie= res.data.directorCheckContainer.idvCheckContainer.idvCheckList[0].selfie;
          this.liveliness= res.data.uboCheckContainer.idvCheckContainer.idvCheckList[0].liveliness;
        }
        
      }
    })
  }
}
