import { Component, Input, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../services/data.service';
import { LegalOnboardingService } from '../../services/legal-onboarding.service';
import { AppService } from '../../../../services/app.service';

@Component({
  selector: 'app-legal-report',
  templateUrl: './legal-report.component.html',
  styleUrls: ['./legal-report.component.scss']
})
export class LegalReportComponent implements OnInit {
  primaryData: any;
  summaryInfo: any;
  manualList: any[];
  list: any[];
  checkAMLType: any = ['CRIME', 'PEP'];
  pepDetails: any[] = new Array();
  pepLength: number = 0;
  crimeDetails: any[] = new Array();
  sancDetails: any[] = new Array();
  crimeDetailsCorp: any[] = new Array();
  sancDetailsCorp: any[] = new Array();
  crimeLength: number = 0;
  sancLength: number = 0;
  isTagFound: boolean = false;
  tagsLength: number = 0;
  adverseDataUBO: any;
  adverseMediaArray: any[] = new Array();
  adverseDataDirector: any;
  adverseDataCorporate: any;
  riskStatus: any = 'LOW';
  riskScore: number = 0;
  @Input() accountID;
  docListArray: any[] = [];
  currentTheme: string = localStorage.getItem('currentTheme');
  adverseMediaScore: number = 0;
  adverseMediaInteliigenceScore: number = 0;
  articledatas: any[] = [{}];
  advmedialist: boolean = false;
  articleData: any;
  riskData: any;
  extractedData: any;
  isExtractedFound: boolean = false;
  

  amlCheck:boolean= true;
  adverseMediaCheck:boolean=  true;
  nationalityRisk:boolean=  true;
  idDoc:boolean=  true;
  addressDoc:boolean=  true;
  selfie:boolean=  true;
  liveliness:boolean=  true;

  constructor(private legalService: LegalOnboardingService, private dataService: DataService, public app: AppService) { }

  ngOnInit() {
    this.getAccessList()
    this.app.currentTheme.subscribe(theme => {
      this.currentTheme = theme;
    });
    this.legalService.getLegalRiskScore(this.accountID).subscribe((res) => {
      if (res) {
        this.riskData = res;
      }
    });
    this.legalService.getCorporateAccountInfo(this.accountID).subscribe((res) => {
      this.primaryData = res;
    });
    this.legalService.getManualUbo(this.accountID).subscribe((res) => {
      this.manualList = res;
    });
    this.legalService.getDocList(this.accountID).subscribe((res) => {
      this.list = res;
      this.list.forEach((element) => {
        if (element.status !== 'CREATED') {
          this.docListArray.push(element);
        }
      });
    });
    this.legalService.getAllCheck(this.accountID).subscribe((res) => {
      if (res.length !== 0 && res !== null && res !== undefined) {
        for (let content of res) {

          // For CORPORATE
          if (content.CORPORATE) {
            this.legalService.getSummary(content.CORPORATE[0].tpe.userName).subscribe((data) => this.summaryInfo = data);
            for (let corporateAML of content.CORPORATE) {
              if (corporateAML.amlChecks.length !== 0 && corporateAML.amlChecks !== null && corporateAML.amlChecks !== undefined) {
                // AML Summary
                for (let item of corporateAML.amlChecks[0].amlChecks) {
                  if (item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'CRIME') {
                    this.riskStatus = item.riskFactor
                    this.riskScore = item.riskScore
                    for (let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details) {
                      this.crimeDetailsCorp.push(pep);
                      for(let allPep of pep.category){
                        if (allPep.name.toUpperCase() === 'CRIME') {
                          this.crimeLength += 1;
                        }
                      }

                      
                    }
                  }
                  if (item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'SANCTION') {
                    this.riskStatus = item.riskFactor
                    this.riskScore = item.riskScore
                    for (let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details) {
                      this.sancDetailsCorp.push(pep);
                      this.sancLength += 1;
                    }
                  }
                }

                // Adverse Media
                console.log("adverse media: ",corporateAML.amlChecks[0].adversemediaCheck);
                if (corporateAML.amlChecks[0].adversemediaCheck.length !== 0
                  && corporateAML.amlChecks[0].adversemediaCheck.length !== null
                  && corporateAML.amlChecks[0].adversemediaCheck.length !== undefined) {
                    console.log("entered if");
                  if (corporateAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData !== null) {
                    if (corporateAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags.length !== 0) {
                      this.isTagFound = true
                      this.adverseMediaArray = corporateAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags;
                      this.tagsLength = this.adverseMediaArray.length;
                      for (let item of corporateAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags) {
                        this.adverseMediaScore += item.aggregateScore;
                      }
                      this.adverseMediaInteliigenceScore = this.adverseMediaScore / corporateAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags.length;
                    }
                  }
                }
              }
            }
          }

          // For DIRECTOR
          if (content.DIRECTOR) {
            for (let directorAML of content.DIRECTOR) {
              if (directorAML.amlChecks.length !== 0 && directorAML.amlChecks !== null && directorAML.amlChecks !== undefined) {
                // AML Summary
                for (let item of directorAML.amlChecks[0].amlChecks) {
                  if (item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'PEP' && item.kysAmlSearchForAmlCheck) {
                    for (let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details) {
                      this.pepDetails.push(pep);
                      for(let allPep of pep.category){
                        if (allPep.name.toUpperCase() === 'PEP') {
                          this.pepLength += 1;
                        }
                      }
                      
                    }
                  }
                  if (item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'CRIME' && item.kysAmlSearchForAmlCheck) {
                    for (let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details) {
                      this.crimeDetails.push(pep);
                      for(let allPep of pep.category){
                        if (allPep.name.toUpperCase() === 'CRIME') {
                          this.crimeLength += 1;
                        }
                      }

                      
                    }
                  }
                  if (item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'SANCTION' && item.kysAmlSearchForAmlCheck) {
                    for (let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details) {
                      this.sancDetails.push(pep);
                      this.sancLength += 1;
                    }
                  }
                }

                // Adverse Media
                if (directorAML.amlChecks[0].adversemediaCheck.length !== 0
                  && directorAML.amlChecks[0].adversemediaCheck.length !== null
                  && directorAML.amlChecks[0].adversemediaCheck.length !== undefined) {
                  if (directorAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData !== null) {
                    if (directorAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags.length !== 0) {
                      this.isTagFound = true
                      this.adverseDataDirector = directorAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags;
                      this.adverseMediaArray.push(this.adverseDataDirector);
                      this.tagsLength = this.adverseDataDirector.length
                    }
                  }
                }
              }
            }
          }

          // For UBO
          if (content.UBO) {
            for (let uboAML of content.UBO) {
              if (uboAML.amlChecks.length !== 0 && uboAML.amlChecks !== null && uboAML.amlChecks !== undefined) {
                // AML Summary
                for (let item of uboAML.amlChecks[0].amlChecks) {
                  if (item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'PEP' && item.kysAmlSearchForAmlCheck.amlFormattedData) {
                    for (let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details) {
                      this.pepDetails.push(pep);
                      for(let allPep of pep.category){
                        if (allPep.name.toUpperCase() === 'PEP') {
                          this.pepLength += 1;
                        }
                      }
                      
                    }
                  }
                  if (item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'CRIME' && item.kysAmlSearchForAmlCheck.amlFormattedData) {
                    for (let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details) {
                      this.crimeDetails.push(pep);
                      for(let allPep of pep.category){
                        if (allPep.name.toUpperCase() === 'CRIME') {
                          this.crimeLength += 1;
                        }
                      }
                      
                    }
                  }
                  if (item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'SANCTION' && item.kysAmlSearchForAmlCheck.amlFormattedData) {
                    for (let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details) {
                      this.sancDetails.push(pep);
                      this.sancLength += 1;
                    }
                  }
                }

                // Adverse Media
                if (uboAML.amlChecks[0].adversemediaCheck.length !== 0
                  && uboAML.amlChecks[0].adversemediaCheck.length !== null
                  && uboAML.amlChecks[0].adversemediaCheck.length !== undefined) {
                  if (uboAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData !== null) {
                    if (uboAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags.length !== 0) {
                      this.isTagFound = true
                      this.adverseDataUBO = uboAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags;
                      this.adverseMediaArray.push(this.adverseDataUBO);
                      this.tagsLength = this.adverseDataUBO.length
                    }
                  }
                }
              }
            }
          }
        }
      }
    })
  }

  // Extracted Data
  extractData() {
    this.dataService.changeLoaderVisibility(true)
    this.legalService.getCompanyExtractedData(this.accountID).subscribe((res) => {
      this.dataService.changeLoaderVisibility(false)

      if (res) {
        if (res.results?.company) {
          this.isExtractedFound = true;
          this.extractedData = res.results.company;
          this.dataService.toastrShow(`Data Extracted Successfully`, 'success')

        }else{
          this.dataService.toastrShow(`No data found for entity`, 'warning')

        }
      }
      else {
        this.dataService.toastrShow(`No data found for entity`, 'warning')
      }
    })
  }

  // Get Articles
  getArticle(val) {
    console.log("Value: ", val);

    this.articledatas = [];
    for (let article of val) {
      this.legalService.getArticle(article.id).subscribe((res) => {
        if (res.content.length > 0) {
          this.articleData = res.content[0];
          this.articledatas.push(this.articleData);
        }
        console.log("Abu Hanzala: ", this.articledatas);

      });
    }
    this.advmedialist = true;
  }

  // Download Zip File By UserName

  getDocInZip() {
    this.dataService.changeLoaderVisibility(true);
    this.legalService.corpDownloadReport(this.accountID).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/zip' });
      var file = new File([blob], 'Documents.zip', { type: 'application/zip' });
      FileSaver.saveAs(blob, `${this.accountID}-documents.zip`);
      this.dataService.changeLoaderVisibility(false);
      console.log(res);
    });
  }

  downloadReport() {
    this.dataService.changeLoaderVisibility(true);
    this.legalService.legalDownloadPdf(this.accountID).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      FileSaver.saveAs(blob, `${this.accountID}-report.pdf`);
    });
  }

  gotoAccept() {
    this.dataService.changeLoaderVisibility(true);
    this.legalService.onboardClient(this.accountID).subscribe(result => {
      this.dataService.changeLoaderVisibility(false);
      let type = result.infoType.toLowerCase();
      this.dataService.toastrShow(result.message, type);
    });
  }
  gotoReject() {
    this.dataService.changeLoaderVisibility(true);
    this.legalService.rejectClient(this.accountID).subscribe(result => {
      this.dataService.changeLoaderVisibility(false);
      let type = result.infoType.toLowerCase();
      this.dataService.toastrShow(result.message, type);
    });
  }
  
  getAccessList(){
    this.legalService.getAccessList(this.accountID).subscribe((res:any)=>{
      if(res.data!==null){
        this.amlCheck= res.data.amlCheck;
        this.adverseMediaCheck= res.data.adverseMediaCheck;
        this.nationalityRisk= res.data.nationalityRisk;
        this.idDoc= res.data.idDoc;
        this.addressDoc= res.data.addressDoc;
        this.selfie= res.data.selfie;
        this.liveliness= res.data.liveliness;
      }
    })

  }

}
