import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stylesheet',
  templateUrl: './stylesheet.component.html',
  styleUrls: ['./stylesheet.component.scss']
})
export class StylesheetComponent implements OnInit {

  dataList: any[] = [
    { key: 'Processing', value: 'PROCESSING' },
    { key: 'Verified', value: 'VERIFIED' },
    { key: 'Failed', value: 'FAILED' },
    { key: 'Onboarded', value: 'ONBOARDED' }
  ];
  selectedPersonId: any;

  constructor() { }

  ngOnInit(): void {
  }

}
