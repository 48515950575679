import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import { LegalOnboardingService } from '../services/legal-onboarding.service';

@Component({
  selector: 'app-legal-onboard',
  templateUrl: './legal-onboard.component.html',
  styleUrls: ['./legal-onboard.component.scss']
})
export class LegalOnboardComponent implements OnInit {
  jurisdictions: any;
  isRegisterationSuccessfull: boolean = false;
  accountId: any;
  @Output() close = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private legalServices: LegalOnboardingService,
    private route: Router, private dataService: DataService) { }
  accountRegister: FormGroup = this.formBuilder.group({
    companyName: ['', Validators.required],
    companyID: [''],
    citizenship: ['', Validators.required],
    jurisdiction: ['', Validators.required],
    nameOfPerson: [''],
    email: ['', [Validators.required, Validators.email]]
  });
  countries: any[];

  ngOnInit() {
    this.legalServices.getAllCountries().subscribe((res) => (this.countries = res));
  }

  onSelectCounty(country) {
    this.accountRegister.get('jurisdiction').setValue('');
    this.legalServices.getCompanyNewJurisdictions(country).subscribe((res) => {
      this.jurisdictions = res;
      this.accountRegister.get("jurisdiction").setValue(this.jurisdictions[0].code);
    });
  }

  goToBack(event) {
    if (event === 'goToList') {
      this.close.emit()
    }
  }

  accountRegisteration() {
    localStorage.removeItem("companyKysNo");
    const payload = {
      name: this.accountRegister.value.companyName,
      countryCode: this.accountRegister.value.citizenship,
      jurisdictionCode: this.accountRegister.value.jurisdiction,
      primaryEmail: this.accountRegister.value.email,
      externalIdentifier: null,
      kysCorporateEntity: {
        registrationName: null,
        lei: null,
        companyID: this.accountRegister.value.companyID,
        jurisdictionCode: null,
        incorporationDate: null,
        currentStatus: null,
        companyType: null,
        branchCount: null,
        parentCompanyName: null,
        parentCompanyID: null,
        streetAddress: null,
        locality: null,
        region: null,
        postalCode: null,
        registeredAddressInFull: null,
      },
      kysContactInfos: [
        {
          name: this.accountRegister.value.nameOfPerson,
          email: this.accountRegister.value.email,
        },
      ],
    };
    this.dataService.changeLoaderVisibility(true);
    this.legalServices.createCorporateAccount(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      this.isRegisterationSuccessfull = true;
      if (res) {
        if (res.tpe) {
          this.accountId = res.tpe.account.accountId;
          localStorage.setItem("companyKysNo", res.tpe.kysNo);
          const payload = {
            "message": 'New entity created',
            "page": "Account Register",
            "geoLocation": null
          }
          this.legalServices.addActivityLog(res.tpe.kysNo, payload).subscribe();
        }
      }
    });
  }
}
