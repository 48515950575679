import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { ServiceService } from '../service/service.service';
import { Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, FormArray, NgForm } from '@angular/forms';
import { finalize, first } from 'rxjs/operators';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  list: any;
    total: any;
    maxSize: number = 5;
    page:number=1;
    jump:number;
    
    constructor(private formBuilder: FormBuilder, 
      private _data: DataService, 
      private service: ServiceService, 
      public router: Router) {}

    ngOnInit() {
        this.getList(this.page, 10);

        
    }
    onPageChange({ itemsPerPage, page }: PageChangedEvent) {
      this.page=page;
      this.getList(this.page, itemsPerPage);
    }

    private getList(page: number, itemsPerPage: number) {
        const payload ={
          "size" : itemsPerPage,
          "page" : page-1,
          "order":"DESC"
        }
        this._data.changeLoaderVisibility(true);
        this.service.getQuickRiskHistory(payload).pipe(finalize(() => {})).subscribe((response) => {
                this.list = response.content;
                this.total = response.totalElements;
                this._data.changeLoaderVisibility(false);
            });
    }
    intermediateRisk(val,val2){
      const risk ={
          "id":val2,
          "data":val
      }
      this._data.intRiskReport(risk);
      this.router.navigate(['risk-report/report/']);
      this._data.tabValAssign(3);
  }
  jumpTo() {
    console.log("working")
    if (this.jump-1 <= this.total/10) {
        if (Math.sign(this.jump) != -1 && Math.sign(this.jump) != 0) {
            let page = this.jump;
            let itemsPerPage = 10;
            this.onPageChange({ itemsPerPage, page })
        }
    }
    else{
      let alertObj = {
          message: "Exceeding the Pages we have",
          status: 'failure',
          autoDismiss: true,
          timeToDismiss: 2000,
      };
      this._data.toastrShow(alertObj.message, alertObj.status);
  }

}

back(){
  
}
}
