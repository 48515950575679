import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { finalize } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { ServiceService } from '../service/service.service';

@Component({
  selector: 'app-fincen',
  templateUrl: './fincen.component.html',
  styleUrls: ['./fincen.component.scss']
})
export class FincenComponent implements OnInit {

  list: any[] = [];
    total: any;
    maxSize: number = 5;
    page:number=1;
    jump:number;
    
    constructor(private formBuilder: FormBuilder, 
      private _data: DataService, 
      private service: ServiceService, 
      public router: Router) {}

    ngOnInit() {
        this.getList(this.page, 10);  
    }
    onPageChange({ itemsPerPage, page }: PageChangedEvent) {
      this.page=page;
      this.getList(this.page, itemsPerPage);
    }

    private getList(page: number, itemsPerPage: number) {
        this._data.changeLoaderVisibility(true);
        this.service.fincenHistory(page-1, itemsPerPage).pipe(finalize(() => {})).subscribe((response) => {
                this.list = response.content;
                this.total = response.totalElements;
                this._data.changeLoaderVisibility(false);
            });
    }

  jumpTo() {
    console.log("working")
    if (this.jump-1 <= this.total/10) {
        if (Math.sign(this.jump) != -1 && Math.sign(this.jump) != 0) {
            let page = this.jump;
            let itemsPerPage = 10;
            this.onPageChange({ itemsPerPage, page })
        }
    }
    else{
      let alertObj = {
          message: "Exceeding the Pages we have",
          status: 'failure',
          autoDismiss: true,
          timeToDismiss: 2000,
      };
      this._data.toastrShow(alertObj.message, alertObj.status);
  }

}

}
