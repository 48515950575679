import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aml-corporate',
  templateUrl: './aml-corporate.component.html',
  styleUrls: ['./aml-corporate.component.scss']
})
export class AmlCorporateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
