import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuestionaireService {

  token: any;

  constructor(private http: HttpClient, @Inject('API_URL') public API_URL: any) {
    
  }

  getStandardQuestions(){
    return this.http.get(`${this.API_URL}/kys/v2.0/question/std`).pipe(map((res: any) => res.data))
  }
  addCustomQuestion(payload,id){
    return this.http.post(`${this.API_URL}/kys/v2.0/question/custom/create/${id}`,payload)
  }
  createQuestioniare(payload){
    return this.http.post(`${this.API_URL}/kys/v2.0/questionnair-template/create`,payload)
  }
  addQuestionTotemplate(questionId,templateId){
    return this.http.get(`${this.API_URL}/kys/v2.0/question/add-std-question/${questionId}/${templateId}`)
  }
  getQuestionaireDetails(id){
    return this.http.get(`${this.API_URL}/kys/v2.0/question/get-all/${id}`)
  }
  getAllQuestionaireDetails(payload){
    return this.http.post(`${this.API_URL}/kys/v2.0/questionnair-template/get-all`,payload)
  }
  getCustomQuestions(){
    return this.http.get(`${this.API_URL}/kys/v2.0/question/custom`).pipe(map((res: any) => res.data))
  }

  createQuestion(payload){
    return this.http.post(`${this.API_URL}/kys/v2.0/question/custom/create`,payload).pipe(map((res: any) => res.data))
  }

  submitQuestionaire(id,payload){
    return this.http.post(`${this.API_URL}/kys/v2.0/questions/check/populate-question/${id}`,payload).pipe(map((res: any) => res.data))
  }

  getQuestionnaire(id){
    return this.http.get(`${this.API_URL}/kys/v2.0/questions/check/cheks/${id}`).pipe(map((res: any) => res.data))

  }
  
  deleteQuestion(id){
   return this.http.get(`${this.API_URL}/kys/v2.0/question/change-status/${id}/INACTIVE`).pipe(map((res:any)=>res.data))
  }

  getQuestionByCategory(category){
    let payload={size:100,page:0}
    return this.http.post(`${this.API_URL}/kys/v2.0/question/get-std-question-by/category/${category}`,payload).pipe(map((res:any)=>res.data))
  }

  getTemplateSearch(payload){
    return this.http.post(`${this.API_URL}/kys/v2.0/questionnair-template/search`,payload).pipe(map((res:any)=>res.data))
  }
  searchQuestionByCategory(payload){
    return this.http.post(`${this.API_URL}/kys/v2.0/question/search`,payload).pipe(map((res:any)=>res.data))
  }
}
