import { Component, OnInit } from '@angular/core';
import { FormGroup , Validators, FormBuilder} from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { ServiceService } from '../service/service.service';
@Component({
  selector: 'app-quick-indivisual-risk',
  templateUrl: './quick-indivisual-risk.component.html',
  styleUrls: ['./quick-indivisual-risk.component.scss']
})
export class QuickIndivisualRiskComponent implements OnInit {
  basicInfoForm: FormGroup;
  countries:[];
  genderArray:any = [
      { key: 'MALE', value: 'MALE' },
      { key: 'FEMALE', value: 'FEMALE' },
      { key: 'OTHER', value: 'OTHER' },
  ];
  netWorthArray: any =[
      { key: 'Under $50,000' },
      { key: '$50,000 - $149,999' },
      { key: '$150,000 - $499,999' },
      { key: 'Over $5,000,000' },
  ];
  riskToleranceArray: any = [
      { key: 'Low' },
      { key: 'Moderate' },
      { key: 'Speculative' },
      { key: 'Aggressive' },
  ]
  constructor(
        private formBuilder: FormBuilder,
        private _data: DataService,
        private service: ServiceService,
        private router: Router
  ) { }

  ngOnInit() {
    this.getAllCountries();
    this.basicInfoForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['',Validators.required],
      citizenship: ['', Validators.required],
      dob: [''],
      email: ['', Validators.required],
      accountnumber:['', Validators.required],
      country: [''],
      totalNetWorthInd:['', Validators.required],
      liquidNetWorthInd:[''],
      riskTolerance:[''],
      gender:[''],
      officeCountry:[''], 
      clearingFirm:[''],
      investmentObjective:[''],
      birthPlace:[''],
      addressLine1:['']
  });

  
  }
  getAllCountries() {
    this.service.getAllKysCountries().subscribe(
      (res) => {
          this.countries = res['data'];
      },
      (err) => {
          console.log(err);
      }
    );
  }
  generateRiskReport(){
    const payload ={
      
      firstName: this.basicInfoForm.value.firstName,
      middleName : this.basicInfoForm.value.middleName,
      lastname: this.basicInfoForm.value.lastName,
      citizenship:this.basicInfoForm.value.citizenship,
      dob: this.basicInfoForm.value.dob,
      email: this.basicInfoForm.value.email,
      accountNumber:this.basicInfoForm.value.accountnumber ,
      residenceCountry: this.basicInfoForm.value.country,
      netWorth:this.basicInfoForm.value.totalNetWorthInd,
      liquidNetWorth:this.basicInfoForm.value.liquidNetWorthInd,
      riskTolerance:this.basicInfoForm.value.riskTolerance,
      gender:this.basicInfoForm.value.gender,
      
      officeCountry:this.basicInfoForm.value.officeCountry,
      clearingFirm:this.basicInfoForm.value.clearingFirm,
      investmentObjective:this.basicInfoForm.value.investmentObjective,
      birthPlace:this.basicInfoForm.value.birthPlace,
      addressLine1:this.basicInfoForm.value.addressLine1,

    }
    this.service.quickRiskReport(payload).subscribe((res)=>{
      console.log("hgjsgfs1",res.riskReportList[0].riskReportId);
      this.intermediateRisk(res, res.riskReportList[0].riskReportId);

    })
  }
  intermediateRisk(val,val2){
    const risk ={
        "id":val2,
        "data":val
    }
    this._data.intRiskReport(risk);
    this.router.navigate(['risk-report/report/']);
    this._data.tabValAssign(2);
}

}
