import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service/service.service';
import { DataService } from 'src/app/services/data.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  
    companyData: any;
    investigationData: any;
    companyOverview: any;
    nationalityRisk: any;
    adverseMedia: boolean = false;
    aml: boolean = false;
    stepp: any;
    tags: any;
    isTagFound: boolean = false;
    articleData: any;
    controlOfficerList: any;
    checkListId: any;
    articledatas:any[]=[{}];
    advmedialist:boolean=false;
    adverseMediaScore: number = 0;
    adverseMediaInteliigenceScore: any;
    isSanc: boolean = false;
    companyAmlData: any;
    isCrime: boolean = false;
    arrayPep: any[];
    isPep: boolean = false;
    riskStatusData: any;
    arraySanc: any[];
    arrayCrime: any[];
    countCrime: number;
    countSanc: number;
    data:any=[{}];
    riskData:any=[{}]

    constructor(private service: ServiceService,
         private dataService: DataService,
         private location :Location, 
         private route: ActivatedRoute) {}

    ngOnInit() {
        this.dataService.intermediatRiskReport.subscribe(val=>{

            if(typeof val == "string" ){
                 this.back(); 
            }else{
                this.data=val.data;
                for( var a of val.data.riskReportList){
                if(a.riskReportId==val.id){
                    this.riskData=a;
                }
                }
            }
             
          })
          
    }

    getChecklistId(val){
        this.checkListId = val
    }
    back(){
        this.location.back();
    }

    

    // downloadReport(){
    //     this.dataService.changeLoaderVisibility(true);
    //     this.service.ittsDownloadReport(this.checkListId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) =>{
    //         const blob = new Blob([res], { type: 'application/pdf' });
    //         var file = new File([blob], 'Documents.pdf', { type: 'application/pdf' });
    //         FileSaver.saveAs(blob, `${this.checkListId}-documents.pdf`);
    //     });
    // }


    getArticle(val){
        this.articledatas=[];
        for (let article of val){
            this.service.ittsArticle(article.id).subscribe((res) =>{
                        this.articleData = res;
                        this.articledatas.push(this.articleData);       
                    });
        }
        this.advmedialist=true;
    }

}