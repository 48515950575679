import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../../services/data.service';
import { RetailOnboardingService } from '../../../services/retail-onboarding.service';

@Component({
  selector: 'app-primary-holder-info',
  templateUrl: './primary-holder-info.component.html',
  styleUrls: ['./primary-holder-info.component.scss']
})
export class PrimaryHolderInfoComponent implements OnInit {
  @Input() state;
  @Output() step = new EventEmitter<any>();
  primaryHolderDataForm: FormGroup = this.formBuilder.group({
    firstNamePInd: [''],
    dobPInd: [''],
    dobDayPInd: [''],
    dependentsPInd: [''],
    phoneCodePInd: [''],
    lastNamePInd: [''],
    dobMPInd: [''],
    maritalStatusPInd: [''],
    maidenNamePInd: [''],
    phonePInd: [''],
    middleNamePInd: [''],
    countryPInd: [''],
    cityPInd: [''],
    employmentStatusPInd: [''],
    yearsPInd: [''],
    natureOfBusinessPInd: [''],
    addressLine1BusinessPInd: [''],
    zipCodeBusinessPInd: [''],
    claimTaxPInd: [''],
    associatePind: [''],
    addressLine1Pind: [''],
    zipCodePind: [''],
    companyNamePind: [''],
    positionPInd: [''],
    countryBusinessPind: [''],
    cityBusinessPind: [''],
    foreignTaxNoPInd: [''],
    countryTaxPInd: [''],
    stateCodeP: ['']
  });
  getAllData: any;
  entityName: string;
  firstName: string = '';
  lastName: string = '';
  middleName: string = '';
  countries: any[];
  residentialStateP: any[];
  personalId: any;
  isSecondary: boolean = false;
  @Input() accountId;
  maritalStatusArray: any = [
    { key: 'Married' },
    { key: 'Unmarried' },
    { key: 'Divorced' },
    { key: 'Widowed' },
  ];

  constructor(private route: Router, private activatedRoute: ActivatedRoute, private retailService: RetailOnboardingService, private formBuilder: FormBuilder,
    private dataService: DataService) { }

  ngOnInit() {
    this.retailService.getAllCountries().subscribe((res) => (this.countries = res));
    this.retailService.getAllIndividualInfo(this.accountId).subscribe((res) => {
      this.getAllData = res;
      if (this.getAllData.length !== 0) {
        if (this.getAllData[0].firstName !== null) {
          this.firstName = this.getAllData[0].firstName;
        }
        if (this.getAllData[0].middleName !== null) {
          this.middleName = this.getAllData[0].middleName;
        }
        if (this.getAllData[0].lastName !== null) {
          this.lastName = this.getAllData[0].lastName;
        }
        this.entityName = this.firstName + ' ' + this.middleName + ' ' + this.lastName;
      }
      for (let item of res) {
        if (item.length !== null) {
          if (item.tpe !== null && item.tpe !== undefined) {
            if (item.tpe.kysTpeAccount === 'PRIMARY') {
              this.personalId = item.id;
              this.primaryHolderDataForm.patchValue({
                firstNamePInd: item.firstName,
                lastNamePInd: item.lastName,
                middleNamePInd: item.middleName
              });
            }
            if (item.tpe.kysTpeAccount === 'SECONDARY') {
              this.isSecondary = true
            }
          }
        }
      }
    })
  }

  onCountrySelectP() {
    this.primaryHolderDataForm.get('stateCodeP').setValue(null);
    const country = this.primaryHolderDataForm.value.countryPInd
    const countryCode = country.countryCode
    this.retailService.getAllStates(countryCode).subscribe((res) => {
      this.residentialStateP = res
    })
  }

  getUpdatePersonalInfo() {
    const payload = {
      firstName: this.primaryHolderDataForm.value.firstNamePInd,
      lastName: this.primaryHolderDataForm.value.lastNamePInd,
      dob: this.primaryHolderDataForm.value.dobPInd,
      maritalStatus1: this.primaryHolderDataForm.value.maritalStatusPInd,
      dependents: parseInt(this.primaryHolderDataForm.value.dependentsPInd),
      motherMaidenName: this.primaryHolderDataForm.value.maidenNamePInd,
      phoneCode: this.primaryHolderDataForm.value.phoneCodePInd,
      phone: parseInt(this.primaryHolderDataForm.value.phonePInd),
      residenceCountry:
        this.primaryHolderDataForm.value.countryPInd == '' ? null : this.primaryHolderDataForm.value.countryPInd,
      city: this.primaryHolderDataForm.value.cityPInd,
      zipCode: this.primaryHolderDataForm.value.zipCodePind,
      employmentStatus: this.primaryHolderDataForm.value.employmentStatusPInd,
      companyName: this.primaryHolderDataForm.value.companyNamePind,
      years: this.primaryHolderDataForm.value.yearsPInd,
      postions: this.primaryHolderDataForm.value.positionPInd,
      natureOfBusiness: this.primaryHolderDataForm.value.natureOfBusinessPInd,
      businessCountry: this.primaryHolderDataForm.value.countryBusinessPind,
      businessAddressLine1: this.primaryHolderDataForm.value.addressLine1BusinessPInd,
      businessCity: this.primaryHolderDataForm.value.cityBusinessPind,
      businessZipCode: this.primaryHolderDataForm.value.zipCodeBusinessPInd,
      areYouCommercialEnterprise: this.primaryHolderDataForm.value.associatePind,
      foreignTaxIdNumber: this.primaryHolderDataForm.value.foreignTaxNoPInd,
      clamTaxTreatyBenifits: this.primaryHolderDataForm.value.claimTaxPInd,
      individualCountry: this.primaryHolderDataForm.value.countryTaxPInd,
      middleName: this.primaryHolderDataForm.value.middleNamePInd,
      residenceProvince:
        this.primaryHolderDataForm.value.stateCodeP == '' ? null : this.primaryHolderDataForm.value.stateCodeP,
      gender: null,
      placeOfBirthCity: null,
      placeOfBirthState: null,
      placeOfBirthCountry: null,
      fatherName: null,
      addressLine1: this.primaryHolderDataForm.value.addressLine1Pind,
      addressLine2: null,
    };
    this.dataService.changeLoaderVisibility(true);
    this.retailService.getUpdatePersonalInfo(this.accountId, this.personalId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((data) => {
      this.dataService.toastrShow(`Pesonal Information Successfully Updated`, 'success');
      if (this.isSecondary == true) {
        let state = {
          step: 3
        }
        this.step.emit(state);
      }
      else {
        let state = {
          step: 4
        }
        this.step.emit(state);
      }
      const payload = {
        "message": 'Primary Holder Information Successfully Updated',
        "page": "Primary Holder Basic Info",
        "geoLocation": null
      }
      this.retailService.addActivityLog(localStorage.getItem("primaryKysNo"), payload).subscribe();
    },

      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Primary Holder Basic Info",
          "geoLocation": null
        }
        this.retailService.addActivityLog(localStorage.getItem("primaryKysNo"), payload).subscribe();
      }
    );
  }

  goBack() {
    let state = {
      step: 1
    }
    this.step.emit(state);
  }

}
