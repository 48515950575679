import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { DevOpsService } from '../../services/dev-ops.service';

@Component({
  selector: 'app-add-articles',
  templateUrl: './add-articles.component.html',
  styleUrls: ['./add-articles.component.scss']
})
export class AddArticlesComponent implements OnInit {
  articleId: any;
  clientId: any;
  list: any[] = [];
  isAdverseEntity: boolean = false;

  constructor(private formBuilder: FormBuilder, private services: DevOpsService, private dataService: DataService, private route: ActivatedRoute) { }

  serchForm: FormGroup = this.formBuilder.group({
    search: ['']
  });
  addAdverseForm: FormGroup = this.formBuilder.group({
    entityName: [''],
    author: [''],
    title: [''],
    url: [''],
    body: [''],
    articleId: [''],
    adverseEntity: ['']
  });

  ngOnInit() {
    this.route.params.subscribe(({ clientID }) => this.clientId = clientID)
  }

  searchFilter(){
    const entityName = this.serchForm.value.search;
    this.services.getAdverseMediaIdByName(entityName).subscribe((res) => {
      if(res.content.length !== 0){
        this.list = res.content
      }
      else{
        let popup = {
          message: 'No Adverse Article exist for the ' + entityName,
          status: 'warning'
        }
        this.dataService.toastrShow(popup.message, popup.status);
      }
    });
  }

  addAdverseDetais(){
    const entityName = this.addAdverseForm.value.entityName;
    const payload = {
      "articleBody": this.addAdverseForm.value.body,
      "articleUrl": this.addAdverseForm.value.url,
      "articleTitle": this.addAdverseForm.value.title,
      "articleAuthor": this.addAdverseForm.value.author,
      "adverseEntities": null,
    }
    this.dataService.changeLoaderVisibility(true);
    this.services.addAdverseMediaArticles(entityName, this.clientId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      this.articleId = res.articleId 
      let popup = {
        message : 'Article Body is added for Article Id' + ' ' + this.articleId,
        status: 'success'
      }
      this.addAdverseForm.reset();
      this.isAdverseEntity = true;
      this.dataService.toastrShow(popup.message, popup.status);
    });
  }

  addAdverseEntity(){
    const payload = {
      "entityName" : this.addAdverseForm.value.adverseEntity
    }
    const articleId = this.addAdverseForm.value.articleId
    this.dataService.changeLoaderVisibility(true);
    this.services.addAdverseMediaEntity(articleId, this.clientId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      let popup = {
        message: 'Adverse Entity Name is added',
        status: 'success',
      }
      this.addAdverseForm.get('adverseEntity').setValue(null);
      this.dataService.toastrShow(popup.message, popup.status);
    })
  }

  clearFilter(){
    this.serchForm.get('search').setValue(null);
    this.list.length = 0;
    this.isAdverseEntity = false;
  }

}
