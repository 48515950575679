export class FinancialData {
  yearEnded : number;
  totalAssets: string;
  netAssets: string;
  totalLiabilities: string;
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IttsServicesService {

  constructor(private http: HttpClient, @Inject('API_URL') public KYS_API_URL: any) { }

  ittsCorporateAmlList(page: number, size: number) {
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v3.0/corporate/filter`, {
            page: page,
            size: size,
            orderBy: null,
            order: 'DESC',
    }).pipe(map((res) => res["data"]));
  }

  ittsCorporateOnboardList(page: number, size: number) {
    return this.http
        .post(`${this.KYS_API_URL}/kys/v3.0/corporate/download-history/`, {
            page: page,
            size: size,
            order: 'DESC',
        })
        .pipe(map((res: any) => res["data"]));
  }

  getAllCountries() {
    return this.http.get<any>(`${this.KYS_API_URL}/country/all `).pipe(map((res) => res["data"]));
  }

  getJurisdiction(countryCode){
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/jurisdiction/country/${countryCode}`).pipe(map((res) => res["data"]));
  }

  ittsCorporateSearch(payload: Object){
    return this.http.post(`${this.KYS_API_URL}/kys/v3.0/corporate/search`, payload).pipe(map((res: any) => res["data"]));
  }

  ittsDoAllCheck(payload: Object){
    return this.http.post(`${this.KYS_API_URL}/kys/v3.0/corporate/start-check`, payload).pipe(map((res: any) => res["data"]));
  }

  ittsCompanyInfo(id){
    return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/company-detail/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsInvestigationDetails(id){
      return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/investigation-detail/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsCompanyOverview(id){
      return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/company-overview/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsNationalityRisk(id){
      return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/geolocation-risk/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsadverseMediaTags(id){
      return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/adverse-media/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsArticle(id){
      return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/article/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsAML(id){
      return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/control-officer/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsAMLforCompany(id){
      return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/aml/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsDownloadReport(username) {
      let headers = new HttpHeaders({
          'Content-Type': 'application/octet-stream',
      });

      let authHttpOptions = {
          headers: headers,
          responseType: 'blob',
      };

      return this.http
          .get(`${this.KYS_API_URL}/kys/v3.0/corporate/report/${username}`, {
              headers: new HttpHeaders({
                  'Content-Type': 'application/octet-stream',
              }),
              responseType: 'blob',
          })
          .pipe(
              map((res) => {
                  return res;
              })
          );
  }

  ittsGetRiskStatus(id){
      return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/risk-details/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsFinancialData(checkListId) : Observable<FinancialData[]> {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v3.0/corporate/company-financial-data/${checkListId}`).pipe(map((res) => res["data"]));
  }

}
