import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LegalOnboardingRoutingModule } from './legal-onboarding-routing.module';
import { LegalListComponent } from './legal-list/legal-list.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ThirdPartyInfoPipe } from './pipes/third-party-info.pipe';
import { OverallStatusComponent } from './overall-status/overall-status.component';
import { LegalDetailsComponent } from './legal-details/legal-details.component';
import { EntityInfoComponent } from './legal-details/entity-info/entity-info.component';
import { DocumentsComponent } from './legal-details/documents/documents.component';
import { ControlOfficersComponent } from './legal-details/control-officers/control-officers.component';
import { ChecksListComponent } from './legal-details/checks-list/checks-list.component';
import { LegalReportComponent } from './legal-details/legal-report/legal-report.component';
import { AmlStatusPipe } from './pipes/aml-status.pipe';
import { AdverseMediaStatusPipe } from './pipes/adverse-media-status.pipe';
import { AddDetailsComponent } from './legal-details/control-officers/add-details/add-details.component';
import { ViewLegalDocumentsComponent } from './legal-details/documents/view-legal-documents/view-legal-documents.component';
import { ManualOnboardingComponent } from './legal-onboard/manual-onboarding/manual-onboarding.component';
import { AccountRegisterComponent } from './legal-onboard/manual-onboarding/account-register/account-register.component';
import { LegalOnboardComponent } from './legal-onboard/legal-onboard.component';
import { CorpDocsComponent } from './legal-onboard/manual-onboarding/corp-docs/corp-docs.component';
import { AddOfficersComponent } from './legal-onboard/manual-onboarding/add-officers/add-officers.component';
import { AddOfficersDetailsComponent } from './legal-onboard/manual-onboarding/add-officers-details/add-officers-details.component';
import { OfficerDocsComponent } from './legal-onboard/manual-onboarding/officer-docs/officer-docs.component';
import { AllDocsPipe } from './pipes/all-docs.pipe';
import { ComponentModuleModule } from '../../components/component-module/component-module.module';
import { LegalMainComponentComponent } from './legal-main-component/legal-main-component.component'
import { NgCircleProgressModule } from 'ng-circle-progress';
import { UboReportComponent } from './legal-details/control-officers/ubo-report/ubo-report.component';
import { ViewCorpDocsComponent } from './legal-details/documents/view-corp-docs/view-corp-docs.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
    declarations: [
        LegalListComponent,
        ThirdPartyInfoPipe,
        OverallStatusComponent,
        LegalDetailsComponent,
        EntityInfoComponent,
        DocumentsComponent,
        ControlOfficersComponent,
        ChecksListComponent,
        LegalReportComponent,
        AmlStatusPipe,
        AdverseMediaStatusPipe,
        AddDetailsComponent,
        ViewLegalDocumentsComponent,
        ManualOnboardingComponent,
        AccountRegisterComponent,
        LegalOnboardComponent,
        CorpDocsComponent,
        AddOfficersComponent,
        AddOfficersDetailsComponent,
        OfficerDocsComponent,
        AllDocsPipe,
        LegalMainComponentComponent,
        UboReportComponent,
        ViewCorpDocsComponent,
    ],
    imports: [
        CommonModule,
        LegalOnboardingRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
        PaginationModule.forRoot(),
        FormsModule,
        ProgressbarModule,
        ComponentModuleModule,
        InfiniteScrollModule,
        NgCircleProgressModule.forRoot({
            backgroundGradient: true,
            backgroundColor: '#ffffff',
            backgroundGradientStopColor: '#c0c0c0',
            backgroundPadding: -10,
            radius: 60,
            maxPercent: 100,
            outerStrokeWidth: 10,
            outerStrokeColor: '#61A9DC',
            innerStrokeWidth: 0,
            subtitleColor: '#444444',
            showBackground: false,
        }),
    ]
})
export class LegalOnboardingModule { }
