import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RetailOnboardingService } from '../../../services/retail-onboarding.service';
import { AppService } from '../../../../../services/app.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-view-doc',
  templateUrl: './view-doc.component.html',
  styleUrls: ['./view-doc.component.scss']
})
export class ViewDocComponent implements OnInit {
  @Input() item;
  @Output() close = new EventEmitter();
  score: number;
  currentTheme: string=localStorage.getItem('currentTheme');
  shouldFlip: boolean = false;
  isImagePdf: boolean = false;
  frontImage: SafeUrl;
  backImage: SafeUrl;

  constructor(private retailService: RetailOnboardingService, public app: AppService, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.app.currentTheme.subscribe(theme=>{
      this.currentTheme=theme;
    })
    this.retailService.getDocumentVerificationResult(this.item.checkId).subscribe((res) => {
      if(res && res.score !== null){
        this.score = res.score
      }
    });
     // Front Image URL
     if(this.item.docPathF !== null && this.item.docPathF !== undefined){
      this.retailService.getDocumentUrl(this.item.docPathF).subscribe((url : string) => this.frontImage = this.domSanitizer.bypassSecurityTrustResourceUrl(url));
      if(this.item.docPathF.substring(this.item.docPathF.length - 3) === "pdf"){
        this.isImagePdf = true;
      }
    }

    // Rear Image URL
    if(this.item.docPathR !== null && this.item.docPathR !== undefined){
      this.retailService.getDocumentUrl(this.item.docPathR).subscribe((url : string) => this.backImage = this.domSanitizer.bypassSecurityTrustResourceUrl(url));
    }
  }

  swapImage(img: HTMLImageElement): void {
    img.src = 'https://via.placeholder.com/177x150.png?text=Image%20not%20Uploaded';
  }
    
  flip() {
    this.shouldFlip = !this.shouldFlip;
  }


  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.close.emit();
  }

   // data should be your response data in base64 format
   openPdf(data){
    this.retailService.getDocumentUrl(data).subscribe(response=>{
      var byteCharacters = atob(response.split(',')[1]);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: 'application/pdf;base64' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL,'_blank');
    })
    
  }

}
