import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DevOpsService } from '../../../services/dev-ops.service';

@Component({
  selector: 'app-entity-info',
  templateUrl: './entity-info.component.html',
  styleUrls: ['./entity-info.component.scss']
})
export class EntityInfoComponent implements OnInit {
  companyData: any;

  constructor(private activatedRoute: ActivatedRoute, private devOpsService: DevOpsService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(({ clientID, accountId }) => {
      this.devOpsService.getCorporateAccountInfo(clientID, accountId).subscribe((res) => {
        this.companyData = res;
      })
    })
  }

}
