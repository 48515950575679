import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/services/data.service';
import { UploadCsvComponent } from './upload-csv/upload-csv.component';
@Component({
  selector: 'app-risk-report',
  templateUrl: './risk-report.component.html',
  styleUrls: ['./risk-report.component.scss']
})
export class RiskReportComponent implements OnInit {
  public bsModalRef: BsModalRef;

  constructor( private _data: DataService, private modalService: BsModalService ) {}

  ngOnInit() {
  }

  uploadCSV(){
    this.bsModalRef = this.modalService.show(UploadCsvComponent, { class: 'modal-lg' })
  }
  
  

}
