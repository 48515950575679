import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../../services/data.service';
import { DevOpsService } from '../../../services/dev-ops.service';

@Component({
  selector: 'app-retail-risk-policy',
  templateUrl: './retail-risk-policy.component.html',
  styleUrls: ['./retail-risk-policy.component.scss']
})
export class RetailRiskPolicyComponent implements OnInit {
  riskTemplateData: any;

  constructor(private activatedRoute: ActivatedRoute, private devOpsService: DevOpsService, private dataService: DataService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(({ clientID,  accountId }) =>{
      this.devOpsService.getIndividualInfoByAccountID(clientID, accountId).subscribe((res) => {
        if(res[0]?.tpe !== null && res[0]?.tpe !== undefined){
          this.devOpsService.getUserSpecificTemplate(clientID, res[0]?.tpe.userName).subscribe((res) => this.riskTemplateData = res.data.riskTemplate)
        }
      })
    })
  }

}
