import { Component, Input, OnInit} from '@angular/core';
import { RetailOnboardingService } from '../../services/retail-onboarding.service';
import { DataService } from '../../../../../app/services/data.service';
import * as FileSaver from 'file-saver';
import { finalize } from 'rxjs/operators';
import { AppService } from '../../../../services/app.service';
@Component({
  selector: 'app-retail-report',
  templateUrl: './retail-report.component.html',
  styleUrls: ['./retail-report.component.scss']
})
export class RetailReportComponent implements OnInit {
  primaryData: any[];
  secondaryData: any[];
  @Input() name: any;
  summaryInfo: any;
  faceImageUrl: any;
  docData: any;
  primaryIDData: Object;
  isPrimaryID: boolean = false;
  isSecondaryID: boolean = false;
  secondaryIDData: Object;
  isPrimaryAddress: boolean = false;
  isPrimaryAddressData: any[] = new Array();
  isSecondaryAddress: boolean = false;
  secondaryAddressData: any[] = new Array();
  isSecondary: boolean = false;
  getAllCheckData: any;
  riskScore: number;
  riskStatus: string;
  userNameP: any;
  isHighEntity: boolean = false;
  amlRiskScore: number;
  amlRiskStatus: string;
  @Input() accountId;
  isTagFound: boolean = false;
  sancLength: number = 0;
  crimeLength: number = 0;
  pepLength: number = 0;
  currentTheme: string=localStorage.getItem('currentTheme');
  primaryUserArray: any = [];
  secondaryUserArray: any = [];
  numberData: number = 0;
  numberDataS: number = 0;
  primeSelfie:any;
  primeLiveliness:any;

  amlCheck:boolean= true;
  adverseMediaCheck:boolean=  true;
  nationalityRisk:boolean=  true;
  idDoc:boolean=  true;
  addressDoc:boolean=  true;
  selfie:boolean=  true;
  liveliness:boolean=  true;

  constructor(private retailServices: RetailOnboardingService, private dataService: DataService, public app: AppService) { }

  ngOnInit() {

      this.app.currentTheme.subscribe(theme=>{
        this.currentTheme=theme;
      })
      this.fetchAllApi();
      this.getAccessList()
  }
  fetchAllApi(){
  this.retailServices.getAllCheckByAccount(this.accountId).subscribe((res) => {
    this.getAllCheckData = res

    // AML Data to Calculate Risk Score and Risk Status
    if(this.getAllCheckData.primary.allCheck.amlChecks.length !== 0 && this.getAllCheckData.primary.allCheck.amlChecks[0].amlChecks.length !== 0){
      for(let item of this.getAllCheckData.primary.allCheck.amlChecks[0].amlChecks){
        if(item.amlStatus.toUpperCase() === 'FAIL'){
          this.amlRiskScore = item.riskScore;
          this.amlRiskStatus = item.riskFactor;
          this.isHighEntity = true;
        }
      }
      if(this.getAllCheckData.primary.allCheck.amlChecks[0].nationalityRiskCheck){
        this.riskScore = this.getAllCheckData.primary.allCheck.amlChecks[0].nationalityRiskCheck[0].riskScore;
        this.riskStatus = this.getAllCheckData.primary.allCheck.amlChecks[0].nationalityRiskCheck[0].riskStatus
      }
    }

    // AML Data for Primary
    if(this.getAllCheckData.primary.allCheck.amlChecks.length !== 0 && this.getAllCheckData.primary.allCheck.amlChecks[0].amlChecks.length !== 0){
      this.dataService.changeLoaderVisibility(true);
      for(let item of this.getAllCheckData.primary.allCheck.amlChecks[0].amlChecks){
        if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'PEP' ){
          this.pepLength = 1;
        }
        if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'CRIME' ){
          this.crimeLength = 1;
        }
        if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'SANCTION' ){
          this.sancLength = 1;              
        }
      }
      this.dataService.changeLoaderVisibility(false);
    }

    // Adverse Media for Primary
    if(this.getAllCheckData.primary.allCheck.amlChecks.length !== 0 && this.getAllCheckData.primary.allCheck.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData !== null){
      if(this.getAllCheckData.primary.allCheck.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags.length !==0){
        this.isTagFound = true
      }
    }
  }),
  this.retailServices.getIndividualInfoByAccountID(this.accountId).subscribe((res) => {
    if(res[0] !== undefined && res[0] !== null){
      this.primaryData = res[0]
      if(res[0].tpe !== null && res[0].tpe !== undefined){
        this.userNameP = res[0].tpe.userName
      }
    }
    if(res[1] !== undefined && res[1] !== null){
      this.isSecondary = true;
      this.secondaryData = res[1]
    }
    if(res[0].tpe !== null && res[0].tpe !== undefined){
      this.retailServices.getSummary(res[0].tpe.userName).subscribe((res) => this.summaryInfo = res);
    }
  }),
  this.retailServices.getdigitalUploadList(this.accountId).subscribe((res) =>{
    for(let item of res){
      if(item.tpe !== null && item.tpe !== undefined){
        
        // For Primary Data
        if(item.docType === 'IDENTITY' && item.tpe.kysTpeAccount === 'PRIMARY'){
          this.primaryIDData = item;
          this.isPrimaryID = true;
          this.faceImageUrl = item.extractedFace;
        }

        if(item.docType === 'ADDRESS' && item.tpe.kysTpeAccount === 'PRIMARY'){
          this.isPrimaryAddress = true;
          this.isPrimaryAddressData.push(item);
          this.numberData += 1;              
          this.getIndexValueForUserPrimary(this.numberData-1);
        }
        // For Secondary Data
        if(item.docType === 'IDENTITY' && item.tpe.kysTpeAccount === 'SECONDARY'){
          this.secondaryIDData = item;
          this.isSecondaryID = true;
        }
        if(item.docType === 'ADDRESS' && item.tpe.kysTpeAccount === 'SECONDARY'){
          this.isSecondaryAddress = true;
          this.secondaryAddressData.push(item);
          this.numberDataS += 1;              
          this.getIndexValueForUserSecondary(this.numberDataS-1);
        }

        if(item.docType=== 'SELFIE' && item.tpe.kysTpeAccount === 'PRIMARY'){
          this.primeSelfie = item;

        }
        if(item.docType=== 'LIVELINESS' && item.tpe.kysTpeAccount === 'PRIMARY'){
          this.primeLiveliness=item;
        }
      }
    }
  })
  
  }
  getIndexValueForUserPrimary(i){
    if(this.primaryUserArray.indexOf(i) > -1){
      this.primaryUserArray.splice(this.primaryUserArray.indexOf(i), 1)
    }
    else{
      this.primaryUserArray.push(i)
    }    
  }
  getAccessList(){
    this.retailServices.getAccessList(this.accountId).subscribe((res:any)=>{
      if(res.data!==null){
        this.amlCheck= res.data.amlCheck;
        this.adverseMediaCheck= res.data.adverseMediaCheck;
        this.nationalityRisk= res.data.nationalityRisk;
        this.idDoc= res.data.idDoc;
        this.addressDoc= res.data.addressDoc;
        this.selfie= res.data.selfie;
        this.liveliness= res.data.liveliness;
      }
    })

  }
  getIndexValueForUserSecondary(i){
    if(this.secondaryUserArray.indexOf(i) > -1){
      this.secondaryUserArray.splice(this.secondaryUserArray.indexOf(i), 1)
    }
    else{
      this.secondaryUserArray.push(i)
    }    
  }

  // Download Zip File By UserName

  downloadZip() {
    this.dataService.changeLoaderVisibility(true);
    this.retailServices.retailDownloadReportZip(this.userNameP).subscribe((res) => {
        const blob = new Blob([res], { type: 'application/zip' });
        var file = new File([blob], 'Documents.zip', { type: 'application/zip' });
        FileSaver.saveAs(blob, `${this.userNameP}-documents.zip`);
        this.dataService.changeLoaderVisibility(false);
    });
  }

  downloadReport(){
    this.dataService.changeLoaderVisibility(true);
    this.retailServices.retailDownloadPdf(this.userNameP).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      const blob = new Blob([res], {type: 'application/pdf'});
      FileSaver.saveAs(blob, `${this.userNameP}-report.pdf`);
    });
  }

  gotoAccept() {
    this.dataService.changeLoaderVisibility(true);
    this.retailServices.onboardClient(this.accountId).subscribe(result=>{
      this.dataService.changeLoaderVisibility(false);
      let type=result.infoType.toLowerCase();
      this.dataService.toastrShow(result.message, type);
    });
  }

  gotoReject() {
      this.dataService.changeLoaderVisibility(true);
      this.retailServices.rejectClient(this.accountId).subscribe(result=>{
        this.dataService.changeLoaderVisibility(false);
        let type=result.infoType.toLowerCase();
        this.dataService.toastrShow(result.message, type);
      });
  }

}
