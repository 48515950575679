import { Component, Input, OnInit } from '@angular/core';
import { AppService } from '../../../../services/app.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../../app/services/data.service';
import { RetailOnboardingService } from '../../services/retail-onboarding.service';

@Component({
  selector: 'app-digital-upload',
  templateUrl: './digital-upload.component.html',
  styleUrls: ['./digital-upload.component.scss']
})
export class DigitalUploadComponent implements OnInit {
  list: any[]=[];
  viewDoc: any = null;
  showUpload: boolean = false;
  activeCard: any;
  indexCard: any;
  docName: any="";
  uploadDoc: any;
  docType: any;
  checkId: any;
  states: any[] = [];
  @Input() accountId: any;
  public bsModalRef: BsModalRef;
  currentTheme: string = localStorage.getItem('currentTheme');

  constructor(private retailServices: RetailOnboardingService, private dataService: DataService, 
    private modalService: BsModalService, private _app: AppService) { }

  ngOnInit() {
    this.retailServices.getdigitalUploadList(this.accountId).subscribe((res) => {
      if(res){
        let data = res.filter(e => e.docType !== 'SELFIE' && e.docType !== 'LIVELINESS')
        this.list = data;
      }
    });
    this._app.currentTheme.subscribe(theme => {
      this.currentTheme = theme;
    });
  }

  reVerifyDocument(checkId){
    this.dataService.changeLoaderVisibility(true);
    this.retailServices.reverifyDocument(checkId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.dataService.toastrShow(`Re-verification initiated`, 'success');
    })
  }

  uploadBack(event){
    this.showUpload=false;
    this.retailServices.getdigitalUploadList(this.accountId).subscribe((res) => {
      if(res){
        let data = res.filter(e => e.docType !== 'SELFIE' && e.docType !== 'LIVELINESS')
        this.list = data;
      }
    })
  }

  uploadedDoc(event){
    console.log("upload event: ",event);
  }

  setStateAsActive(state) {
    console.log(state);
    this.docName=state.docName;
    this.uploadDoc = state;
    this.docType = state.docType;
    if(state["tpe"] !== null && state["tpe"] !== undefined){
      this.retailServices.getIndividualInformationByKysNo(state["tpe"].kysNo).subscribe((data) => {
        this.retailServices.getAllStates(data.country.countryCode).subscribe((res) => this.states = res);
      });
    }
  }

  getCheckId(checkId){
    this.showUpload=true;
    this.checkId = checkId;
  }


  openUploadModal(template){
    this.bsModalRef = this.modalService.show(template, { 
      class: 'modal-lg',
      keyboard:false,
      backdrop:'static' 
    });
  }

}
