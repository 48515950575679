import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AmlScreeningRoutingModule } from './aml-screening-routing.module';
import { AmlScreeningComponent } from './aml-screening.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AmlScreeningReportComponent } from './aml-screening-report/aml-screening-report.component';
import { AmlScreeningCorpReportComponent } from './aml-screening-corp-report/aml-screening-corp-report.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
  declarations: [
    AmlScreeningComponent,
    AmlScreeningReportComponent,
    AmlScreeningCorpReportComponent
  ],
  imports: [
    CommonModule,
    AmlScreeningRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    NgCircleProgressModule.forRoot({
      backgroundGradient: true,
      backgroundColor: '#ffffff',
      backgroundGradientStopColor: '#c0c0c0',
      backgroundPadding: -10,
      radius: 60,
      maxPercent: 100,
      outerStrokeWidth: 10,
      outerStrokeColor: '#61A9DC',
      innerStrokeWidth: 0,
      subtitleColor: '#444444',
      showBackground: false,
})
  ],
})
export class AmlScreeningModule { }
