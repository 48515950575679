export class EndUser {
  identityInfo: string;

  email: string;
  endUserType: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: any;
  gender?: string;
  country: string;
  mobileNumber: any;

  fatherName?: string;
  individualUserType?: string;
  placeOfBirthCity?: string;
  placeOfBirthCountry?: string;
  placeOfBirthState?: string;

  companyName?: string;
  companyID?: string;
  incorporationDate?: string;
  branchCount?: string;
  isChildCompany?: string;

  officerID?: any;

  checks?: any;
  remoteChecks?: any;
  dashboardChecks?: any;

  company?: any;

  isUpdate?: any;
}

export class CorpUser {
  companyName: string;
  companyID: string;
  jurisdictionCode: any;
  // country          : any ;
  countryCodes: any;
  jurisdictionCodes: any;

  isUpdate?: any;
}

import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  HostListener,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy
} from "@angular/core";
// import { WizardComponent, WizardState } from "angular-archwizard";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { KycModalComponent } from "../../shared/kyc-modal/kyc-modal.component";
import { Company } from "../../models/Company";
import {
  AmlRecords,
  AmlSources,
  ClientConfig
} from "../../utils/client-config";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from "../../services/data.service";
import { AppService } from "../../services/app.service";
import { first } from "rxjs/operators";
import { Subscription } from "rxjs";
import { PaymentService } from "../../services/payment.service";
import { TrackingService } from '../../services/tracking.service';
@Component({
  selector: "app-live-check",
  templateUrl: "./live-check.component.html",
  styleUrls: ["./live-check.component.scss"]
})
export class LiveCheckComponent implements OnInit, OnDestroy {
  @Input() isSourceModalorParentComponent: any;
  @Input() userInfo: any;
  @Input() userType: any;

  @Output() newKycUser = new EventEmitter();

  isCorpSelected: boolean = false;
  // clientConfig     : any = ClientConfig;
  bsModalRef: BsModalRef;

  category: "individual" | "corporate";



  aadhaarCheck:any={
    costPerRequest: 2,
    isCorporateCheck: false,
    isDashboardCheck: false,
    isRemoteCheck: true,
    name: "OTP Aadhaar Check",
    slug: "otp-aadhaar-check",
    status: "ACTIVE"
  };

  // For Child components;
  hashId: string;
  usersCheckConfig: any[] = [];
  basicUserData: any;
  kycOriginCountry: string = null;

  //basic info
  endUser: any;
  corpUser: CorpUser;
  selectedCompany: any;

  standardChecks: any;
  allowedDocs: any[] = [];
  countries: any[] = [];
  selectedChecks: any[] = [];

  companyJurisdictions: any[] = [];
  corpCountries: any[] = [];

  //boolean value for wizard
  wizardTriggered: boolean = false;
  sendRemoteNow: boolean = false;

  //for checking previous checks
  hasChecks: {
    hasPendingChecks: boolean;
    isUserExist: boolean;
  } = {
    hasPendingChecks: false,
    isUserExist: false
  };

  //form booleans
  isIndividualFormValid: boolean = false;
  addWizardStep: boolean = false;
  allowWizardStepLoading: boolean = false;

  @ViewChildren("checkBtn")
  checkBtns: ElementRef;

  compChecks: any = new Set(["id-doc-check", "selfie-check", "liveness-check"]);
  compAMLChecks: any = new Set([
    "sanctions-check",
    "crime-check",
    "pep-check",
    "adverse-media-check"
  ]);
  compCheckObj: any = new Map();
  allUserChecks: any = new Map();
  usersAMLChecks: any = new Map();

  complimentaryAMLChecks: any = new Map();

  isUpdate: boolean = false;
  isUser: any;

  //for email update only in case of AML
  emailUpdateInfo: any = null;
  // userCountry        : any = null ;

  //variable for showing "Send a Remote Link" Modal on process completion
  showRemoteLinkModal: boolean = false;

  standardServices: any = null ;
  allowedServices : any = []; 

  subscriptions : Subscription[] = []; 


  @HostListener("window:beforeunload", ["$event"])
  doSomething($event) {
    console.log($event);
    console.log("END OF ALL IN LIVE CHECK");
  }

  constructor(
    private modalService: BsModalService,
    private _data: DataService,
    public router: Router,
    private _app: AppService,
    public cdRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private _payment : PaymentService,
    private _track:TrackingService
  ) {
    this.activatedRoute.params.subscribe(params => {
      console.log(params);
      if (Object.keys(params).length) {
        this.category = params["category"];
        this.hashId = params["hashId"];
      }
    });
    var a = this._data.loadClientData.subscribe(
      clientDataFromCookies =>{
        this.isUser = clientDataFromCookies["email"];  
      });
    this.subscriptions.push(a);
    console.log("::::::::::::::::::::::::::", this.isUser);
  }

  ngOnInit() {
    console.log(this.hashId);
    console.log(this.category);
    if (this.hashId && this.hashId.length) {
      this.getChecksConfiguration();
    } else {
      if (this.userType && this.userType == "INDIVIDUAL") {
        this.endUser = this.userInfo;
        this.isCorpSelected = false;
        this.isUpdate = true;
        this.endUser.isUpdate = true;
        console.log(this.endUser);
      } else {
        this.endUser = new EndUser();
      }

      if (this.userType && this.userType == "CORPORATE") {
        this.corpUser = this.userInfo;
        this.isCorpSelected = true;
        this.isUpdate = true;
        this.corpUser.isUpdate = true;
        this.endUser.isUpdate = true;
        console.log(this.corpUser);
        console.log(this.isCorpSelected,"dhjdhfjdhfdhf")
      } else {
        this.corpUser = new CorpUser();
      }
    
      this.getStandardCheckConfig();
      this.getCurrentSubscription();
      this.getAllCountries();
      let planCategory = this._data.getCookiesByName("planCategory");
      console.log(this.endUser);
      console.log("TYPE: ", planCategory);
      // this.isCorpSelected = this.checkIfCorpSelected("CORPORATE");
    }
  }
  getCurrentSubscription(){
    this._payment.getCurrentSubscription().subscribe( res => {
        console.log(' Current SUbscription : ',res);
        let accessibleStandardServices = (res.data) ? res.data.subscriptionPlan.standardServices : [];
        let accessibleStandardCheck = (res.data) ? res.data.subscriptionPlan.standardCheck : [];
        if (accessibleStandardServices && accessibleStandardServices.length) {
          this.standardServices = accessibleStandardCheck;
          this.allowedServices = this._data.isLiveCheckServicesPresent(this.standardServices)
          console.log('live check Standard services : ', this.standardServices);
          console.log('live check Standard checks : ', this.standardChecks);
        }
    })
  }
  showShowTab(type) {
    // type  CORPORATE | INDIVIDUAL
    if (!this.isUpdate) {
      return true;
    } else if (this.isUpdate && this.userType == type) {
      return true;
    } else {
      return false;
    }
  }

  checkIfCorpSelected(type) {
    let planCategory = this._data.getCookiesByName("planCategory");
    if (!this.isUpdate) {
      return false;
    } else if (
      this.isUpdate &&
      this.userType == type &&
      (planCategory == type || planCategory == "ALL")
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkPendingChecks() {
    let obj = {
      // "email": this.endUser.email ,
      identityInfo: this.endUser.identityInfo,
      endUserType: "INDIVIDUAL"
    };
    this._app.checkPendingChecks(obj).subscribe(
      res => {
        this.hasChecks = res.data;
        console.log(res);
      },
      err => {
        console.log(err);
        this.hasChecks.isUserExist = false;
        this.hasChecks.hasPendingChecks = false;
      }
    );
  }

  getStandardCheckConfig() {
    this._app.getStandardCheckConfig().subscribe(res => {
      this.standardChecks = res["data"];
      this.populateIdChecks();
      this.populateAMLChecks();
      console.log(this.standardChecks);
    });
  }

  populateAMLChecks() {
    if (
      this.standardChecks["aml-checks"] &&
      this.standardChecks["aml-checks"].length
    ) {
      for (let entry of Array.from(this.compAMLChecks.values())) {
        for (var j = 0; j < this.standardChecks["aml-checks"].length; j++) {
          if (this.standardChecks["aml-checks"][j].slug == entry) {
            this.standardChecks["aml-checks"][j].type = "dashboard";
            if (this.standardChecks["aml-checks"][j].status == "ACTIVE") {
              this.complimentaryAMLChecks.set(
                entry,
                this.standardChecks["aml-checks"][j]
              );
            }
          }
        }
      }
    }
    // console.log(this.complimentaryAMLChecks);
  }

  populateIdChecks() {
    if (
      this.standardChecks &&
      this.standardChecks["id-checks"] &&
      this.standardChecks["id-checks"].length
    ) {
      for (let entry of Array.from(this.compChecks.values())) {
        for (var j = 0; j < this.standardChecks["id-checks"].length; j++) {
          if (this.standardChecks["id-checks"][j].slug == entry) {
            // console.log('True');
            // if(this.standardChecks["id-checks"][j].isRemoteCheck) {
            this.standardChecks["id-checks"][j].type = "remote";
            // }else{
            // this.standardChecks["id-checks"][j].type = 'dashboard'
            // }
            this.compCheckObj.set(entry, this.standardChecks["id-checks"][j]);
          }
        }
      }
    }
    // console.log(this.compChecks); //new Set(['id-doc-check','selfie-check','liveness-check']);
    // console.log(this.compCheckObj); // New empty Map
    // console.log(this.allUserChecks);
  }

  toggleCheck(check, type, isCorporateUser?: boolean) {
    let isCorporateCheck = check.isCorporateCheck ? true : false;

    if (!this.allUserChecks.has(check.slug)) {
      this.patchForIDV(check, type, isCorporateUser);
    } else {
      if (
        this.allUserChecks.has(check.slug) &&
        this.allUserChecks.get(check.slug).slug == check.slug
      ) {
        console.log("deleteCheck");
        this.deleteCheck(check, type);
        console.log(this.allUserChecks);
      } else if (
        this.allUserChecks.has(check.slug) &&
        this.allUserChecks.get(check.slug).slug != check.slug
      ) {
        this.patchForIDV(check, type, isCorporateUser);
      }
    }

    let keys = Array.from(this.allUserChecks.keys());
    this.selectedChecks = [];
    for (var key of keys) {
      this.selectedChecks.push(this.allUserChecks.get(key));
    }

    console.log(this.allUserChecks);
  }

  patchForIDV(check, type, isCorporateUser?: boolean) {
    if (this.compChecks.has(check.slug)) {
      let keys = Array.from(this.compCheckObj.keys());
      for (var key of keys) {
        if (key != "id-doc-check") {
          this.compCheckObj.get(key).type = "remote";
        } else {
          this.compCheckObj.get(key).type = type;
        }
        this.allUserChecks.set(key, this.compCheckObj.get(key));
      }
    } else if (this.compAMLChecks.has(check.slug)) {
      let keys = Array.from(this.complimentaryAMLChecks.keys());
      console.log("complimentaryAMLChecks");
      console.log(this.complimentaryAMLChecks);
      for (var key of keys) {
        if (isCorporateUser) {
          if (this.complimentaryAMLChecks.get(key).isCorporateCheck) {
            this.allUserChecks.set(key, this.complimentaryAMLChecks.get(key));
            this.usersAMLChecks.set(key, this.complimentaryAMLChecks.get(key));
          }
        } else {
          this.allUserChecks.set(key, this.complimentaryAMLChecks.get(key));
          this.usersAMLChecks.set(key, this.complimentaryAMLChecks.get(key));
        }
      }
    } else {
      check.type = type;
      this.allUserChecks.set(check.slug, check);
    }
  }

  deleteCheck(check, type) {
    if (
      this.allUserChecks.get(check.slug).slug == check.slug &&
      this.allUserChecks.get(check.slug).type == type
    ) {
      if (this.compChecks.has(check.slug)) {
        let keys = Array.from(this.compCheckObj.keys());
        for (var key of keys) {
          this.allUserChecks.delete(key);
        }
      } else if (this.compAMLChecks.has(check.slug)) {
        // let keys = Array.from(this.complimentaryAMLChecks.keys());
        // for(var key of keys){
        // 	this.allUserChecks.delete(key);
        // }
        this.checkForAdverseDeletion(check, type);
      } else {
        this.allUserChecks.delete(check.slug);
      }
    } else {
      this.patchForIDV(check, type);
    }
  }

  checkForAdverseDeletion(check, type) {
    let keys = Array.from(this.usersAMLChecks.keys());
    if (keys.length <= 2 && check.slug !== "adverse-media-check") {
      this.usersAMLChecks.delete(check.slug);
      this.allUserChecks.delete(check.slug);

      if (this.usersAMLChecks.has("adverse-media-check")) {
        this.usersAMLChecks.delete(check.slug);
        this.allUserChecks.delete(check.slug);

        this.usersAMLChecks.delete("adverse-media-check");
        this.allUserChecks.delete("adverse-media-check");
      }
    } else {
      this.usersAMLChecks.delete(check.slug);
      this.allUserChecks.delete(check.slug);
    }
  }

  // checkForAdverseDeletion(check , type){
  // 	let keys = Array.from(this.usersAMLChecks.keys());
  // 	if(check.slug !=='adverse-media-check'){
  // 		this.usersAMLChecks.delete(key);
  // 		this.allUserChecks.delete(key);
  // 	}
  // 	for(var key of keys){
  // 		if(keys.length == 2 && keys.indexOf('adverse-media-check') <= 0 ){
  // 			this.usersAMLChecks.delete(key);
  // 			this.allUserChecks.delete(key);
  // 		}
  // 	}
  // 	console.log(this.usersAMLChecks);
  // 	console.log(this.allUserChecks);
  // }

  // toggleCheck(check , type){
  // 	// this.selectedChecks.push(check);
  // 	if(!this.isCheckSelected(check , type)){
  // 		check.type = type;
  // 		this.selectedChecks.push(check);
  // 		// this.additivePatchForIDV(check , type);
  // 		// this.selectedChecks.map((obj)=>check.type = type);
  // 	}
  // 	else{
  // 		let checkType = this.selectedChecks.filter((obj)=>check.type == type);
  // 		//toggle remove function
  // 		if(checkType.length){
  // 			var index = this.selectedChecks.indexOf(check);
  // 			if (index > -1) {
  // 			  this.selectedChecks.splice(index, 1);
  // 			}
  // 			// this.substractivePatchForIDV(check , type) ;
  // 		}
  // 		else{
  // 			// if(check.slug !== 'id-doc-check'){
  // 				this.selectedChecks.map((obj)=>{
  // 					if(obj.slug == check.slug){
  // 						obj.type = type
  // 					}
  // 					return obj;
  // 				});
  // 			// }
  // 			// else if(check.slug == 'id-doc-check'){
  // 			// 	this.mappingPatchForIDV(check , type);
  // 			// }
  // 		}
  // 	}
  // 	// if(type =='remote'){
  // 	// 	this.cumulativePatch(check , type);
  // 	// }
  // 	// else if(check.slug == 'id-doc-check' && type =='dashboard'){
  // 	// 	this.mappingPatchForIDV(check , type);
  // 	// }
  // 	console.log(this.selectedChecks);
  // }

  isCheckSelected(check, type) {
    let isSelected = this.selectedChecks.filter(obj => check.slug == obj.slug);
    return isSelected.length;
  }

  isCheckTypeSelected(check, type) {
    // console.log("isCheckTypeSelected");
    let isSelected = this.selectedChecks.filter(obj => {
      if (obj.slug == check.slug && check.type == type) {
        return obj;
      }
    });
    return isSelected.length;
  }

  hasRemoteChecksOrNot() {
    // let remoteChecks = this.selectedChecks.filter((obj)=>obj.type == 'remote') ;
    let remoteChecks = this.selectedChecks.filter(obj => {
      if (obj && obj["type"] && obj["type"] == "remote") {
        return obj;
      }
    });
    if (remoteChecks.length) {
      return true;
    } else {
      return false;
    }
  }

  getChecksCost() {
    if (this.selectedChecks.length) {
      let count = this.selectedChecks
        .map(obj => obj.costPerRequest)
        .reduce((acc, i) => acc + i);
      return count;
    } else {
      return null;
    }
  }

  showCorpTab() {
    setTimeout(() => {
      document.getElementById("individualTab").classList.remove("active");
      document.getElementById("individualTab").classList.remove("show");
      document.getElementById("individualTabArea").classList.remove("show");
      document.getElementById("individualTabArea").classList.remove("active");

      document.getElementById("corporateTabArea").classList.add("show");
      document.getElementById("corporateTabArea").classList.add("active");
      document.getElementById("corporateTab").classList.add("active");
      document.getElementById("corporateTab").classList.add("show");
    }, 0);
  }

  getChecksConfiguration() {
    this._app.getChecksConfiguration(this.hashId).subscribe(res => {
      this.usersCheckConfig = res.data["checks"];
      this.basicUserData = res.data["individualUserInfo"]
        ? res.data["individualUserInfo"]
        : res.data["corporateUserInfo"];
      // this.kycOriginCountry = res.data["kycOriginCountry"]; //OLD ATTRIBUTE
      this.kycOriginCountry = res.data["residenceCountry"];
      console.log(this.usersCheckConfig);
      this.mapChecks(this.usersCheckConfig);
      if (res.data["hasRemoteChecks"] && res.data["linkStatus"] == "NOT_SENT") {
        let remoteChecks = this.usersCheckConfig.filter(
          obj => obj.isRemoteCheck == true
        );
        this.openRemoteLinkModal(remoteChecks);
        // this.showRemoteLinkModal = true ;
      }
    });
  }

  openRemoteLinkModal(remoteChecks) {
    let checkNames = remoteChecks.map(obj => obj.standardCheck.name);
    console.log(checkNames);
    const initialState = {
      title: `Send Remote Request`,
      type: "confirmation-modal",
      heroText: `You have selected ${checkNames} , Do you want to send KYC request now or later ?`
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(sendNow => {
      console.log("results", sendNow);
      if (Object.keys(sendNow).length && sendNow.sendNow) {
        this._app.sendRemoteLink(this.hashId).subscribe(res => {
          console.log(res);
          this._data.toastrShow("Remote KYC link has been sent", "info");
        });
      }
    });
  }

  mapChecks(usersCheckConfig) {
    let checkConfig = {
      "address-checks": [],
      "aml-checks": [],
      "id-checks": []
    };
  }

  selectCheckType() {
    // if(this.isCorpSelected == true){
    // 	this.router.navigate(["/live-check/corporate/basic-info"]);
    // }
    // else if(this.isCorpSelected == false){
    // 	this.router.navigate(["/live-check/individual/basic-info"]);
    // }
  }

  onCountrySelect(country) {
    console.log(country);
    this._app.getAllowedDocs(country).subscribe(res => {
      (this.allowedDocs["validIdentityDoc"] = res.data["validIdentityDocs"]),
        (this.allowedDocs["validAddressDoc"] = res.data["validAddressDocs"]),
        console.log(this.allowedDocs);
    });
  }

  getAllCountries() {
    this._app
      .getAllCountries()
      .pipe(first())
      .subscribe(
        res => {
          this.countries = res["data"];
        },
        err => {
          console.log(err);
        }
      );
  }

  onUserEmailUpdate(e) {
    console.log("onUserEmailUpdate");
    console.log(e);
    this.emailUpdateInfo = e;
  }

  finishUserProcess(shouldSendToDocUpload) {
    let hasRemoteChecks = this.selectedChecks.filter(
      obj => obj.type == "remote"
    );
    console.log(hasRemoteChecks.length);
    if (!hasRemoteChecks.length) {
      this.sendRemoteNow = false;
      // this.openConfirmationModal();
    }
    // else
    // {
    // this.registerEndUser(shouldSendToDocUpload);
    // }
    console.log(this.endUser);
    console.log(this.corpUser);
    console.log(this.emailUpdateInfo);

    if (!this.endUser || !Object.keys(this.endUser).length) {
      this.endUser = this.corpUser;
    }

    console.log(this.endUser);

    // console.log(this.userCountry);
    if (
      this.emailUpdateInfo &&
      this.emailUpdateInfo["canUpdate"] &&
      this.emailUpdateInfo["guid"] &&
      this.endUser["identityInfo"]
    ) {
      let obj = {
        newIdentityInfo: this.endUser["identityInfo"],
        oldIdentityInfo: this.emailUpdateInfo["guid"]
      };
      this._app
        .updateUserEmail(obj, this.emailUpdateInfo["username"])
        .subscribe(res => {
          let countryCode = this.endUser["countryCode"];
          this.endUser = res.data;
          this.endUser.countryCode = countryCode;
          // this.endUser.countryCode = this.userCountry;
          this.checkIdentityInfoType();
          this.registerEndUser(shouldSendToDocUpload);
        });
    } else {
      // if(this.endUser["identityInfo"] && this.emailUpdateInfo["guid"]){
      if (this.emailUpdateInfo && this.emailUpdateInfo["guid"]) {
        this.endUser["identityInfo"] = this.emailUpdateInfo["guid"];
      } else {
        // this.endUser["identityInfo"] = null;
      }
      // this.endUser["identityInfo"] = !this.endUser["identityInfo"] ? this.emailUpdateInfo["guid"] : this.endUser["identityInfo"];
      this.checkIdentityInfoType();
      this.registerEndUser(shouldSendToDocUpload);
    }
    console.log(this.sendRemoteNow);
  }

  checkIdentityInfoType() {
    // if(this.endUser && this.endUser.identityInfoType && this.endUser.identityInfoType !== "GUID"){
    // 	if(this.endUser.identityInfo && this.endUser.identityInfo.length){
    // 		this.endUser.identityInfoType = "EMAIL";
    // 	}
    // 	else{
    // 		this.endUser.identityInfoType = "NAME" ;
    // 	}
    // }
    // else{
    // 	if(this.endUser.identityInfo && this.endUser.identityInfo.length){
    // 		this.endUser.identityInfoType = "EMAIL";
    // 	}
    // 	else{
    // 		this.endUser.identityInfoType = "NAME" ;
    // 	}
    // }

    if (this.endUser && !this.endUser.identityInfoType) {
      if (this.endUser.identityInfo && this.endUser.identityInfo.length) {
        this.endUser.identityInfoType = "EMAIL";
      } else {
        this.endUser.identityInfoType = "NAME";
      }
    } else if (this.endUser && this.endUser.identityInfoType == "GUID") {
      this.endUser.identityInfoType = "GUID";
      this.endUser.identityInfo = this.endUser.guid
        ? this.endUser.guid
        : this.endUser.identityInfo;
    }
  }

  registerEndUser(shouldSendToDocUpload?: boolean) {
    var date = new Date();
    console.log(this.endUser);
    // this._data.changeLoaderVisibility(true);
    // let slugArr = this.selectedChecks.map(obj=>obj.slug);
    // this.endUser["checks"] = slugArr ;
    let areRemoteChecks = this.selectedChecks.filter(obj => {
      if (obj.type == "remote") {
        return obj;
      }
    });
    let areDashboardChecks = this.selectedChecks.filter(obj => {
      if (obj.type == "dashboard") {
        return obj;
      }
    });
    let remoteChecks = areRemoteChecks.length
      ? areRemoteChecks.map(obj => obj.slug)
      : null;
    let dashboardChecks = areDashboardChecks.length
      ? areDashboardChecks.map(obj => obj.slug)
      : null;

    this.endUser["remoteChecks"] = remoteChecks;
    this.endUser["dashboardChecks"] = dashboardChecks;

    console.log(this.endUser);
    if (this.endUser["dob"] && this.endUser["dob"] instanceof Date) {
      let newDob = new Date(this.endUser["dob"]);
      let dobOffset = newDob.getTimezoneOffset() * 60000;
      var normalizedDob = new Date(newDob.getTime() - dobOffset).toISOString().split('T')[0];
      // let normalizedDOB = this._data.getNormalizedDate(this.endUser["dob"]);
      console.log(this.endUser["dob"]);
      this.endUser["dob"] = normalizedDob;
      console.log(this.endUser["dob"]);
    }

    this.endUser["endUserType"] = !this.isCorpSelected
      ? "INDIVIDUAL"
      : "CORPORATE";
    if (this.isCorpSelected) {
      this.endUser.companyName = this.corpUser.companyName;
    }

    console.log(this.endUser);
    this._data.changeLoaderVisibility(true);
    console.log("DOB :::::::::::::"), this.endUser;
    this._app.registerEndUser(this.endUser).subscribe(res => {
      this._track.activityLog();
      console.log(res);
      this._data.changeLoaderVisibility(false);
      if (this.sendRemoteNow) {
        this.sendRemoteLink(res, shouldSendToDocUpload);
        
      } else {
        this.shouldExitOrContinue(shouldSendToDocUpload, res);
      }
    });
  }

  emitNewUser(res) {
    // let id = res.data["id"];
    this.newKycUser.emit(res);
  }

  shouldExitOrContinue(shouldSendToDocUpload, res) {
    if (shouldSendToDocUpload) {
      if (this.isSourceModalorParentComponent) {
        this.newKycUser.emit(null);
      }
      this.router.navigate([`/live-check/individual/${res.data.checks["id"]}`]);
      // this.router.navigate([`/live-check/individual/${res.data["id"]}`]);
    } else {
      this.emitNewUser(res);
    }
  }

  sendRemoteLink(response, shouldSendToDocUpload) {
    let id = response.data.checks["id"];
    this._app.sendRemoteLink(id).subscribe(
      res => {
        console.log(res);
        this._data.toastrShow("Remote Kyc link has been sent", "info");
        this.shouldExitOrContinue(shouldSendToDocUpload, response);
      },
      err => {}
    );
  }

  // openConfirmationModal(){
  // 	const initialState = {
  //              title   : `Send Remote Request`,
  //              type    : "confirmation-modal" ,
  //              heroText    : "You have selected Remote Check , Do you want to send request now or later"
  //        };
  //        this.bsModalRef = this.modalService.show(KycModalComponent, {
  //              initialState,
  //              class: "modal-lg remoteCheckConfirmationModal"
  //        });
  //        this.bsModalRef.content.closeBtnName = "Close";
  //        this.bsModalRef.content.onClose.subscribe(sendNow => {
  //              console.log("results", sendNow);
  //        });
  // }

  openInviteModal() {
    const initialState = {
      title: `Invite New User`,
      type: "invite-new-user"
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(result => {
      console.log("results", result);
      if (Object.keys(result).length) {
        console.log(result);
      }
    });
  }

  // test(){
  // 	this.endUser["company"] = this.selectedCompany ;
  // 	console.log(this.selectedCompany);
  // }

  checkFormStatus(e) {
    // console.log(e);
    console.log("FORM IS VALID");
    console.log(e);
    this.isIndividualFormValid = e;
  }

  checkUpdatedUser(endUser) {
    console.log(this.endUser);
    // if(this.endUser && !this.endUser.identityInfoType){
    // 	this.endUser = Object.assign(this.endUser , endUser) ;
    // }
    let temp = { ...this.endUser, ...endUser };
    this.endUser = temp;
    // this.endUser = endUser ;
    console.log(endUser);
    console.log(this.endUser);
  }

  shouldAddWizard(e) {
    console.log("addWizardStep" + e);
    this.addWizardStep = e;
  }

  onSelectCompany(company) {
    this.selectedCompany = company;
    this.endUser["company"] = this.selectedCompany;
  }

  checkForUser() {
    if (this.isUser != "esen@rimuut.com") {
      return true;
    } else {
      this.standardChecks["address-checks"] = "";
      this.standardChecks["id-checks"] = "";
      console.log("::::::::::::", this.standardChecks);
      return false;
    }
  }

  isInclude(item) {
   return this.allowedServices.includes(item);
  };

  ngOnDestroy() {
    this.subscriptions.map(s => {
      s.unsubscribe();
    })
  }
}
