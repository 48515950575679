import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiUploadService {

  constructor(private http: HttpClient, @Inject('API_URL') public KYS_API_URL: any) { }

  getBulkFilterByAccount(id, page, size) {
    return this.http
        .post<any>(`${this.KYS_API_URL}/kys/v2.0/bulkonboarding/bulkonboardinglist${id}`, {
            page: page,
            size: size,
            orderBy: 'createdDate',
            order: 'DESC',
        })
        .pipe(map((res) => res["data"]));
  }

  bulkOnBoardUser(body: Object) {
    if (Array.isArray(body)) {
        return this.http.post(`${this.KYS_API_URL}/kys/v2.0/bulkonboarding/listofjsonupload`, body);
    }
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/bulkonboarding/singlejsonupload`, body);
  }

}
