import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../../services/data.service';
import { LegalOnboardingService } from '../../../services/legal-onboarding.service';
declare var $;

@Component({
  selector: 'app-account-register',
  templateUrl: './account-register.component.html',
  styleUrls: ['./account-register.component.scss']
})
export class AccountRegisterComponent implements OnInit {
  @Input() state: any;
  @Output() step = new EventEmitter<any>();
  companyData: any;
  companyForm: FormGroup;
  @Input() accountId: any;

  constructor(private formBuilder: FormBuilder, private route: Router, private activatedRoute: ActivatedRoute,
    private legalService: LegalOnboardingService, private dataService: DataService) { }

  ngOnInit() {
    localStorage.removeItem("companyKysNo");
    this.companyForm = this.formBuilder.group({
      nameOfEntity: [''],
      Jurisdiction: [''],
      cuurentStatus: [''],
      companyType: [''],
      branchCount: [''],
      pCompany: [''],
      cAddress: [''],
      region: [''],
      companyID: ['', Validators.required],
      country: [''],
      incorporateDate: [''],
      lei: [''],
      registryUrl: [''],
      pCompanyID: [''],
      locality: [''],
      postalCode: ['']
    });
    this.legalService.getAllInfo(this.accountId).subscribe((res) => {
      this.companyData = res;
      this.companyForm.patchValue({
        nameOfEntity: this.companyData.companyName,
        Jurisdiction: this.companyData.tpe.jurisdiction.name,
        cuurentStatus: this.companyData.currentStatus,
        companyType: this.companyData.companyType,
        branchCount: this.companyData.branchCount,
        pCompany: this.companyData.parentCompanyName,
        cAddress: this.companyData.streetAddress,
        region: this.companyData.region,
        companyID: this.companyData.companyID,
        country: this.companyData.country.countryName,
        incorporateDate: this.companyData.incorporationDate,
        lei: this.companyData.lei,
        registryUrl: this.companyData.opencorporatesUrl,
        pCompanyID: this.companyData.parentCompanyID,
        locality: this.companyData.locality,
        postalCode: this.companyData.postalCode,
      });
      if(this.companyData.tpe){
        localStorage.setItem("companyKysNo", this.companyData.tpe.kysNo)
      }
    })
  }

  basicDetails() {
    const payload = {
      "registrationName": null,
      "lei": this.companyForm.value.lei,
      "companyID": this.companyForm.value.companyID,
      "incorporationDate": this.companyForm.value.incorporateDate,
      "currentStatus": this.companyForm.value.cuurentStatus,
      "companyType": this.companyForm.value.companyType,
      "branchCount": parseInt(this.companyForm.value.branchCount),
      "parentCompanyName": this.companyForm.value.pCompany,
      "parentCompanyID": this.companyForm.value.pCompanyID,
      "streetAddress": this.companyForm.value.cAddress,
      "locality": this.companyForm.value.locality,
      "region": this.companyForm.value.region,
      "postalCode": this.companyForm.value.postalCode,
      "registeredAddressInFull": null,
      "opencorporatesUrl": this.companyForm.value.registryUrl,
    }
    this.dataService.changeLoaderVisibility(true);
    this.legalService.corporateUpdateInfo(this.accountId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      let state = {
        step: 2
      }
      this.step.emit(state);
      const payload = {
        "message": 'Company Basic Info Updated Successfully',
        "page": "Company Basic Info Update",
        "geoLocation": null
      }
      this.legalService.addActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Company Basic Info Update",
          "geoLocation": null
        }
        this.legalService.addActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    )
  }



  // @HostListener("click",["$event"])
  // labelCliCk($event) {
  //   // if(){
  //   //   let labelID = $(this).attr('for');
  //   //   $('#'+labelID).trigger('click');
  //   // }
  //   console.log("click event: ",$event)
  // }
}
