import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { ServiceService } from '../service/service.service';

@Component({
  selector: 'app-upload-csv',
  templateUrl: './upload-csv.component.html',
  styleUrls: ['./upload-csv.component.scss']
})
export class UploadCsvComponent implements OnInit {
  
  fileTyleArray: any[] = [
    { key : 'FINCEN', value : 'FINCEN' },
    { key : 'Customer Data', value : 'CUSTOMER_DATA' },
    { key : 'Others', value : 'OTHERS' },
  ];
  csvForm: FormGroup = this.formBuilder.group({
    fileType: [''],
    fileName: ['']
  });
  isInputField: boolean = false;
  csvFile: string [] = [];
  fileType: any;
  
  constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private dataService: DataService, private service: ServiceService) { }

  ngOnInit() {
  }

  getFileTypeName(event){    
    this.fileType = event;
    if(event === 'OTHERS'){
        this.isInputField = true;
    }
    else{
        this.isInputField = false;
    }
  }

  csvInputChange(event){
    this.bsModalRef.hide();
    this.csvFile.push(event.target.files[0]);
  }

  uploadCSVFilse(){
    const formData = new FormData();
    formData.append("file", this.csvFile[0]);
    formData.append("uploadCSVType", this.csvForm.value.fileType);
    if(this.fileType === 'OTHERS'){
        formData.append("name", this.csvForm.value.fileName);
    }
    else{
        formData.append("name", null);
    }    
    this.dataService.changeLoaderVisibility(true);
    this.service.csvFileUpload(formData).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
        this.dataService.toastrShow(`CSV File has been Uploaded`, 'success');
    });
  }

}
