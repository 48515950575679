import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../../services/data.service';
import { RetailOnboardingService } from '../../../services/retail-onboarding.service';

@Component({
  selector: 'app-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.scss']
})
export class DocUploadComponent implements OnInit {
  @Input() state;
  @Output() step = new EventEmitter<any>();
  @Output() submit = new EventEmitter<any>();
  countries: any[];
  @Input() accountId;
  getAllData: any[];
  entityName: string;
  firstName: string = '';
  lastName: string = '';
  middleName: string = '';
  getAllDocData: any[] = new Array();
  isSecondary: boolean = false;
  activeCard: any;
  docName: any;
  uploadDoc: any;
  showUpload: boolean = false;
  docType: any;
  states: any[] = [];
  checkId: any;
  indexCard: any;

  docArray: any[] = [
    { key: 'IDENTITY', value: 'IDENTITY' },
    { key: 'ADDRESS', value: 'ADDRESS' },
    { key: 'OTHERS', value: 'UNKNOWN' },
  ]

  public bsModalRef: BsModalRef;
  manualDocumentName: any;
  isRefresh: boolean = false;
  primaryKysNo: any;

  constructor(private activatedRoute: ActivatedRoute, private retailService: RetailOnboardingService, private dataService: DataService, private route: Router, private modalService: BsModalService) { }

  ngOnInit() {
    this.retailService.getAllCountries().subscribe((res) => (this.countries = res));
    this.retailService.getAllIndividualInfo(this.accountId).subscribe((res) => {
      this.getAllData = res;
      if (this.getAllData.length !== 0) {
        if(this.getAllData[0].tpe){
          this.primaryKysNo = this.getAllData[0].tpe.kysNo
        }
        this.retailService.getAllStates(this.getAllData[0].country.countryCode).subscribe((res) => this.states = res);
        if (this.getAllData[0].firstName !== null) {
          this.firstName = this.getAllData[0].firstName;
        }
        if (this.getAllData[0].middleName !== null) {
          this.middleName = this.getAllData[0].middleName;
        }
        if (this.getAllData[0].lastName !== null) {
          this.lastName = this.getAllData[0].lastName;
        }
        this.entityName = this.firstName + ' ' + this.middleName + ' ' + this.lastName;
      }
      for (let item of res) {
        if (item.length !== null) {
          if (item.tpe !== null && item.tpe !== undefined) {
            if (item.tpe.kysTpeAccount === 'SECONDARY') {
              this.isSecondary = true
            }
          }
        }
      }
    });
    this.retailService.getAllCheckByAccount(this.accountId).subscribe((res) => {
      if (res.primary) {
        if (res.primary.allCheck.idvChecks.length !== 0) {
          for (let identity of res.primary.allCheck.idvChecks[0].IDENTITY) {
            const idArray = identity
            this.getAllDocData.push(idArray);
          }
          for (let address of res.primary.allCheck.idvChecks[0].ADDRESS) {
            const addressArray = address
            this.getAllDocData.push(addressArray);
          }
        }
      }
    });
  }


  goBack() {
    if (this.isSecondary == true) {
      let state = {
        step: 3
      }
      this.step.emit(state);
    }
    else {
      let state = {
        step: 2
      }
      this.step.emit(state);
    }
  }

  gotoNext() {
    if (this.isSecondary == true) {
      let state = {
        step: 5
      }
      this.step.emit(state);
    }
    else {
      let obj;
      this.dataService.changeLoaderVisibility(true);
      this.retailService.getFinalSubmit(this.accountId, obj).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
        this.dataService.toastrShow(`Submitted Successfully`, 'success');
        this.submit.emit('goToList');
        const payload = {
          "message": 'Primary Holder Information Submitted Successfully',
          "page": "Primary Holder Submit",
          "geoLocation": null
        }
        this.retailService.addActivityLog(localStorage.getItem("primaryKysNo"), payload).subscribe();
      },

        (err: HttpErrorResponse) => {
          const payload = {
            "message": err,
            "page": "Primary Holder Submit",
            "geoLocation": null
          }
          this.retailService.addActivityLog(localStorage.getItem("primaryKysNo"), payload).subscribe();
        }
      );
    }
  }

  uploadBack(event) {
    this.showUpload = false;
  }

  uploadedDoc(event) {
    console.log("upload event: ", event);
    this.isRefresh = !this.isRefresh;
    this.manualDocumentName = ''
  }

  setStateAsActive(state, i) {
    console.log(state);
    this.activeCard = state.docName;
    this.indexCard = i;
    this.docName = state.docName;
    this.uploadDoc = state;
    this.docType = state.docType;
  }

  getCheckId(checkId) {
    this.checkId = checkId;
  }

  gotoComp(comp) {
    console.log(comp);
    this.showUpload = true;
  }

  openUploadModal(template) {
    this.bsModalRef = this.modalService.show(template, {
      class: 'modal-lg',
      keyboard: false,
      backdrop: 'static'
    });
  }

}
