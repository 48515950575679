export interface IRequestOptions {
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | Array<string> };
  observe?: any;
  // params?: HttpParams | { [param: string]: string | Array<string> };
  reportProgress?: boolean;
  responseType?: "arraybuffer" | "blob" | "json" | "text";
  withCredentials?: boolean;
}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LegalOnboardingService {

  constructor(private http: HttpClient, @Inject('API_URL') public KYS_API_URL: any) { }

  getAllCountries() {
    return this.http.get<any>(`${this.KYS_API_URL}/country/all `).pipe(map((res) => res["data"]));
  }

  getCorporateOnboardList(page: number, size: number) {
    return this.http
      .post(`${this.KYS_API_URL}/kys/v2.0/tpe/corporate/onboarding`, {
        page: page,
        size: size,
        orderBy: null,
        order: 'DESC',
      })
      .pipe(map((res: any) => res["data"]));
  }

  corporateFilterBySearch(obj) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/tpe/corporate/onboarding/search`, obj).pipe(map((res: any) => res["data"]));
  }

  corporateFilterByStatus(obj) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/tpe/corporate/onboarding`, obj).pipe(map((res: any) => res["data"]));
  }

  getThirdPartyInfo(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/tp/progress/${id}`).pipe(map((res: any) => res["data"]));
  }

  getDocumentsInfo(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/document/info/${id}`).pipe(map((res: any) => res["data"]));
  }

  getOverallStatus(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/status/${id}`).pipe(map((res: any) => res["data"]));
  }

  getCorporateDetails(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/corporate/status/${id}`).pipe(map((res: any) => res["data"]));
  }

  getCorporateAccountInfo(accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/accountinfo/corporate/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getManualUbo(accountId: any) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/list/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getAMLStatus(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/aml/${id}`).pipe(map((res: any) => res["data"]));
  }

  getAdverseMediaStatus(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/adverse-media/${id}`).pipe(map((res: any) => res["data"][0]));
  }

  saveControlOfficer(accountId, payload) {
    return this.http.put(`${this.KYS_API_URL}/kys/v2.0/co/update/${accountId}`, payload);
  }

  performKYC(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/start-kyc/${id}`);
  }

  generateCorporateUbo(accountId: string) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/account/corporate/generate/control-officer/${accountId}`, {}).pipe(map((res: any) => res["data"]));
  }

  linkOfficerToLegal(accountId, payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/co/link-officer/${accountId}`, payload);
  }

  getOverAllScore(accountId: string) {
    return this.http.get<number>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/generate-score/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getSystemUbo(accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/corporate/control-officer/list/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getCheckList(accountId) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/check-list/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getAllCheck(accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/all/check/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getSummary(userName: any) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/tpe/summary/${userName}`).pipe(map((res: any) => res["data"]));
  }

  getDocList(accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/document/check/digital-upload/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getAllStates(id) {
    return this.http.get(`${this.KYS_API_URL}/provinces/country/${id}`).pipe(map((res: any) => res.data));
  }

  reverifyDocument(documentId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/document/check/extract-and-verify?kysDocumentCheckV1Id=${documentId}`);
  }

  getUserSpecificRiskTemplate(accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/user/risk-policy/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getDocumentUrl(url: string) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/document/check/imageurl`, { s3url: url }).pipe(map((res: any) => res["data"]));
  }

  getVerifiedResult(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/document/check/verification-result?kysDocumentCheckV1Id=${id}`).pipe(map((res) => res["data"]));
  }

  getCompanyNewJurisdictions(country) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/jurisdiction/country/${country}`).pipe(map((res) => res["data"]));
  }

  riskPolicy(accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/risk-policy/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  createCorporateAccount(accountInfo: Object) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/account/corporate/register`, accountInfo).pipe(map((res: any) => res["data"]));
  }

  assignCorporateRiskTemplate(template: Object) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/account/corporate/risk-policy`, template);
  }

  getAllInfo(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/${id}`).pipe(map((res: any) => res["data"]))
  }

  corporateUpdateInfo(id, obj) {
    return this.http.put(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/${id}`, obj).pipe(map((res: any) => res["data"]))
  }

  getAllChecks(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/doc/check/${id}`).pipe(map((res: any) => res["data"]))
  }

  getAllProvince(id) {
    return this.http.get(`${this.KYS_API_URL}/provinces/country/${id}`).pipe(map((res: any) => res["data"]))
  }

  addOfficers(id, obj) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/co/add/${id}`, obj).pipe(map((res: any) => res["data"]));
  }

  getOfficersList(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/group/${id}`).pipe(map((res: any) => res["data"]))
  }

  updateOfficersInfo(id, obj) {
    return this.http.put(`${this.KYS_API_URL}/kys/v2.0/co/update/${id}`, obj).pipe(map((res: any) => res["data"]));
  }

  assignRiskforOfficers(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/control-officer-risk-policy/${id}`).pipe(map((res: any) => res["data"]))
  }

  getAllChecksforOfficers(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/control-officer-get-checks/${id}`).pipe(map((res: any) => res["data"]))
  }

  getDocName(country, type) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/document/available/docs/${country}?type=${type}`).pipe(map((res: any) => res["data"]))
  }

  resendRemoteLink(kysNo) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/remote/resend-link/${kysNo}`).pipe(map((res: any) => res["data"]))
  }

  legalDownloadPdf(accountId) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/download-report/${accountId}`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }), responseType: 'blob'
    }).pipe(map((res: any) => res));
  }

  corpDownloadReport(username) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
    });

    let authHttpOptions = {
      headers: headers,
      responseType: 'blob',
    };

    return this.http
      .get(`${this.KYS_API_URL}/kys/v2.0/co/download-document/${username}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/octet-stream',
        }),
        responseType: 'blob',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  onboardClient(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/accept-button/${id}`).pipe(map((res) => res));
  }

  rejectClient(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/reject-button/${id}`).pipe(map((res) => res));
  }

  getIndividualInformationByKysNo(kysNo) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/individual/kysNo/${kysNo}`).pipe(map((res: any) => res["data"]));
  }

  getDevopsIndividualInformationByKysNo(clientId, kysNo) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/individual/devops/${clientId}/kysNo/${kysNo}`).pipe(map((res: any) => res["data"]));
  }

  corpSubmit(id, obj) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/submit/${id}`, obj).pipe(map((res: any) => res.data));
  }

  uboSubmit(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/control-officer/submit/${id}`).pipe(map((res: any) => res.data));
  }

  getAllCheckForUBO(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/officer/get-check/${id}`).pipe(map((res: any) => res.data));
  }

  getArticle(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/adverse-media/article/${id}?adverseMediaSourceType=DEVOPS`).pipe(map((res: any) => res.data));
  }

  getLegalRiskScore(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/corporate/get-risk-status-score/${id}`).pipe(map((res) => res['data']));
  }

  getLivelinessVideo(s3URL) {
    let headers = new HttpHeaders({
      "Content-Type": "application/octet-stream"
    });
    let authHttpOptions = {
      headers: headers,
      responseType: "blob"
    };
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/get-video-doc?s3Url=${s3URL}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/octet-stream"
      }),
      responseType: "blob"
    }).pipe(map(res => {
      return res;//['data'];
    })
    );
  }

  getCompanyExtractedData(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/corporate/get-open-corp-data/${id}`).pipe(map((res: any) => res.data));
  }

  addActivityLog(kysNo, payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v1/tpe-activity/add/${kysNo}`, payload);
  }

  fetchAllActivityLog(accountId, payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v1/tpe-activity/get/all-activity/${accountId}`, payload);
  }
  updateTemplate(payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v1/customised-template/edit`, payload);
  }
  getAccessList(id){
    
    return this.http.post(`${this.KYS_API_URL}/kys/v1/customised-template/get/${id}`, null);
  }
  getQuestionnaireDetails(id){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/questions/check/cheks/${id}`)
  }

}
