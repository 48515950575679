import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DevOpsService } from '../../../services/dev-ops.service';

@Component({
  selector: 'app-checks-list',
  templateUrl: './checks-list.component.html',
  styleUrls: ['./checks-list.component.scss']
})
export class ChecksListComponent implements OnInit {
  checkEntries: any = 'Entries List';
  isType: boolean = false;
  anyOfficerFound: boolean = false;
  checkListEntries: any[];
  accountId: any;
  detailsList: any[];
  ddoDetailsList: any;
  isName: boolean = false;
  storeValueOfType: any;
  clientId: any;

  constructor(private devOpsService: DevOpsService, private activatedRoute: ActivatedRoute) { }


  ngOnInit() {
    this.activatedRoute.params.subscribe(({ clientID, accountId }) => {
      this.accountId = accountId;
      this.clientId = clientID
      this.devOpsService.getCheckList(clientID, accountId).subscribe((res) => {
        if(res !== null && res !== undefined){
          this.checkListEntries = res.entries;
          this.anyOfficerFound = true;
        }
      })
    });
  }

  getcheckEntries(checkEntries){
    this.isName = false;
    this.isType = true;
    this.checkEntries = checkEntries;
    this.storeValueOfType = checkEntries
    this.devOpsService.getCheckList(this.clientId, this.accountId).subscribe((res) => {
      if(res !== null && res !== undefined){
        for(let item of res.entries){
          if(item.type === checkEntries){
            this.detailsList = item.list
          }
        }
      }
    });
  }

  getListName(getName){
    this.isType = false;
    this.isName = true;
    this.checkEntries = getName;
    this.devOpsService.getCheckList(this.clientId, this.accountId).subscribe((res) => {
      if(res !== null && res !== undefined){
        for(let item of res.entries){
          if(item.type === this.storeValueOfType){
            for(let list of item.list){
              if(list.name = getName){
                this.ddoDetailsList = list.checkList;
              }
            }
          }
        }
      }
    });
  }

}
