import { Component, Input, OnInit } from '@angular/core';
import { RetailOnboardingService } from '../../services/retail-onboarding.service';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'app-retail-checks-list',
  templateUrl: './checks-list.component.html',
  styleUrls: ['./checks-list.component.scss']
})
export class ChecksListComponent implements OnInit {
  getAllCheckData: any;
  baselIndex : any = "The Basel AML Index is an independent annual ranking that assesses risks of money laundering and terrorist financing (ML/TF) around the world.Published by the Basel Institute on Governance since 2012, the Basel AML Index provides risk scores based on data from 17 publicly available sources such as the Financial Action Task Force (FATF), Transparency International, the World Bank and the World Economic Forum.";
  pepLength: number = 0;
  pepDetails: any[];
  crimeLength: number = 0;
  crimeDetails: any[];
  sancLength: number = 0;
  sancDetails: any[];
  adverseData: any[];
  isTagFound: boolean = false;
  tagsLength: number = 0;
  pepLengthS: number = 0;
  pepDetailsS: any[];
  crimeLengthS: number = 0;
  crimeDetailsS: any[];
  sancLengthS: number = 0;
  sancDetailsS: any[];
  adverseDataS: any[];
  isTagFoundS: boolean = false;
  tagsLengthS: number = 0;
  isSecondary: boolean = false;
  checkAMLType: any = ['CRIME', 'PEP'];
  @Input() accountId;
  adverseMediaScore: number = 0;
  adverseMediaInteliigenceScore: number = 0;
  articledatas:any[]=[{}];
  advmedialist:boolean=false;
  articleData: any;
  adverseMediaScoreS: number = 0;
  adverseMediaInteliigenceScoreS: number = 0;
  articledatasS:any[]=[{}];
  advmedialistS:boolean=false;
  articleDataS: any;
  constructor(private retailServices: RetailOnboardingService, private dataService: DataService,) { }


  ngOnInit() {
      this.retailServices.getAllCheckByAccount(this.accountId).subscribe((res) => {
        this.getAllCheckData = res
        if(this.getAllCheckData.primary.allCheck !== null && this.getAllCheckData.primary.allCheck !== undefined){
          // AML Data for Primary
        if(this.getAllCheckData.primary.allCheck.amlChecks.length !== 0 && this.getAllCheckData.primary.allCheck.amlChecks[0].amlChecks.length !== 0){
          this.dataService.changeLoaderVisibility(true);
          for(let item of this.getAllCheckData.primary.allCheck.amlChecks[0].amlChecks){
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'PEP' && item.kysAmlSearchForAmlCheck.amlFormattedData!=null){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                this.pepDetails = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                for(let allPep of pep.category){
                  if(allPep.name.toUpperCase() === 'PEP'){
                    this.pepLength += 1;
                  }
                }
              }
            }
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'CRIME' && item.kysAmlSearchForAmlCheck.amlFormattedData!=null ){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                this.crimeDetails = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                for(let allPep of pep.category){
                  if(allPep.name.toUpperCase() === 'CRIME'){
                    this.crimeLength += 1;
                  }
                }

                
              }
            }
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'SANCTION' && item.kysAmlSearchForAmlCheck.amlFormattedData!=null ){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){                
                this.sancDetails = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                
                this.sancLength += 1;
              }
            }
          }
          this.dataService.changeLoaderVisibility(false);
        }

        // Adverse Media for Primary
        if(this.getAllCheckData.primary.allCheck.amlChecks.length !== 0 && this.getAllCheckData.primary.allCheck.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData !== null){
          if(this.getAllCheckData.primary.allCheck.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags.length !==0){
            this.isTagFound = true
            this.adverseData = this.getAllCheckData.primary.allCheck.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags;
            this.tagsLength = this.adverseData.length
          }
        }
        }
      });
      this.retailServices.getAllCheckByAccount(this.accountId).subscribe((res) => {
        this.getAllCheckData = res
      //  console.log("Get All Check Data: ", this.getAllCheckData.secondary[0].allCheck.amlChecks);
        if(this.getAllCheckData.secondary.length > 0 ){
          this.isSecondary = true;
        if(this.getAllCheckData.secondary[0].allCheck !== null && this.getAllCheckData.secondary[0].allCheck !== undefined){
          // AML Data for Secondary
        if(this.getAllCheckData.secondary[0].allCheck.amlChecks.length !== 0 
          && this.getAllCheckData.secondary[0].allCheck.amlChecks !== null
          && this.getAllCheckData.secondary[0].allCheck.amlChecks !== undefined
          && this.getAllCheckData.secondary[0].allCheck.amlChecks[0].amlChecks.length !== 0){
          this.dataService.changeLoaderVisibility(true);
          for(let item of this.getAllCheckData.secondary[0].allCheck.amlChecks[0].amlChecks){
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'PEP' && item.kysAmlSearchForAmlCheck.amlFormattedData!=null ){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                this.pepDetailsS = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                for(let allPep of pep.category){
                  if(allPep.name.toUpperCase() === 'PEP'){
                    this.pepLengthS += 1;
                  }
                }
              }
            }
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'CRIME' && item.kysAmlSearchForAmlCheck.amlFormattedData!=null ){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                this.crimeDetailsS = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                for(let allPep of pep.category){
                  if(allPep.name.toUpperCase() === 'CRIME'){
                    this.crimeLengthS += 1;
                  }
                }
                
              }
            }
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'SANCTION' && item.kysAmlSearchForAmlCheck.amlFormattedData!=null ){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                this.sancDetailsS = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                this.sancLengthS += 1;
              }
            }
          }
          this.dataService.changeLoaderVisibility(false);
        }

        // Adverse Media for Secondary
        if(this.getAllCheckData.secondary[0].allCheck.amlChecks !== null
          && this.getAllCheckData.secondary[0].allCheck.amlChecks !== undefined){
          if(this.getAllCheckData.secondary[0].allCheck.amlChecks.length !== 0 
            && this.getAllCheckData.secondary[0].allCheck.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData !== null){
            if(this.getAllCheckData.secondary[0].allCheck.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags.length !==0){
              this.isTagFoundS = true
              this.adverseDataS = this.getAllCheckData.secondary[0].allCheck.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags;
              this.tagsLengthS = this.adverseData.length
            }
          }
        }
        }
        }
      });
  }

  // Get Articles For Primary
  getArticle(val) {
    console.log("Value: ", val);
    
    this.articledatas = [];
    for (let article of val) {
      this.retailServices.getArticle(article.id).subscribe((res) => {
        if(res.content.length > 0){
          this.articleData = res.content[0];
          this.articledatas.push(this.articleData);
        }
        console.log("Abu Hanzala: ", this.articledatas);
        
      });
    }
    this.advmedialist = true;
  }

  // Get Articles For Secondary
  getArticleS(val) {
    console.log("Value: ", val);
    
    this.articledatasS = [];
    for (let article of val) {
      this.retailServices.getArticle(article.id).subscribe((res) => {
        if(res.content.length > 0){
          this.articleDataS = res.content[0];
          this.articledatasS.push(this.articleData);
        }
        console.log("Abu Hanzala: ", this.articledatas);
        
      });
    }
    this.advmedialistS = true;
  }

}
