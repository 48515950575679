export class filterArray {
  key: string;
  value: string;
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../../app/services/data.service';
import { DevOpsService } from '../../services/dev-ops.service';

@Component({
  selector: 'app-legal-list',
  templateUrl: './legal-list.component.html',
  styleUrls: ['./legal-list.component.scss']
})
export class LegalListComponent implements OnInit {
  public bsModalRef: BsModalRef;
  userName: any;
  clientId: any;


  constructor(private dataService: DataService, private devOpsService: DevOpsService, private activatedRoute: ActivatedRoute) { }
  list: any;
  total: any;
  page = 0;
  itemsPerPage = 10;
  maxSize = 5;
  jump: any;
  searchKey: any;
  isTrue: boolean = false;
  isFilterByStatus: boolean = false;
  byStatus: any = null;
  filterArray: filterArray[] = [
    { key: 'Initiated', value: 'INITIATED' },
    { key: 'In Progress', value: 'IN_PROGRESS' },
    { key: 'Processing', value: 'PROCESSING' },
    { key: 'Verified', value: 'VERIFIED' },
    { key: 'Failed', value: 'FAILED' },
    { key: 'Approved', value: 'APPROVED' },
    { key: 'Rejected', value: 'REJECTED' },
  ];

  ngOnInit() {
    this.activatedRoute.params.subscribe(({ clientID, userName }) =>{
      this.userName = userName;
      this.clientId = clientID
    })
    this.getList(this.page, this.itemsPerPage);
  }

  getList(page, size) {
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService
        .getCorporateOnboardList(page, size, this.clientId)
        .pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
        .subscribe((res) => {
            this.list = res.content;
            this.total = res.totalElements;
        });
  }

  filterBySearchByChange(page, itemsPerPage){
    const payload = {
      "name": this.searchKey,
      "overAllStatus": this.byStatus,
      "page": page,
      "size": itemsPerPage,
      "orderBy": null,
      "order": 'DESC'
  }
  this.devOpsService.corporateFilterBySearch(this.clientId, payload).subscribe((res) =>{
      this.list = res.content;
      this.total = res.totalElements;
  })
  }

  filterBySearch(key){
    this.searchKey = key
    this.isTrue = true
      const payload = {
          "name": this.searchKey,
          "overAllStatus": this.byStatus,
          "page": 0,
          "size": 10,
          "orderBy": null,
          "order": 'DESC'
      }
      this.devOpsService.corporateFilterBySearch(this.clientId, payload).subscribe((res) =>{
          this.list = res.content;
          this.total = res.totalElements;
      })
  }

  getFilter(filter) {
    this.isFilterByStatus = true;
    this.byStatus = filter
    const payload = {
      "name": this.searchKey,
      "overAllStatus": this.byStatus,
      "page": 0,
      "size": 10,
      "orderBy": null,
      "order": 'DESC'
  }
  this.devOpsService.corporateFilterByStatus(this.clientId, payload).subscribe((res) =>{
      this.list = res.content;
      this.total = res.totalElements;
  })
  }

  getFilterByChange(page, itemsPerPage) {
    const payload = {
      "name": this.searchKey,
      "overAllStatus": this.byStatus,
      "page": page,
      "size": itemsPerPage,
      "orderBy": null,
      "order": 'DESC'
  }
  this.devOpsService.corporateFilterByStatus(this.clientId, payload).subscribe((res) =>{
      this.list = res.content;
      this.total = res.totalElements;
  })
  }

  onPageChange({ page, itemsPerPage }: PageChangedEvent) {
    this.itemsPerPage = itemsPerPage;
    this.page = page-1;
    if(this.isTrue){
      this.filterBySearchByChange(this.page, this.itemsPerPage)
    }else if(this.isFilterByStatus){
      this.getFilterByChange(this.page, this.itemsPerPage)
    }else{
      this.getList(this.page, this.itemsPerPage);
    }
  }

  jumpTo(){
    this.page = this.jump-1
    if(this.isTrue){
      this.filterBySearchByChange(this.jump, this.itemsPerPage)
    }else if(this.isFilterByStatus){
      this.getFilterByChange(this.jump, this.itemsPerPage)
    }else{
      this.getList(this.jump, this.itemsPerPage);
    }
  }

  clearAll(){
    this.jump = '';
    this.page = 0;
    this.byStatus = null;
    this.searchKey;
    this.isFilterByStatus = false;
    this.getList(this.jump, this.itemsPerPage)
  }

}
