import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LegalOnboardingService } from '../../../legal-onboarding/services/legal-onboarding.service';
import { RetailOnboardingService } from '../../services/retail-onboarding.service';

@Component({
  selector: 'app-retail-details',
  templateUrl: './retail-details.component.html',
  styleUrls: ['./retail-details.component.scss']
})
export class RetailDetailsComponent implements OnInit {
  getInfoData: any;
  accountId: any;
  @Input() item;
  @Output() close = new EventEmitter<any>();
  @Input() directlyReport?: boolean = false;
  isActivityLog: boolean = false;
  activityArray: any[] = [];
  sum = 10;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  start: number = 0;
  primaryUserArray: any[] = [];
  isScrollLeft: boolean = false;
  isScrollRight: boolean = true;
  questionnaireCheck: any=false;
  accoutNumber: any;
  QuestionnaireDetails: any;

  constructor(private retailServices: RetailOnboardingService,
    private legalService: LegalOnboardingService) { }

  ngOnInit() {
    
    this.getAccessList();
    this.getAcoountNumber();
    if (this.item) {
      this.accountId = this.item.account.accountId
      this.retailServices.getIndividualInfoByAccountID(this.accountId).subscribe((res) => this.getInfoData = res);
    }
    this.fetchAllActivity(this.start, this.sum);
  }

  activityLogToggle() {
    if (!this.isActivityLog) {
      this.isActivityLog = true;
    }
    else {
      this.isActivityLog = false;
    }
  }

  refreshActivity() {
    this.activityArray = []
    this.fetchAllActivity(0, this.sum);
  }

  fetchAllActivity(page, size) {
    const payload = {
      "size": size,
      "page": page,
      "sortBy": "createdDate",
      "orderBy": "DESC"
    }
    this.retailServices.fetchAllActivityLog(this.accountId, payload).subscribe((res) => {
      var data = res['data']['content'] || [];
      for (var i = 1; data.length > i; i++) {
        let formate1 = new DatePipe('en-Us').transform(data[i].createdDate, 'dd-MM-yyyy');
        let formate2 = new DatePipe('en-Us').transform(data[i - 1].createdDate, 'dd-MM-yyyy')
        if (formate1 == formate2) {
          data[i]['sameDate'] = 'yes'
        }
      }
      data.forEach(element => {
        this.activityArray.push(element)
      })
    });
  }

  scrollLeft() {
    var left = document.querySelector('.exceed-tabs');
    left?.scrollBy(-150, 0);
    var scrollPercentage = 100 * left.scrollLeft / (left.scrollWidth-left.clientWidth);
    if(scrollPercentage == 0){
      this.isScrollLeft = false;
      this.isScrollRight = true;
    }
    else if(scrollPercentage < 98){
      this.isScrollRight = true;
    }
  }

  scrollRight() {
    var right = document.querySelector('.exceed-tabs');
    right?.scrollBy(150, 0);
    var scrollPercentage = 100 * right.scrollLeft / (right.scrollWidth-right.clientWidth);
    if(scrollPercentage > 98){
      this.isScrollLeft = true;
      this.isScrollRight = false;
    }
    else if(scrollPercentage > 0){
      this.isScrollLeft = true;
    }
  }

  getIndexValueForUserPrimary(i) {
    if (this.primaryUserArray.indexOf(i) > -1) {
      this.primaryUserArray.splice(this.primaryUserArray.indexOf(i), 1)
    }
    else {
      this.primaryUserArray.push(i)
    }
  }

  onScrollDown() {
    this.start += 1;
    this.direction = "down";
    this.fetchAllActivity(this.start, this.sum);
  }

  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.close.emit('retailOut');
  }
  getAccessList(){
    this.retailServices.getAccessList(this.item.account.accountId).subscribe((res:any)=>{
      console.log("this is questionnaire det",res)
      if(res.data!==null){
        this.questionnaireCheck= res.data.questionCheck;
      }
    })
  }
  getAcoountNumber(){
    this.retailServices.getIndividualInfoByAccountID(this.item.account.accountId).subscribe((res:any)=>{
      console.log("this isthe acc",res)
      this.accoutNumber=res[0].tpe.kysNo;
      this.legalService.getQuestionnaireDetails(this.accoutNumber).subscribe((res:any)=>{
        this.QuestionnaireDetails=res['data'];
        console.log("this is the questionnaire", this.QuestionnaireDetails)
      })
    })
  }

}
