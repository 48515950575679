import { Component, Input, OnInit } from '@angular/core';
import { LegalOnboardingService } from '../../services/legal-onboarding.service';

@Component({
  selector: 'app-entity-info',
  templateUrl: './entity-info.component.html',
  styleUrls: ['./entity-info.component.scss']
})
export class EntityInfoComponent implements OnInit {
  companyData: any;
  @Input() accountId;
  @Input() activityLog : boolean = false;

  constructor(private legalServices: LegalOnboardingService) { }

  ngOnInit() {
    this.legalServices.getCorporateAccountInfo(this.accountId).subscribe((res) => {
      this.companyData = res;
    })
  }

}
