import { Component, OnInit } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
// import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import { ApiUploadService } from '../api-upload.service'

@Component({
  selector: 'app-api-history',
  templateUrl: './api-history.component.html',
  styleUrls: ['./api-history.component.scss']
})
export class ApiHistoryComponent implements OnInit {

  constructor(private apiUploadService: ApiUploadService, private dataService: DataService,) { }

  list: any;
  total: any;
  page = 0;
  itemsPerPage = 10;
  maxSize = 5;
  jump: any;

  ngOnInit() {
    this.getList('', this.page, this.itemsPerPage);
  }
  getList(status = '', page, size) {
      this.dataService.changeLoaderVisibility(true);
      this.apiUploadService.getBulkFilterByAccount(status, page, size).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
        this.list = res.content;
        this.total = res.totalElements;
      });
  }

  onPageChange({ itemsPerPage, page }: PageChangedEvent) {
    this.itemsPerPage = itemsPerPage;
    this.page = page;
    this.getList('', this.page, this.itemsPerPage);
  }

  jumpTo(){
    this.page = this.jump
    this.getList('', this.page, this.itemsPerPage);
  }

}
