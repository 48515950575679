import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { LegalOnboardingService } from 'src/app/pages/legal-onboarding/services/legal-onboarding.service';
import { DataService } from 'src/app/services/data.service';
import { DevOpsService } from '../../../services/dev-ops.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  list: any[];
  viewDoc: any = null;
  showUpload: boolean = false;
  activeCard: any;
  indexCard: any;
  docName: any;
  uploadDoc: any;
  docType: any;
  checkId: any;
  states: any[] = [];
  accountId: any;
  clientId: any;

  public bsModalRef: BsModalRef;

  constructor(private devOpsService: DevOpsService, private activatedRoute: ActivatedRoute, private dataService: DataService, private legalService: LegalOnboardingService,
    private modalService: BsModalService
   ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(({ clientID, accountId }) =>{
      this.accountId = accountId;
      this.clientId = clientID;
      this.devOpsService.getDocList(clientID, accountId).subscribe((res) => {
        this.list = res;
      })
    })
  }

  reVerifyDocument(item){
    if(item.docType === 'IDENTITY' || item.docType === 'ADDRESS'){
      this.dataService.changeLoaderVisibility(true);
      this.devOpsService.corpReverifyDocument(this.clientId, item.checkId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
        this.dataService.toastrShow(`Re-verification initiated`, 'success');
      });
    }
    else{
      this.dataService.toastrShow(`Please select only ID or Address document to initiate Re-verification process`, 'warning');
    }
  }

  uploadBack(event){
    this.showUpload=false;
    this.devOpsService.getDocList(this.clientId, this.accountId).subscribe((res) => {
      this.list = res;
    })
  }

  uploadedDoc(event){
    console.log("upload event: ",event);
  }

  setStateAsActive(state) {
    console.log(state);
    this.docName=state.docName;
    this.uploadDoc = state;
    this.docType = state.docType;
    if(state.tpe !== null && state.tpe !== undefined){
      this.legalService.getDevopsIndividualInformationByKysNo(this.clientId, state.tpe.kysNo).subscribe((data) => {
        this.devOpsService.getAllStates(data.country.countryCode).subscribe((res) => this.states = res);
      });
    }
  }

  getCheckId(checkId){
    this.showUpload=true;
    this.checkId = checkId;
  }


  openUploadModal(template){
    this.bsModalRef = this.modalService.show(template, { 
      class: 'modal-lg',
      keyboard:false,
      backdrop:'static' 
    });
  }
}
