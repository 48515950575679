import { Injectable , Inject} from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType , HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {DataService} from "../services/data.service";
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

	token : any ;
	notifications = new BehaviorSubject(null);
	amlAlerts= new BehaviorSubject(null);
    constructor(
	  	private http: HttpClient,
	    private _data : DataService,
		  @Inject('API_URL') public API_URL: any 
  	){
	      this.token = this._data.getCookiesByName('token');
	      console.log(this.token);
  	}


  	getAllNotification(data , type){
  		return this.http.post<any>(`${this.API_URL}/notification/?filter=${type}` , data)
	        .pipe(map(res => {
	            return res;
        }));
  	}
	  

  	markAsRead(notificationId){
  		return this.http.post<any>(`${this.API_URL}/kys/v2.0/alerts/notification-seen/${notificationId}` , null)
	        .pipe(map(res => {
	            return res;
        }));
  	}
	markAsReadAML(notificationId){
		return this.http.post<any>(`${this.API_URL}/kys/v2.0/alerts/bookmarked/notification-seen/${notificationId}` , null)
		  .pipe(map(res => {
			  return res;
	  }));
	}

  	registerDeviceId(data){
		return this.http.post<any>(`${this.API_URL}/kys/notification/subscribe`, data)
		  .pipe(map(res => {
			  return res;
	  }));
	}
	  getBulkNotification(data ){
		return this.http.post<any>(`${this.API_URL}/bulk/notification` , data)
		  .pipe(map(res => {
			  return res;
	  }));
	}
	markBulkNotifAsRead(notificationId){
		return this.http.post<any>(`${this.API_URL}/bulk/mark/notification/${notificationId}` , null)
		  .pipe(map(res => {
			  return res;
	  }));
	}
	// download(batchId: number | undefined): Observable<Blob>{
	// 	return this.http.get<any>(`${this.API_URL}/bulk/notification/export/${batchId}`,{responseType:'blob'})
	// 	  .pipe(map(res => {
	// 		  return res;
	//   }));
	  download(batchId: number | undefined): Observable<Blob> {
		return this.http.get(`${this.API_URL}/bulk/notification/export/${batchId}`, {
		  responseType: 'blob'
		});
	  }
	
	  newNotificationData(){
		  return this.http.get(`${this.API_URL}/kys/v2.0/alerts/get-alerts-data`).pipe(map(res=>{
			  return res;
		  }))
	  }
	  AMLalertsData(){
		return this.http.get(`${this.API_URL}/kys/v2.0/alerts/bookmarked/get-alerts-data`).pipe(map(res=>{
			return res;
		}))
	  }
}
