import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApiUploadRoutingModule } from './api-upload-routing.module';
import { ApiHistoryComponent } from './api-history/api-history.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ApiUploadViewComponent } from './api-upload-view/api-upload-view.component';
import { ApiUploadComponent } from './api-upload/api-upload.component';

@NgModule({
  declarations: [ApiHistoryComponent, ApiUploadViewComponent, ApiUploadComponent],
  imports: [
    CommonModule,
    ApiUploadRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
  ],
})
export class ApiUploadModule { }
