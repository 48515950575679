import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AppService } from '../../services/app.service';
import { LegalOnboardingService } from '../../pages/legal-onboarding/services/legal-onboarding.service';

@Component({
  selector: 'app-selfie-verification',
  templateUrl: './selfie-verification.component.html',
  styleUrls: ['./selfie-verification.component.scss']
})
export class SelfieVerificationComponent implements OnInit {
  @Input() item;
  frontImage: SafeUrl;
  extractionResult: any;
  faceExtractionResults: any;
  emotionalAnalysisArray: any[] = [];
  currentTheme: string = localStorage.getItem('currentTheme');

  constructor(private domSanitizer: DomSanitizer, private legalService: LegalOnboardingService, public app: AppService) { }

  ngOnInit(): void {
    // Front Image URL
    if (this.item.docPathF !== null && this.item.docPathF !== undefined) {
      this.legalService.getDocumentUrl(this.item.docPathF).subscribe((url: string) => {
        this.frontImage = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
      });
    }

    // Raw Extraction Results
    this.extractionResult = JSON.parse(this.item.rawExtractionResult);
    console.log(this.extractionResult);

    // Extraction Results
    const faceExtraction = JSON.parse(this.item.extractionResult);
    console.log(faceExtraction);
    if (faceExtraction) {
      this.faceExtractionResults = faceExtraction['azureFaceVerifyRes'];
      this.app.currentTheme.subscribe(theme => {
        this.currentTheme = theme;
        if(this.currentTheme === 'theme-light'){        
          this.emotionalAnalysisArray = new Array(
            { emoji: 'assets/images/emotion/Neutral_light_mode.svg', name: 'Neutral', value: this.extractionResult["faceAttributes"]["emotion"]["neutral"] },
            { emoji: 'assets/images/emotion/Anger_light_mode.svg', name: 'Anger', value: this.extractionResult["faceAttributes"]["emotion"]["anger"] },
            { emoji: 'assets/images/emotion/Contempt_light_mode.svg', name: 'Contempt', value: this.extractionResult["faceAttributes"]["emotion"]["contempt"] },
            { emoji: 'assets/images/emotion/Surprise_light_mode.svg', name: 'Surprise', value: this.extractionResult["faceAttributes"]["emotion"]["surprise"] },
            { emoji: 'assets/images/emotion/Sadness_light_mode.svg', name: 'Sadness', value: this.extractionResult["faceAttributes"]["emotion"]["sadness"] },
            { emoji: 'assets/images/emotion/Happiness_light_mode.svg', name: 'Happiness', value: this.extractionResult["faceAttributes"]["emotion"]["happiness"] },
            { emoji: 'assets/images/emotion/Fear_light_mode.svg', name: 'Fear', value: this.extractionResult["faceAttributes"]["emotion"]["fear"] },
            { emoji: 'assets/images/emotion/Disgust_light_mode.svg', name: 'Disgust', value: this.extractionResult["faceAttributes"]["emotion"]["disgust"] }
          )
        }
        else{
          this.emotionalAnalysisArray = new Array(
            { emoji: 'assets/images/emotion/Neutral_Dark_Mode.svg', name: 'Neutral', value: this.extractionResult["faceAttributes"]["emotion"]["neutral"] },
            { emoji: 'assets/images/emotion/Anger_Dark_Mode.svg', name: 'Anger', value: this.extractionResult["faceAttributes"]["emotion"]["anger"] },
            { emoji: 'assets/images/emotion/Contempt_Dark_Mode.svg', name: 'Contempt', value: this.extractionResult["faceAttributes"]["emotion"]["contempt"] },
            { emoji: 'assets/images/emotion/Surprise_Dark_Mode.svg', name: 'Surprise', value: this.extractionResult["faceAttributes"]["emotion"]["surprise"] },
            { emoji: 'assets/images/emotion/Sadness_Dark_Mode.svg', name: 'Sadness', value: this.extractionResult["faceAttributes"]["emotion"]["sadness"] },
            { emoji: 'assets/images/emotion/Happiness_Dark_Mode.svg', name: 'Happiness', value: this.extractionResult["faceAttributes"]["emotion"]["happiness"] },
            { emoji: 'assets/images/emotion/Fear_Dark_Mode.svg', name: 'Fear', value: this.extractionResult["faceAttributes"]["emotion"]["fear"] },
            { emoji: 'assets/images/emotion/Disgust_Dark_Mode.svg', name: 'Disgust', value: this.extractionResult["faceAttributes"]["emotion"]["disgust"] }
          )
        }
      });
    }
  }

  swapImage(img: HTMLImageElement): void {
    img.src = 'https://via.placeholder.com/177x150.png?text=Image%20not%20Uploaded';
  }

}
