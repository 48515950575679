import { Pipe, PipeTransform } from '@angular/core';
import { LegalOnboardingService } from '../../legal-onboarding/services/legal-onboarding.service';
import { DevOpsService } from '../services/dev-ops.service';

@Pipe({
  name: 'thirdPartyInfo'
})
export class ThirdPartyInfoPipe implements PipeTransform {

  constructor(private devOpsService: DevOpsService){}

  async transform(value: any, args1: any){
    const response = await this.devOpsService.getThirdPartyInfo(args1, value).toPromise();
    return parseInt(response);
  }

}
