import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RetailOnboardingService } from '../../services/retail-onboarding.service';
import { AppService } from '../../../../services/app.service';

@Component({
  selector: 'app-overall-status',
  templateUrl: './overall-status.component.html',
  styleUrls: ['./overall-status.component.scss']
})
export class OverallStatusComponent implements OnInit {

  getInfoData: any;
  accountId: any;
  overAllStatus: any;
  failedDocument: number;
  totalDocument$: Observable<number>;
  score$: Observable<number>;
  primaryData: any;
  secondaryData: any;
  isSecondary: boolean = false;
  list: any;
  currentTheme: string=localStorage.getItem('currentTheme');
  @Input() item;
  @Output() close = new EventEmitter<any>();
  verificationAvailabilty: any[] = [
    { name: 'Adress Verification', check: 'NA' },
    { name: 'Selfie Check', check: 'NA' },
    { name: 'Liveliness Check', check: 'NA' },
    { name: 'Additional Documents', check: 'NA' }
  ];

  constructor(private retailServices: RetailOnboardingService, public app: AppService) { }

  ngOnInit() {
    this.app.currentTheme.subscribe(theme=>{
      this.currentTheme=theme;
    })
    if(this.item){
      this.accountId = this.item.account.accountId
      this.retailServices.getIndividualInfoByAccountID(this.accountId).subscribe((res) => {
        if(res){
          this.getInfoData = res
        }
      });
      this.retailServices.getOverAllAccountStatus(this.accountId).subscribe((res) => {
        if(res){
          this.overAllStatus = res
        }
      });
      this.retailServices.getOverAllFailedDocs(this.accountId).subscribe((res) => this.failedDocument = res);
      this.totalDocument$ = this.retailServices.getOverAllTotalDocs(this.accountId);
      this.score$ = this.retailServices.getOverAllScore(this.accountId);  
      this.retailServices.getdigitalUploadList(this.accountId).subscribe((res) => {
        this.list = res;
        for(let item of res){
          if(item.tpe !== null && item.tpe !== undefined){
            // For Primary Data
            if(item.docType === 'IDENTITY' && item.tpe.kysTpeAccount === 'PRIMARY'){
              this.primaryData = item;              
            }
            // For Secondary Data
            if(item.docType === 'IDENTITY' && item.tpe.kysTpeAccount === 'SECONDARY'){
              this.isSecondary = true;
              this.secondaryData = item;
            }
          }
        }
      });
    }
  }

  closeInfo($event: MouseEvent){
    $event.preventDefault();
    this.close.emit();
  }

}
