import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { DevOpsService } from '../../services/dev-ops.service';

@Component({
  selector: 'app-add-tags',
  templateUrl: './add-tags.component.html',
  styleUrls: ['./add-tags.component.scss']
})
export class AddTagsComponent implements OnInit {
  clientId: any;

  constructor(private formBuilder: FormBuilder, private services: DevOpsService, private dataService: DataService, private route: ActivatedRoute) { }

  addAdverseForm: FormGroup = this.formBuilder.group({
    entityName: [''],
    tag: [''],
    aggregateScore: [''],
    score: [''],
    articleId: ['']
  });

  ngOnInit() {
      this.route.params.subscribe(({ clientID }) => this.clientId = clientID);
  }

  addAdverseDetais(){
    const payload = {
      "tags":
      [
        {
          "tag": this.addAdverseForm.value.tag,
          "aggregateScore": this.addAdverseForm.value.aggregateScore,
          "article": [
            {
              "id": this.addAdverseForm.value.articleId,
              "score": this.addAdverseForm.value.score,
            }  
          ]
        }
      ]
    }
    const entityName = this.addAdverseForm.value.entityName
    this.dataService.changeLoaderVisibility(true);
    this.services.addTagsName(entityName, this.clientId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      let popup = {
        message: this.addAdverseForm.value.tag + ' is added for ' + entityName,
        status: 'success'
      }
      this.addAdverseForm.reset();
      this.dataService.toastrShow(popup.message, popup.status);
    });
  }

}
