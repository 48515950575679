import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LegalOnboardingService } from '../../../services/legal-onboarding.service';

@Component({
  selector: 'app-view-corp-docs',
  templateUrl: './view-corp-docs.component.html',
  styleUrls: ['./view-corp-docs.component.scss']
})
export class ViewCorpDocsComponent implements OnInit {
  @Input() item;
  @Output() close = new EventEmitter();
  shouldFlip: boolean = false;
  isImagePdf: boolean = false;
  frontImage: SafeUrl;
  backImage: SafeUrl;
  constructor(private domSanitizer: DomSanitizer, private legalService: LegalOnboardingService) { }

  ngOnInit(): void {
    // Front Image URL
    if(this.item.docPathF !== null && this.item.docPathF !== undefined){
      this.legalService.getDocumentUrl(this.item.docPathF).subscribe((url : string) => this.frontImage = this.domSanitizer.bypassSecurityTrustResourceUrl(url));
      if(this.item.docPathF.substring(this.item.docPathF.length - 3) === "pdf"){
        this.isImagePdf = true;
      }
    }

    // Rear Image URL
    if(this.item.docPathR !== null && this.item.docPathR !== undefined){
      this.legalService.getDocumentUrl(this.item.docPathR).subscribe((url : string) => this.backImage = this.domSanitizer.bypassSecurityTrustResourceUrl(url));
    }
  }

  swapImage(img: HTMLImageElement): void {
    img.src = 'https://via.placeholder.com/177x150.png?text=Image%20not%20Uploaded';
  }
    
  flip() {
    this.shouldFlip = !this.shouldFlip;
  }

  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.close.emit();
  }

  // data should be your response data in base64 format
  openPdf(data){
    this.legalService.getDocumentUrl(data).subscribe(response=>{
      var byteCharacters = atob(response.split(',')[1]);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: 'application/pdf;base64' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL,'_blank');
    })
    
  }

}
