import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-aml-summary',
  templateUrl: './new-aml-summary.component.html',
  styleUrls: ['./new-aml-summary.component.scss']
})
export class NewAmlSummaryComponent implements OnInit {
  @Input() amlSummary: any;
  @Input() amlType: any;
  showHideDetailsArray: any[] = []
  constructor() { }

  ngOnInit(): void {
  }

  toggleDetails(index){
    const i = this.showHideDetailsArray.indexOf(index);
    if(i > -1){
      this.showHideDetailsArray.splice(i, 1);
    }
    else{
      this.showHideDetailsArray.push(index);
    }
  }

}
