import { Pipe, PipeTransform } from '@angular/core';
import { LegalOnboardingService } from '../services/legal-onboarding.service';

@Pipe({
  name: 'thirdPartyInfo'
})
export class ThirdPartyInfoPipe implements PipeTransform {

  constructor(private legalServices: LegalOnboardingService){}

  async transform(value: any){
    const response = await this.legalServices.getThirdPartyInfo(value).toPromise();
    return parseInt(response);
  }

}
