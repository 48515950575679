import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import { IttsServicesService } from '../services/itts-services.service';

@Component({
  selector: 'app-entity-search',
  templateUrl: './entity-search.component.html',
  styleUrls: ['./entity-search.component.scss']
})
export class EntitySearchComponent implements OnInit {

  companyForm: FormGroup = this.formBuilder.group({
    companyName: [''],
    citizenship: [''],
    jurisdiction: [''],
    regNo: ['']
  });
  countries: any=[];
  jurisdictionData: any=[];
  showRegNo: boolean = false;
  list: any[];
  sourceOfData: any;
  public bsModalRef: BsModalRef;
  public bsModalRef2: BsModalRef;
  companyObject: Object;
  companyName: any;
  companyType: any;
  companyNumber: any;
  checkListId: any;
  searchDone:boolean=false;
  isReport:boolean = false;
  checkType:any;

  constructor(private formBuilder: FormBuilder, private ittsServices: IttsServicesService, private dataService: DataService, 
    private modalService: BsModalService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.ittsServices.getAllCountries().subscribe((res) => (this.countries = res));    
  }

  onCountrySelect(countryCode){
    this.showRegNo = false

    if(countryCode === 'CN'){
      this.showRegNo = true
    }
    this.ittsServices.getJurisdiction(countryCode).subscribe((res) => {
      this.jurisdictionData = res;
      this.companyForm.get('jurisdiction').reset();
      this.companyForm.get('jurisdiction').setValue(this.jurisdictionData[0].code)

    })
  console.log("form",this.companyForm.value);
  }

  companySearch(){
    if(this.showRegNo == true){
      const payload = {
        "name" : this.companyForm.value.companyName,
        "jurisdictionCode": this.companyForm.value.jurisdiction,
        "country": this.companyForm.value.citizenship,
        "companyNumber": this.companyForm.value.regNo, 
      }
      this.dataService.changeLoaderVisibility(true);
      this.ittsServices.ittsCorporateSearch(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) =>{
        this.searchDone=true;
        if(res !== null){
          if(res.result !== null){
            this.list = res.result.data.CompanyRegistrationInformation.data
          }
          this.sourceOfData = res.sourceOfData;
        }
      });
    }
    else{
      const payload = {
        "name" : this.companyForm.value.companyName,
        "jurisdictionCode": this.companyForm.value.jurisdiction,
        "country": this.companyForm.value.citizenship,
      }
      this.dataService.changeLoaderVisibility(true);
      this.ittsServices.ittsCorporateSearch(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
        this.searchDone=true;
        if(res !== null){
          if(res.results !== null){
            this.list = res.results.companies
          }
          this.sourceOfData = res.sourceOfData;
        }
      });
    }
  }

  openDialogue(template: TemplateRef<any>){
    this.bsModalRef = this.modalService.show(template, { class:  'modal-dialog-centered' })
  }

  openDialogue2(template2: TemplateRef<any>){
    this.bsModalRef2 = this.modalService.show(template2, { class:  'modal-dialog-centered' })
}

  doCheck(object, name, type, number){
    this.companyObject = object
    this.companyName = name
    this.companyType = type
    this.companyNumber = number
  }

  standardCheck(checktype){
    if(this.companyType === "CHINA"){
      const payload = {
          "checkType": checktype,
          "name" : this.companyName,
          "jurisdiction": this.companyForm.value.jurisdiction,
          "country": this.companyForm.value.citizenship,
          "companyNumber" : this.companyNumber,
          "companyDataFormatted" : null,
          "cnCompanyDataFormatted" : this.companyObject
      }
      this.dataService.changeLoaderVisibility(true);
      this.ittsServices.ittsDoAllCheck(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) =>{
        this.checkListId = res.checkListId;
        this.checkType = checktype;
        // this.router.navigate([`report/${this.checkListId}/${checktype}`], { relativeTo: this.activatedRoute })
        this.isReport=true;
      });
    }else if(this.companyType === 'OPEN'){
      const payload = {
          "checkType": checktype,
          "name" : this.companyName,
          "jurisdiction": this.companyForm.value.jurisdiction,
          "country": this.companyForm.value.citizenship,
          "companyNumber" : this.companyNumber,
          "companyDataFormatted" : this.companyObject,
          "cnCompanyDataFormatted" : null
      }
      this.dataService.changeLoaderVisibility(true);
      this.ittsServices.ittsDoAllCheck(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) =>{
        this.checkListId = res.checkListId;
        // this.router.navigate([`report/${this.checkListId}/${checktype}`], { relativeTo: this.activatedRoute })
        this.checkListId = res.checkListId;
        this.checkType = checktype;
        this.isReport=true;
      });
    }
  }


  clear(){
    this.companyForm.reset();
    this.searchDone=false;
    this.list=[];
  }

}
