import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChartsModule } from "ng2-charts";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { PrettyJsonModule } from "angular2-prettyjson";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";

import { FormsModule } from "@angular/forms";
import { KycModalComponent } from "./kyc-modal.component";
import { CreateUserComponent } from "./../../components/create-user/create-user.component";
import { UsersAllChecksComponent } from "./../../components/users-all-checks/users-all-checks.component";
import { AlertScheduleComponent } from "./../../components/alert-schedule/alert-schedule.component";
import { AdminThemingComponent } from "./../../components/admin-theming/admin-theming.component";

import { ChecksOperationComponent } from "./../../pages/report-details/checks-operation/checks-operation.component";

import { CheckoutModule } from "./../../components/checkout/checkout.module";
import { CheckoutNewModule } from "./../../components/checkout-new/checkout-new.module";
import { CouponsModule } from "./../../pages/coupons/coupons.module";
import { LiveCheckModule } from "./../../pages/live-check/live-check.module";

import { FormValidatorModule } from "./../../directives/form-validator/form-validator.module";
import { PipesModule } from "./../../pipes/pipes.module";

import { ImageCropperModule } from "ngx-image-cropper";
import { BulkUploadModule } from "./../../pages/bulk-upload/bulk-upload.module";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ColorPickerModule } from 'ngx-color-picker';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";

@NgModule({
    declarations: [
        KycModalComponent,
        CreateUserComponent,
        UsersAllChecksComponent,
        AlertScheduleComponent,
        AdminThemingComponent,
        ChecksOperationComponent,
    ],
    imports: [
        FormsModule,
        PipesModule,
        ChartsModule,
        NgxChartsModule,
        CheckoutModule,
        CheckoutNewModule,
        CouponsModule,
        LiveCheckModule,
        FormValidatorModule,
        ImageCropperModule,
        CommonModule,
        TabsModule,
        BulkUploadModule,
        NgMultiSelectDropDownModule,
        PrettyJsonModule,
        BsDatepickerModule.forRoot(),
        ColorPickerModule,
    ],
    exports: [KycModalComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class KycModalModule {}
