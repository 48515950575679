import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DevOpsService } from '../services/dev-ops.service';

@Component({
  selector: 'app-add-adver-media',
  templateUrl: './add-adver-media.component.html',
  styleUrls: ['./add-adver-media.component.scss']
})
export class AddAdverMediaComponent implements OnInit {
  userName: any;
  clientId: any;

  constructor(private activatedRoute: ActivatedRoute, private devOpsService: DevOpsService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(({ clientID, userName }) =>{
      this.userName = userName;
      this.clientId = clientID
    });
  }

}
