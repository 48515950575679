import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DevOpsService } from '../../../services/dev-ops.service';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit {
  accountInfo: any = {};
  primaryData: any = {};
  docData: any = {};
  secondaryData: any;
  docDataSecondary: any;

  constructor(private devOpsService: DevOpsService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(({ clientID, accountId }) =>{
      this.devOpsService.getAccountInfoById(clientID, accountId).subscribe((res) => {
        this.accountInfo = res
        console.log('res----',this.accountInfo)
      });
      this.devOpsService.getIndividualInfoByAccountID(clientID, accountId).subscribe((res) => {
        if(res[0]!==undefined && res[0]?.length !== 0){
          this.primaryData = res[0]
        }
        if(res[1]!==undefined && res[1]?.length !== 0){
          this.secondaryData = res[1]
        }
      });
      this.devOpsService.getdigitalUploadList(clientID, accountId).subscribe((res) => {
        for(let item of res){
          if(item.docType === 'IDENTITY'){
            if(item.tpe !== null && item.tpe !== undefined){
              if(item.tpe.kysTpeAccount === 'PRIMARY'){
                this.docData = item
              }
              if(item.tpe.kysTpeAccount === 'SECONDARY'){
                this.docDataSecondary = item
              }
            }
          }
        }
      });
    })
  }

}
