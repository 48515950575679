import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LegalOnboardingService } from 'src/app/pages/legal-onboarding/services/legal-onboarding.service';
import { ActivatedRoute, Router } from '@angular/router';
export interface ExtractedInfo {
  type: string;
  name: string;
  fName: string;
  lName: string;
  address: string;
  documentName: string;
}

@Component({
  selector: 'app-address-verification',
  templateUrl: './address-verification.component.html',
  styleUrls: ['./address-verification.component.scss']
})
export class AddressVerificationComponent implements OnInit{

 @Input() item;
 @Input() headingShow? : boolean = false;
 @Input() accountType? : string;
 @Input() index? : number = 0;
  docData: any;
  faceImageUrl: any;
  extractedInfo: ExtractedInfo;
  shouldFlip: boolean = false;
  isImagePdf: boolean = false;
  frontImage: SafeUrl;
  backImage: SafeUrl;
  verifiedResultData: any;
  individualInfo: Object;


  constructor(private domSanitizer: DomSanitizer, private legalService: LegalOnboardingService, private route : ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
      this.faceImageUrl = this.item.extractedFace;
      this.docData = this.item

      // Verification Reults
      this.legalService.getVerifiedResult(this.item.checkId).subscribe((res) => {this.verifiedResultData = res
      })

      // Individual Information By Kys No
      if(this.item.tpe !== null && this.item.tpe !== undefined){
        console.log("reached address compoent",this.router.url.toString().includes("/dev-ops")  );
        if( this.router.url.toString().includes("/dev-ops") ){
          this.route.paramMap.subscribe(params => {
            let clientId = params.get("clientID");
            this.legalService.getDevopsIndividualInformationByKysNo(clientId,this.item.tpe.kysNo).subscribe((res) => this.individualInfo = res);
          });
        }
        else{
          this.legalService.getIndividualInformationByKysNo(this.item.tpe.kysNo).subscribe((res) => this.individualInfo = res);
        }
      }


      // Front Image URL
      if(this.item.docPathF !== null && this.item.docPathF !== undefined){
        this.legalService.getDocumentUrl(this.item.docPathF).subscribe((url : string) => this.frontImage = this.domSanitizer.bypassSecurityTrustResourceUrl(url));
        if(this.item.docPathF.substring(this.item.docPathF.length - 3) === "pdf"){
          this.isImagePdf = true;
        }
      }

      // Rear Image URL
      if(this.item.docPathR !== null && this.item.docPathR !== undefined){
        this.legalService.getDocumentUrl(this.item.docPathR).subscribe((url : string) => this.backImage = this.domSanitizer.bypassSecurityTrustResourceUrl(url));
      }

      // Extraction Results
      try {
        const extration = JSON.parse(this.item.extractionResult);
        this.extractedInfo = extration ? <ExtractedInfo>{} : null;
        if (extration && extration.extractionType !== null) {
            this.extractedInfo.type = extration.extractionType;
        }
        if (extration && extration.name !== null) {
            this.extractedInfo.name = extration.name;
        }
        if (extration && extration.documentName !== null) {
            this.extractedInfo.documentName = extration.documentName;
        }
        if (extration && extration.address !== null) {
            this.extractedInfo.address = extration.address;
        }
        if (extration && extration.fname !== null) {
            this.extractedInfo.fName = extration.fname;
        }
        if (extration && extration.lname !== null) {
            this.extractedInfo.lName = extration.lname;
        }
      } catch (e) {
          console.log('failed to parse');
      }
  }

  swapImage(img: HTMLImageElement): void {
    img.src = 'https://via.placeholder.com/177x150.png?text=Image%20not%20Uploaded';
  }
    
  flip() {
    this.shouldFlip = !this.shouldFlip;
  }

  openPdf(data){
    this.legalService.getDocumentUrl(data).subscribe(response=>{
      var byteCharacters = atob(response.split(',')[1]);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: 'application/pdf;base64' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL,'_blank');
    })
    
  }
}


