import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../services/data.service';
import { LegalOnboardingService } from '../../pages/legal-onboarding-2.0/services/legal-onboarding.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterAsDialogueComponent } from '../toaster-as-dialogue/toaster-as-dialogue.component';

@Component({
  selector: 'app-new-kyc-modal',
  templateUrl: './new-kyc-modal.component.html',
  styleUrls: ['./new-kyc-modal.component.scss']
})
export class NewKycModalComponent implements OnInit {
  currentTheme: string = localStorage.getItem("currentTheme");
  public onClose: Subject<any> = new Subject();
  type: 
    | "officer-view-edit-page"
    | "fetch-officer"
    | "add-officer"
    | "onboard-add-officers"
    | "crr-break-points"
    | "trade-break-points"

  fetchOfficerList: any[] = [];
  crrGuidDetails: any[] = [];
  tradeDetails:any[];
  companyName: any;
  maritalStatusArray: any = [
    { key: 'Married' },
    { key: 'Unmarried' },
    { key: 'Divorced' },
    { key: 'Widowed' },
  ];
  countries: any[] = [];
  isUSCountry: boolean = false;
  viewType: any = '';
  accountId: any;
  infomationUpdated: boolean = false;
  generatedAPICalls: boolean = false;
  retailSubmitted: boolean = false;
  asNewSubmitted: boolean = false;
  isaddOnboardSubmitted: boolean = false;
  isOfficerEdit: boolean = false;

  constructor(public bsModalRef: BsModalRef, private _app: AppService, private formBuilder : FormBuilder,
    private dataService: DataService, private legalServices: LegalOnboardingService, private modalService: BsModalService) { }

  addMoreDetailsForm: FormGroup = this.formBuilder.group({
    lastName: [''],
    dob: [''],
    maritalStatus: [''],
    phoneNo: [''],
    address: [''],
    ssn: [''],
    taxID: [''],
    percentage: [''],
    status: [''],
    designation: [''],
    firstName: [''],
    email: [''],
    citizenship: ['', Validators.required],
    officerType: [''],
  });
  addFromRetail: FormGroup = this.formBuilder.group({
    officerType: ['', Validators.required],
    customerId: ['', Validators.required],
  });
  addOfficerAsNew: FormGroup = this.formBuilder.group({
    lastName: [''],
    dob: [''],
    maritalStatus: [''],
    phoneNo: [''],
    address: [''],
    ssn: [''],
    taxID: [''],
    percentage: [''],
    officerType: ['', Validators.required],
    status: [''],
    designation: [''],
    firstName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    citizenship: ['', Validators.required],
  });
  addOnboardOfficers: FormGroup = this.formBuilder.group({
    firstName: ['', Validators.required],
    officerType: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });
  officerItem: any;
  officerName: any = '';
  officerTypeArray: any[] = [
    { name: 'UBO' },
    { name: 'DIRECTOR' }
  ];

  ngOnInit(): void {
    this.onClose.next('');
    if(this.viewType == 'perform-kyc' || this.viewType == 'onboard-edit'){
      this.addMoreDetailsForm.enable()
      this.addMoreDetailsForm.get('firstName').disable();
      this.addMoreDetailsForm.get('email').disable();
    }
    else if(this.type == 'add-officer'){
      this.addMoreDetailsForm.enable()
    }
    else{
      this.addMoreDetailsForm.disable();
    }
    this._app.currentTheme.subscribe((theme) => {
      this.currentTheme = theme;
    });
    var themeCall = document.getElementsByClassName('modal-content')[0];
    themeCall.classList.add(this.currentTheme ? this.currentTheme : 'theme-light');
    this._app.getAllCountries().subscribe((res) => (this.countries = res['data'] || []));
    this.patchValues();
    if(this.type == 'crr-break-points'){
      this.getCrrBreakPoints();
    }
  }

  patchValues(){
    const firstName = this.officerItem?.firstName || '';
    const lastName = this.officerItem?.lastName || '';
    this.officerName = firstName + ' ' + lastName;
    if(this.officerItem != null){
      const res = this.officerItem;
      this.onCountrySelect(res.citizenshipCountry?.countryCode);
      res?.dob ? this.addMoreDetailsForm.controls.dob.setValue(formatDate(res?.dob, 'yyyy-MM-dd', 'en-US')) : '';
      this.addMoreDetailsForm.patchValue({
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        maritalStatus: res.maritalStatus,
        citizenship: res?.citizenshipCountry?.countryCode,
        address: res.residenceAddress,
        ssn: res.ssn,
        taxID: res.taxId,
        percentage: res.percentageOfOwnership,
        status: res.currentStatus,
        designation: res.designation,
        phoneNo: res.phoneNumber,
      });
    }
  }

  getCrrBreakPoints(){
    var staticQuestionnaire = {
      key: "Questionnaire",
      score: "12.00",
      weight: "8",
      riskUnitRes: [
        {
          key: "Questionnaire",
          score: "1",
          weight: "8"
        }
      ]
    }
    if(this.crrGuidDetails?.length){
      for(let item of this.crrGuidDetails){
        if(item?.child?.length){
          for(let guid of item?.child){
            this.legalServices.CrrSearch(guid?.guid).subscribe((data) => {
              if(data && data['content'] && data['content']?.length){
                if(data['content'][0]?.riskProfile && data['content'][0]?.riskProfile?.riskFactor && data['content'][0]?.riskProfile?.riskFactor?.length){
                  for(let risk of data['content'][0]?.riskProfile?.riskFactor){
                    guid?.value.push(risk);
                  }
                }
              }
            })
          }
          // Added Static value of Questionnaire for Individual only
          if(item?.type == 'DIRECTOR' || item?.type == 'UBO'){
            for(let guid of item?.child){
              guid?.value.push(staticQuestionnaire);
            }
          }
        }
      }
    }
  }

  onCountrySelect(event) {
    if (event === 'US') {
      this.isUSCountry = true
    }
    else {
      this.isUSCountry = false;
    }
  }

  performKyc(type: string){
    const payload = {
      firstName: this.officerItem.firstName,
      lastName: this.addMoreDetailsForm.value.lastName,
      maritalStatus: this.addMoreDetailsForm.value.maritalStatus,
      dob: this.addMoreDetailsForm.value.dob,
      phoneNumber: this.addMoreDetailsForm.value.phoneNo,
      email: this.officerItem.email,
      residenceAddress: this.addMoreDetailsForm.value.address,
      ssn: this.addMoreDetailsForm.value.ssn,
      taxId: this.addMoreDetailsForm.value.taxID,
      percentageOfOwnership: this.addMoreDetailsForm.value.percentage,
      currentStatus: this.addMoreDetailsForm.value.status,
      designation: this.addMoreDetailsForm.value.designation,
      controlOfficerType: this.officerItem && this.officerItem.controlOfficerType,
      citizenshipCountryCode: this.addMoreDetailsForm.value.citizenship,
    };
    if(type == 'perform-kyc'){
      this.isOfficerEdit = true;
      if(this.addMoreDetailsForm.valid){
        this.dataService.changeLoaderVisibility(true);
    this.legalServices.saveControlOfficer(this.accountId, payload).subscribe((res) => {
      this.infomationUpdated = true;
      this.legalServices.performKYC(this.officerItem.id).pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
        .subscribe(() => {
        this.bsModalRef.hide();
        this.onClose.next('back');
          const initialState = {
            imageUrl: '/assets/images/toaster/event-success.svg',
            subText: 'KYC Initiated, remote link has been sent!',
            type: 'success',
            button: 'Awesome!'
          }
          this.modalService.show(ToasterAsDialogueComponent, { initialState, 
            class: 'modal-dialog-centered toaster-modal'});
        });
      if (res['data'] && res['data'].tpe) {
        const message = {
          "message": "Officer's Information have been updated",
          "page": "Officer Details Update in user provided info",
          "geoLocation": null
        }
        this.legalServices.addActivityLog(res['data'].tpe.kysNo, message).subscribe();
      }
    });
      }
    }
    else if(type == 'edit'){
      this.addMoreDetailsForm.enable();
      this.addMoreDetailsForm.get('firstName').disable();
      this.addMoreDetailsForm.get('email').disable();
      this.viewType = 'edit-details';
    }
    else if(type == 'done'){
      this.bsModalRef.hide();
      this.onClose.next('back');
    }
    else if(type == 'discard'){
      this.addMoreDetailsForm.disable();
      this.viewType = 'view';
      this.patchValues();
    }
    else if(type == 'save-details'){
      this.isOfficerEdit = true;
      if(this.addMoreDetailsForm.valid){
      this.dataService.changeLoaderVisibility(true);
      this.legalServices.saveControlOfficer(this.accountId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      this.infomationUpdated = true;
      this.addMoreDetailsForm.disable();
      this.viewType = 'view';
      if (res['data'] && res['data'].tpe) {
        const message = {
          "message": "Officer's Information have been updated",
          "page": "Officer Details Update in user provided info",
          "geoLocation": null
        }
        this.legalServices.addActivityLog(res['data'].tpe.kysNo, message).subscribe();
      }
    },
    );
    }
    }
    else if(type == 'onboard-edit'){
      this.isOfficerEdit = true;
      if(this.addMoreDetailsForm.valid){
      this.bsModalRef.hide();
      const payload = {
        firstName: this.officerItem.firstName,
        lastName: this.addMoreDetailsForm.value.lastName,
        maritalStatus: this.addMoreDetailsForm.value.maritalStatus,
        dob: this.addMoreDetailsForm.value.dob,
        phoneNumber: this.addMoreDetailsForm.value.phoneNo,
        email: this.officerItem.email,
        residenceAddress: this.addMoreDetailsForm.value.address,
        ssn: this.addMoreDetailsForm.value.ssn,
        taxId: this.addMoreDetailsForm.value.taxID,
        percentageOfOwnership: this.addMoreDetailsForm.value.percentage,
        currentStatus: this.addMoreDetailsForm.value.status,
        designation: this.addMoreDetailsForm.value.designation,
        controlOfficerType: this.officerItem && this.officerItem.controlOfficerType,
        citizenshipCountryCode: this.addMoreDetailsForm.value.citizenship,
      };
      const data = {
        payload: payload,
        id: this.officerItem?.id
      }
      this.onClose.next(data);
    }
    }
  }

  get officerEdit(): { [key: string]: AbstractControl } {
    return this.addMoreDetailsForm.controls;
  }

  generateOfficers(){
    this.dataService.changeLoaderVisibility(true);
    this.legalServices.generateCorporateUbo(this.accountId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.legalServices.getSystemUbo(this.accountId).subscribe((res) => {
        this.fetchOfficerList = res || [];
        this.generatedAPICalls = true;
      });
    });
  }

  get retail(): { [key: string]: AbstractControl } {
    return this.addFromRetail.controls;
  }
  get asNew(): { [key: string]: AbstractControl } {
    return this.addOfficerAsNew.controls;
  }

  addOfficers(addType: string){
    if(addType == 'add-new'){
      this.asNewSubmitted = true;
      if(this.addOfficerAsNew.valid){
        this.bsModalRef.hide();
        const payload = {
          firstName: this.addOfficerAsNew.value.firstName,
          lastName: this.addOfficerAsNew.value.lastName,
          maritalStatus: this.addOfficerAsNew.value.maritalStatus,
          dob: this.addOfficerAsNew.value.dob,
          phoneNumber: this.addOfficerAsNew.value.phoneNo,
          email: this.addOfficerAsNew.value.email,
          residenceAddress: this.addOfficerAsNew.value.address,
          ssn: this.addOfficerAsNew.value.ssn,
          taxId: this.addOfficerAsNew.value.taxID,
          percentageOfOwnership: this.addOfficerAsNew.value.percentage,
          currentStatus: this.addOfficerAsNew.value.status,
          designation: this.addOfficerAsNew.value.designation,
          controlOfficerType: this.addOfficerAsNew.value.officerType,
          citizenshipCountryCode: this.addOfficerAsNew.value.citizenship,
          type: 'added-officers'
        };
        this.onClose.next(payload);
      }
    }
    else if(addType == 'add-retail'){
      this.retailSubmitted = true;
      if (this.addFromRetail.valid) {
        this.bsModalRef.hide();
        const data = {
          id: this.addFromRetail.value.customerId,
          officerType: this.addFromRetail.value.officerType,
          type: 'from-retail'
        }
        this.onClose.next(data);
      }
    }
  }

  get onboardNewOfficers(): { [key: string]: AbstractControl } {
    return this.addOnboardOfficers.controls;
  }

  addOnboardedOfficers(type){
    if(type == 'add'){
      this.isaddOnboardSubmitted = true;
      if (this.addOnboardOfficers.valid) {
        this.bsModalRef.hide();
        this.onClose.next(this.addOnboardOfficers.value);
      }
    }
  }

  expand(id,action){
    if(action=='expand'){
      var element = document.getElementById(id);
      element.classList.add('d-none');
      element.classList.remove('d-inline')
      var expelement=document.getElementById(id+'-expand')
      expelement.classList.add('d-inline')
      expelement.classList.remove('d-none')
    }
    else{
      var element = document.getElementById(id);
      element.classList.remove('d-none');
      element.classList.add('d-inline');
      var expelement=document.getElementById(id+'-expand');
      expelement.classList.remove('d-inline')
      expelement.classList.add('d-none')
    }
    
  }
}
