import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../services/data.service';
import { RetailOnboardingService } from '../../services/retail-onboarding.service';

@Component({
  selector: 'app-retail-risk-policy',
  templateUrl: './retail-risk-policy.component.html',
  styleUrls: ['./retail-risk-policy.component.scss']
})
export class RetailRiskPolicyComponent implements OnInit {
  riskTemplateData: any;
  kysNo: any;
  @Input() accountId;

  constructor(private retailService: RetailOnboardingService, private dataService: DataService) { }

  ngOnInit() {
      this.retailService.getAllIndividualInfo(this.accountId).subscribe((res) => {
        if(res[0].tpe !== null && res[0].tpe !== undefined){
          this.kysNo = res[0].tpe.kysNo
          this.retailService.userSpecificRiskTemplate(res[0].tpe.userName).subscribe((res) => {
            if(res){
              this.riskTemplateData = res.riskTemplate
            }
          })
        }
      })
  }

  resendRemoteLink(){
    this.dataService.changeLoaderVisibility(true);
    this.retailService.resendRemoteLink(this.kysNo).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() =>{
      this.dataService.toastrShow(`Remote Link Has been Re-send`, 'success');
    })
  }

}
