import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApiUploadComponent } from './api-upload/api-upload.component';

const routes: Routes = [
  { path : '', component: ApiUploadComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApiUploadRoutingModule { }
