import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LegalOnboardingService } from '../../../services/legal-onboarding.service';
import { AppService } from '../../../../../services/app.service';

@Component({
  selector: 'app-view-legal-documents',
  templateUrl: './view-legal-documents.component.html',
  styleUrls: ['./view-legal-documents.component.scss']
})
export class ViewLegalDocumentsComponent implements OnInit {
  @Input() item;
  @Output() close = new EventEmitter();
  score: number = 0;
  currentTheme: string=localStorage.getItem('currentTheme');

  constructor(private legalService: LegalOnboardingService, public app: AppService) { }

  ngOnInit() {
    this.app.currentTheme.subscribe(theme=>{
      this.currentTheme=theme;
    })
    this.legalService.getVerifiedResult(this.item.checkId).subscribe((res) => {
      if(res){
        if(res.score !== null){
          this.score = res.score
        }
      }
    })
  }

  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.close.emit();
  }

}
