import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../../services/app.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../services/data.service';
import { LegalOnboardingService } from '../../services/legal-onboarding.service';


@Component({
  selector: 'app-manual-onboarding',
  templateUrl: './manual-onboarding.component.html',
  styleUrls: ['./manual-onboarding.component.scss']
})
export class ManualOnboardingComponent implements OnInit {
  currentTheme: string = localStorage.getItem('currentTheme');
  step = 1;
  state: any;
  riskTemplateData: any;
  public bsModalRef: BsModalRef;
  @Input() accountId: any;
  @Input() isEdit?;
  @Output() close = new EventEmitter<any>();
  isManualOnboard: boolean = true;
  companyData: any;

  checkArr= {
    "identityDoc": true,
    "addressDoc": true,
    "amlCheck": true,
    "adverseMediaCheck": true,
    "nationalityRisk": true,
  }
  amlArr={
    "amlCheck": false,
    "adverseMediaCheck": false,
    "nationalityRisk": false,
    "uboCheck": false,
    "directorCheck": false,
    "documentCheck": false,
    "questionCheck": false,
  }
  questionnaireId: any[];

  constructor(private legalService: LegalOnboardingService, private modalService: BsModalService, private route: Router,
    private dataService: DataService, private _app: AppService) { }

  ngOnInit() {
    this._app.currentTheme.subscribe(theme => {
      this.currentTheme = theme;
    });
    if (this.isEdit == true) {
      this.isManualOnboard = false;
    }
    else {
      this.isManualOnboard = true;
    }
    this.getRiskPolicy()
    this.legalService.getAllInfo(this.accountId).subscribe((res) => {
      this.companyData = res;
    });
  }
  getRiskPolicy(){
    this.legalService.riskPolicy(this.accountId).subscribe(res=>{
      this.riskTemplateData=res;
      console.log("risk1",this.riskTemplateData)
      this.riskTemplateData= [...this.riskTemplateData.map(e=>({
        ...e,
        legal:[...Object.keys(this.amlArr)].map(p=>({label:p,value:true,title:this.convertToTitle(p)})),
        ubo:[...
          [...Object.keys(this.checkArr)].map(p=>({label:p,value:true,active:true,title:this.convertToTitle(p)})),
        {label:"liveliness",value:e?.directorConfigContainer?.idvCheckContainer.idvCheckList[0].liveness,active:e.directorConfigContainer.idvCheckContainer.idvCheckList[0].liveness || false,title:"Liveness Check",},
        {label:"selfie",value:e?.directorConfigContainer?.idvCheckContainer.idvCheckList[0].selfie,active:e.directorConfigContainer.idvCheckContainer.idvCheckList[0].selfie|| false,title:"Selfie Check",},],
        director:[...
          [...Object.keys(this.checkArr)].map(p=>({label:p,value:true,title:this.convertToTitle(p)})),
        {label:"liveliness",value:e?.uboConfigContainer?.idvCheckContainer.idvCheckList[0].liveness || false,title:"Liveness Check"},
        {label:"selfie",value:e?.uboConfigContainer?.idvCheckContainer.idvCheckList[0].selfie || false,title:"Selfie Check"}]
         
      }))]
      console.log("risk", this.riskTemplateData)
    })
    
    
  }
  
  customiseTemplate(riskTemplateId){
    let data=this.riskTemplateData.find(e=>e.riskTemplateID===riskTemplateId);
    let temp={
      ubo:{},
      director:{}
    }
    data.legal.forEach(e=>{
      temp[e.label]=e.value
    })
    let temp1={}
    data.ubo.forEach(e=>{
      temp1[e.label]=e.value
    })
    if(temp1['selfie']==true){
      temp1['identityDoc']=true
    }
    let temp2={}
    data.director.forEach(e=>{
      temp2[e.label]=e.value
    })
    temp.ubo=temp1;
    temp.director=temp1;
    let payload={
      kysNo:localStorage.getItem("companyKysNo"),
      riskTemplateId:riskTemplateId,
      legal:temp,
      questionnaireId:this.questionnaireId
    }
    this.legalService.updateTemplate(payload).subscribe(res=>{

    })

  }
  clickClose() {
    this.bsModalRef.hide()
  }

  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.close.emit();
  }

  changeStep(event) {
    this.step = event.step;
    this.state = event.state ? event.state : undefined;
    console.log("parent event: ", event.state);
  }

  goToMain(event) {
    this.close.emit(event);
  }

  selectTemplate(template) {
    this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' })
  }

  getLinkTemplate(template, remoteLink) {
    this.bsModalRef.hide();
    this.isManualOnboard = false;
    const payload = {
      accountId: this.accountId,
      riskTemplateId: template.riskTemplateID,
      remoteCheck: remoteLink,
    };
    this.dataService.changeLoaderVisibility(true);
    this.legalService.assignCorporateRiskTemplate(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.isManualOnboard = false;
      const payload = {
        "message": 'Risk templete has been assigned',
        "page": "Risk Template",
        "geoLocation": null
      }
      this.legalService.addActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Risk Template",
          "geoLocation": null
        }
        this.legalService.addActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    );
  }

  assignRiskTemplate(template, remoteLink) {
    this.bsModalRef.hide();
    const payload = {
      accountId: this.accountId,
      riskTemplateId: template.riskTemplateID,
      remoteCheck: remoteLink,
    };
    this.dataService.changeLoaderVisibility(true);
    this.legalService.assignCorporateRiskTemplate(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.dataService.toastrShow(`Remote link has been sent`, 'success');
      this.close.emit('goToList');
      const payload = {
        "message": 'Remote Link has been sent',
        "page": "Remote Link",
        "geoLocation": null
      }
      this.legalService.addActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Remote Link",
          "geoLocation": null
        }
        this.legalService.addActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    );
  }
  openDailog(template){
    this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered modal-lg' })
    this.bsModalRef.content.closeBtnName = 'Close'; 
  }
  closeModal(event){
    this.bsModalRef.hide();
    this.dataService.questionnaireId.subscribe(res=>{
      let id=res;
      this.questionnaireId=[]
      this.questionnaireId.push(id)
    })
  }
  convertToTitle(str){
    if(str=="amlCheck"){
      return "AML Check"  
    }
    else if(str=='questionCheck'){
     return "Questionnaire"
    }
    else if(str=='uboCheck'){
      return "UBO Check"
    }
    else{
      let a=[]
      a=str.split(/(?=[A-Z])/)
      a[0]=a[0].charAt(0).toUpperCase()+a[0].slice(1)
      str =a.join(" ")
      return str
    }
  }

}
