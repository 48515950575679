import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { DevOpsService } from '../../services/dev-ops.service';

@Component({
  selector: 'app-legal-details',
  templateUrl: './legal-details.component.html',
  styleUrls: ['./legal-details.component.scss']
})
export class LegalDetailsComponent implements OnInit {
  name: any;
  accountId: any;
  riskTemplateData: any;
  clientId: any;
  userName: any;

  constructor(private activatedRoute: ActivatedRoute, private devOpsService: DevOpsService, private dataService: DataService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(({ clientID, userName, accountId, name }) =>{
      this.name = name;
      this.accountId = accountId;
      this.clientId = clientID;
      this.userName = userName
      this.devOpsService.getUserSpecificRiskTemplate(clientID, accountId).subscribe((res) => {
        this.riskTemplateData = res.riskTemplate
      })
    })
  }

}
