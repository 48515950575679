import { Component, OnInit, TemplateRef } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { ServiceService } from '../service/service.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination'
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-partial-match-list',
    templateUrl: './partial-match-list.component.html',
    styleUrls: ['./partial-match-list.component.scss']
})
export class PartialMatchListComponent implements OnInit {
    check: any = "MATCHED";
    filterOrNot: boolean = true;

    list: any[] = [];

    page = 1;
    jump: number;
    itemsPerPage = 10;
    maxSize = 10;
    total = 100;
    filter: any;
    filterbyAccount: any;
    serchForm: FormGroup;
    serchForm2: FormGroup;
    isTrue: boolean;
    data: any = [{}];
    bsModalRef: BsModalRef;
    bsModalRef2: BsModalRef;
    riskReportId: number;
    source: any = ["Fincen Individuals", "Fincen Organisations"];
    fincaiTab: any = "active";
    otherTab: any = "";
    tab = "fincen";

    constructor(
        private _data: DataService,
        private _app: ServiceService,
        public router: Router,
        private modalService: BsModalService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute) { }

    ngOnInit() {
        this.serchForm = this.formBuilder.group({
            search: ['']
        });
        this.serchForm2 = this.formBuilder.group({
            search2: ['']
        });
        this.getval();
    }
    getval() {
        const payload = {
            "size": 10,
            "page": this.page - 1,
            "order": "ASC",
            "dobStatus": null,
            "nameStatus": null,
            "addressStatus": null,
            "manualCheckStatus": null,
            "toggle": this.check,
            "source": this.source
        }
        this._data.changeLoaderVisibility(true);
        this._app.getIrResult(payload).pipe(finalize(() => { }))
            .subscribe((res) => {
                if (res == null) {
                    this.data = []
                } else {
                    this.data = res.content;
                }

                this.total = res.totalElements;
                this._data.changeLoaderVisibility(false);
            });
    }
    otherData() {
        const payload = {
            "size": 10,
            "page": this.page - 1,
            "order": "ASC",
            "dobStatus": null,
            "nameStatus": null,
            "addressStatus": null,
            "manualCheckStatus": null,
            "toggle": this.check,
            "source": this.source
        }
        this._data.changeLoaderVisibility(true);
        this._app.getIrOtherResult(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false)))
            .subscribe((res) => {
                if (res == null) {
                    this.data = []
                } else {
                    this.data = res.content;
                }

                this.total = res.totalElements;
                this._data.changeLoaderVisibility(false);
            });
    }
    tabAssign(val) {
        this.tab = val;
        if (val == 'all') {
            this.otherTab = "active";
            this.fincaiTab = "";
            let page = 1;
            let itemsPerPage = 10;
            this.onPageChange({ itemsPerPage, page })
        } else {
            this.otherTab = "";
            this.fincaiTab = "active";
            let page = 1;
            let itemsPerPage = 10;
            this.onPageChange({ itemsPerPage, page })
        }

    }
    filterAllMatched() {
        this.filterOrNot = !this.filterOrNot;
        if (!this.filterOrNot) {
            this.check = "ALL"
        }
        else {
            this.check = "MATCHED"
        }
        if (this.fincaiTab == "active") {
            this.getval();
        }
        else {
            this.otherData();
        }

    }
    overrideRisk(id, enumVal, cmt) {
        // console.log("entered cmt: ",cmt);
        const payload = {
            "id": id,
            "status": enumVal,
            "comment": cmt
        }
        this._data.changeLoaderVisibility(true);
        this._app.overrideRiskReport(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false)))
            .subscribe((res) => {
                let alertObj = {
                    message: "Data Updated",
                    status: 'success',
                    autoDismiss: true,
                    timeToDismiss: 5000,
                };
                if (this.fincaiTab == "active") {
                    this.getval();
                }
                else {
                    this.otherData();
                }
                this._data.toastrShow(alertObj.message, alertObj.status);

            });


    }
    openDialogue(template: TemplateRef<any>, id) {
        this.riskReportId = id;
        this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered' })
    }

    openDialogue2(template2: TemplateRef<any>, id) {
        this.riskReportId = id;
        this.bsModalRef2 = this.modalService.show(template2, { class: 'modal-dialog-centered' })
    }
    intermediateRisk(val, val2) {
        const risk = {
            "id": val2,
            "data": val,
        }
        this._data.intRiskReport(risk);
        this._data.tabValAssign(1);
        this.router.navigate(['risk-report/report/']);
    }
    searchFilter() {
        this.isTrue = true;
        this.page = 1;
        const payload = {
            "name": this.serchForm.value.search,
            "page": this.page - 1,
            "size": 10,
            "order": 'ASC',
            "source": this.source,
            "searchData": this.tab.toUpperCase()
        }
        if (this.serchForm.value.search == null || this.serchForm.value.search == "") {
            if (this.fincaiTab == "active") {
                this.getval();
            }
            else {
                this.otherData();
            }

        } else {

            this._app.intermediateRiskSearch(payload).subscribe((res) => {
                this.data = res.content;
                this.total = res.totalElements;
            })

        }

    }

    filterSearch() {
        this.isTrue = true
        const payload = {
            "name": this.serchForm.value.search,
            "page": this.page - 1,
            "size": 10,
            "orderBy": null,
            "order": 'ASC'
        }
        this._app.intermediateRiskSearch(payload).subscribe((res) => {
            this.data = res.content;
            this.total = res.totalElements;
        })
    }

    onPageChange({ itemsPerPage, page }: PageChangedEvent) {
        this.itemsPerPage = itemsPerPage;
        this.page = page;

        if (this.serchForm.value.search == null || this.serchForm.value.search == "") {
            if (this.fincaiTab == "active") {
                this.getval();
            }
            else {
                this.otherData()
            }
        } else {
            const payload = {
                "name": this.serchForm.value.search,
                "page": this.page - 1,
                "size": 10,
                "order": 'ASC',
                "source": this.source,
                "searchData": this.tab.toUpperCase()
            }
            this._app.intermediateRiskSearch(payload).subscribe((res) => {
                this.data = res.content;
                this.total = res.totalElements;
            })

        }
    }
    jumpTo() {
        console.log("working")
        if (this.jump-1 <= this.total/10) {
            if (Math.sign(this.jump) != -1 && Math.sign(this.jump) != 0) {
                let page = this.jump;
                let itemsPerPage = 10;
                this.onPageChange({ itemsPerPage, page })
            }
        }
        else{
            let alertObj = {
                message: "Exceeding the Pages we have",
                status: 'failure',
                autoDismiss: true,
                timeToDismiss: 2000,
            };
            this._data.toastrShow(alertObj.message, alertObj.status);
        }

    }
}