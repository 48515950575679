import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService } from "../../services/data.service";
import { AppService } from "../../services/app.service";

@Component({
  selector: 'app-transaction-slider',
  templateUrl: './transaction-slider.component.html',
  styleUrls: ['./transaction-slider.component.scss']
})
export class TransactionSliderComponent implements OnInit {

  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>

  array = [];
  sum = 10;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  start: number = 0;
  subscriptionData: any;
  isIndia = window.sessionStorage.getItem('isIndia');
  planHistory: any = {
    name: 'Plan History',
    currentPage: 0,
    itemsPerPage: 4,
    totalItems: null,
    maxSize: null,
    sort: "DESC",
    data: [],
    totalCredits: 0,
    // buildQuery: {
    //     'size': 4,
    //     'page': 0,
    //     'orderBy': "createdDate",
    //     'sort': "DESC",
    //     'startDate': '',
    //     'endDate': ''
    // }
  }
  transactionArray = [];

  constructor(
    private _data: DataService,
    private _app: AppService,
  ) { }

  ngOnInit(): void {
    let token = this._data.getCookiesByName("token")
    if (token) {
      this.getPlanHistory(this.sum, this.start);
      this.getClientSubscriptionInfo();
    }
  }

  getPlanHistory(size, page) {
    this._data.changeLoaderVisibility(true);
    this._app.getPlanHistory(size, page).subscribe(res => {
      this.planHistory.data = res.data.content.map(e => {
        if (e.serviceType == 'LICENCE') {
          var date = new Date(e.lastModifiedDate);
          date.setDate(date.getDate() + e.licence.licenceDuration)
          e.remainingDays = date;
        }
        // console.log(e);
        return e;
      });
      this.planHistory.data.forEach(element => {
        this.transactionArray.push(element)
      });
      console.log("transctin list: ", this.transactionArray);
      this.planHistory.totalCredits = (res.data.content.length) ? res.data.content.reduce((a, b) => (a.totalCredit > b.totalCredit) ? a : b) : 0;
      console.log('planHistory.totalCredits', this.planHistory.totalCredits);
      this._data.changeLoaderVisibility(false);
      if (this.planHistory.currentPage === 0) {
        this.planHistory.totalItems = res.data.totalElements;
        this.planHistory.maxSize = res.data.totalPages;
      }
      console.log(res);
    })
  }

  isExpired(date) {
    return Date.parse(date) < Date.now();
  }

  onScrollDown() {
    // add another 20 items  
    console.log("triggered down scroll");
    this.start += 1;
    this.getPlanHistory(this.sum, this.start);
    this.direction = "down";
  }

  getClientSubscriptionInfo() {
    this._app.getClientSubscriptionInfoV2()
      .subscribe(
        (res) => {
          console.log(res);
          this.subscriptionData = res.data;

        }
      );
  }
}
