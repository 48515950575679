export class SelectedIdMessage{
  frontPhotoMessage : string;
  rearPhotoMessage  : string;
  constructor( ) {
    this.frontPhotoMessage = "" ;
    this.rearPhotoMessage = "" ;
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "../../services/data.service";
import { AppService } from "../../services/app.service";
import { finalize } from "rxjs/operators";

import { Verification } from "../../models/Verification.model";

import {
MatchAttributeStatus,
VerificationStates,
} from "../../utils/status";

import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { KycModalComponent } from "../../shared/kyc-modal/kyc-modal.component";
import { TrackingService } from '../../services/tracking.service';
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-all-doc-upload',
  templateUrl: './all-doc-upload.component.html',
  styleUrls: ['./all-doc-upload.component.scss']
})
export class AllDocUploadComponent implements OnInit {

  @Input() docType: string;
  @Input() selectedDocName:string;
  @Input() multiDoc?: string; // Only For Ad-Hock
  @Input() kysNo?: any; // Only For Ad-Hock
  @Input() selectedDoc?: any ;
  @Input() checkId?: any;
  @Input() states? : any[] = [];
  @Input() selectedDocId? : any; // Only For Ad-Hock
  @Input() uploadType: any;  // values= "LEGAL" || "CONTROL_OFFICER" || "RETAIL" || "REUPLOAD" 
  @Input() accountId?:any;
  @Input() controleOfficerId?:any;
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() upload: EventEmitter<any> = new EventEmitter();
  selectedId             : string;
  selectedIdMessage      : SelectedIdMessage = new SelectedIdMessage();
  idDoc                  : any = {};
  
  //boolean value to check if ID upload process is fresh or ID already uploaded.
  isIDUpdate             : boolean = false;
  verification           : Verification;
  endUserDocInfo         : any = null;
  startDate: any;
  endDate: any;

  knOptions = {
    readOnly: true,
    size: 140,
    unit: "%",
    textColor: "#ffffff",
    fontSize: "18",
    fontWeigth: "400",
    fontFamily: "Roboto",
    valueformat: "percent",
    value: 0,
    max: 250,
    trackWidth: 15,
    barWidth: 15,
    trackColor: "#e1e1e1",
    barColor: "#ff0000",
  };

  //OCR result
  ocrdata                 : any = {};
  faceUrl                 : any = null;
  extAllowed              : any[] = ["image/png", "image/jpeg", "image/jpg"];
  bsModalRef              : BsModalRef;
  disablegoto: boolean=false;
  disablProceed: boolean=true;
  deviceInfo: any;
  ip: any;
  pdfURL: any;
  isStateMandatory:boolean=false;

  constructor(
    private modalService: BsModalService,
    private _data: DataService,
    private _app: AppService,
    public router: Router,
    private route: ActivatedRoute
  ) {

   }

   ngOnInit() {
    this.verification                =  new Verification();
    this.idDoc["docType"]            =  this.docType; 
    this.idDoc["docSerialNo"]        =  "";
    this.idDoc["provinceCode"]       =  "";
    this.idDoc["issueDate"]          =  "";
    this.idDoc["expiryDate"]          =  "";

    if(this.selectedDoc && this.selectedDoc.mandatoryField){
      console.log("selectd doc",this.selectedDoc);
      let split = this.selectedDoc.mandatoryField.split('|')
      for(let i=0; split.length>i; i++){        
        if(split[i]==="state"){
            this.isStateMandatory = true
        }
      }
    }

  }

  shouldShowInputField(attr) {
    console.log("in show field: ",this.selectedDoc);
  }

  getDocFromServer(checkGuid) {
    this._app.getDocFromServer(checkGuid).subscribe(res => {
      this.idDoc["docFrontUrl"] = `data:image/jpeg;base64,${
        res.data["frontDoc"]
      }`;
      this.idDoc["docRearUrl"] = `data:image/jpeg;base64,${
        res.data["rearDoc"]
      }`;
    });
  }

  getVerificationText(key, value, isIcon?: any) {
    let matched = VerificationStates.filter(obj => {
      if (obj.field == key && obj.status == value) {
        return obj;
      }
    });
    if (matched.length) {
      return isIcon ? matched[0].icon : matched[0].text;
    }
  }

  fileChangeListener($event, docSlug, docUrlType) {
    var image: any = new Image();
    var file: File = $event[0] ? $event[0] : $event.target.files[0];
    console.log("image file before: ", file);
    console.log("FIle TOuched");
    console.log(file);
    if (file) {
      if(file.size<=5000000){
        var name = file.name;
        if (this.extAllowed.indexOf(file.type) >= 0) {
          this._data.changeLoaderVisibility(true);
          var reader = new FileReader();
          var that = this;
          setTimeout(()=>{
            this._data.changeLoaderVisibility(false);
            that.openCropModal($event, docSlug, docUrlType);
          },200);
        } else if (file.type === "application/pdf") {
          this._data.changeLoaderVisibility(true);
          console.log("Selected pdf");
          var fileReader = new FileReader();
          var base64;
          var that = this;
          // Onload of file read the file content
          fileReader.onload = function(fileLoadedEvent) {
            base64 = fileReader.result;
            // Print data in console
            //   console.log(base64);
            // that.idDoc[docUrlType] = base64;
            that.idDoc[docUrlType+'Pdf'] = {name : file.name, file : base64};
            console.log("image file after: ", that.idDoc[docUrlType+'Pdf']);
          };
          // Convert data to base64
          fileReader.readAsDataURL(file);
          // this.idDoc[docUrlType] = result["image"];
          // this.idDoc["docSlug"] = docSlug;
          setTimeout(()=>{
            this._data.changeLoaderVisibility(false);
          },200);
          console.log("::::::::;;;;", this.idDoc);
        }else {
          this._data.toastrShow(
            "File extension not supported , we support png , jpeg , jpg & pdf.",
            "error"
          );
        }
      }
      else{
        this._data.toastrShow(
          "Please Upload File less than 5 Mb.",
          "error"
        );
      }
    }
  }

  onFilesChange($event, docSlug, docUrlType) {
    this.fileChangeListener($event, docSlug, docUrlType);
  }

  openCropModalCamera(event, docSlug, docUrlType) {
    const initialState = {
      title: `Crop Image`,
      type: "crop-image-b64",
      imgAsb64: event,
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
      // console.log("results", result);
      if (result && Object.keys(result).length) {
        console.log(result);
        this.idDoc[docUrlType] = result["image"];
        this.idDoc["docSlug"] = docSlug;
      }
    });
  }
  openCameraModal(type, docSlug, docUrlType) {
    // console.log({ selfie: this.selfieObj, liveness: this.livenessObj });
    const initialState = {
      title: `Capture Document`,
      type: "capture-doc",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
      //get image from camera
      if (result && Object.keys(result).length) {
        console.log(result);
        this.openCropModalCamera(result, docSlug, docUrlType);
      }
    });
  }

  openCropModal(event, docSlug, docUrlType) {
    const initialState = {
      title: `Crop Image`,
      type: "crop-image",
      loadedImageEvent: event,
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
      keyboard:false,
      backdrop:'static'
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
      // console.log("results", result);
      if (result && Object.keys(result).length) {
        // console.log(result);
        this.idDoc[docUrlType] = result["image"];
        this.idDoc["docSlug"] = docSlug;
      }
    });
  }
  
  saveIdDocs(){
    this._data.changeLoaderVisibility(true);
      const idDocIssDInd = new DatePipe('en-US').transform(this.idDoc["issueDate"], 'dd/MM/yyyy');
      const idDocExpDInd = new DatePipe('en-US').transform(this.idDoc["expiryDate"], 'dd/MM/yyyy');
      
      console.log("reached address compoent",this.router.url.toString().includes("/dev-ops")  );
        if( this.router.url.toString().includes("/dev-ops") ){
          const formData = new FormData();
          formData.append('frontBase64', this.idDoc['docFrontUrl']?this.idDoc['docFrontUrl']:this.idDoc['docFrontUrlPdf']['file']?this.idDoc['docFrontUrlPdf']['file']:null);
          formData.append('rearBase64', this.idDoc['docRearUrl']?this.idDoc['docRearUrl']:null);
          formData.append('accountId', this.accountId?this.accountId:'');
          formData.append('docId', this.selectedDoc.kysDocumentID?this.selectedDoc.kysDocumentID:(this.selectedDoc.kysDocument && this.selectedDoc.kysDocument.kysDocumentID)?this.selectedDoc.kysDocument.kysDocumentID:'');
          formData.append('checkId', this.checkId?this.checkId:'');
          formData.append('uploadType', this.uploadType);
          if(idDocIssDInd){
            formData.append('docIssueDate', idDocIssDInd);
          }
          if(idDocExpDInd){
            formData.append('docExpiryDate', idDocExpDInd);
          }
          formData.append('docName', this.selectedDocName);
          formData.append('idNumber', this.idDoc["docSerialNo"]?this.idDoc["docSerialNo"]:'');
          formData.append('stateCode', this.idDoc["provinceCode"]?this.idDoc["provinceCode"]:'');
          this.route.paramMap.subscribe(params => {
            let clientId = params.get("clientID");
            this._data.changeLoaderVisibility(true);
            this._app.devOpsCommonDocUploadAPIForAll(clientId, formData).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() =>{
              let popup ={
                message: this.selectedDocName + ' Uploaded Successfully',
                status: 'success'
              }
              this._data.changeLoaderVisibility(false);
              this._data.toastrShow(popup.message, popup.status);
              this.upload.emit("uploaded successfully");
              this.back.emit(false);
            })
          });
        }
        else if(this.multiDoc === 'ADHOCK'){
          let payload={
            frontImage:this.idDoc['docFrontUrl']?this.idDoc['docFrontUrl']:this.idDoc['docFrontUrlPdf']['file']?this.idDoc['docFrontUrlPdf']['file']:null,
            rearImage: this.idDoc['docRearUrl']?this.idDoc['docRearUrl']:null,
            accountId: this.accountId?this.accountId:null,
            docId: null,
            checkId: null,
            kysDocType: this.docType ? this.docType : null,
            kysNo: this.kysNo,
            docName: this.selectedDocName,
            // idNumber: this.idDoc["docSerialNo"]?this.idDoc["docSerialNo"]:'',
            // stateCode: this.idDoc["provinceCode"]?this.idDoc["provinceCode"]:'',
            // docIssueDate:idDocIssDInd?idDocIssDInd:null,
            // docExpiryDate:idDocExpDInd?idDocExpDInd:null
          }
          this._data.changeLoaderVisibility(true);
          this._app.retailAdHock(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() =>{
            let popup ={
              message: this.selectedDocName + ' Uploaded Successfully',
              status: 'success'
            }
            this._data.changeLoaderVisibility(false);
            this._data.toastrShow(popup.message, popup.status);
            this.upload.emit("uploaded successfully");
            this.back.emit(false);
          })
        }
        else {
          let payload={
            frontBase64:this.idDoc['docFrontUrl']?this.idDoc['docFrontUrl']:this.idDoc['docFrontUrlPdf']['file']?this.idDoc['docFrontUrlPdf']['file']:null,
            rearBase64: this.idDoc['docRearUrl']?this.idDoc['docRearUrl']:null,
            accountId: this.accountId?this.accountId:null,
            docId: this.selectedDoc.kysDocumentID?this.selectedDoc.kysDocumentID:(this.selectedDoc.kysDocument && this.selectedDoc.kysDocument.kysDocumentID)?this.selectedDoc.kysDocument.kysDocumentID:'',
            checkId:this.checkId?this.checkId:'',
            uploadType: this.uploadType,
            docName: this.selectedDocName,
            idNumber: this.idDoc["docSerialNo"]?this.idDoc["docSerialNo"]:'',
            stateCode: this.idDoc["provinceCode"]?this.idDoc["provinceCode"]:'',
            docIssueDate:idDocIssDInd?idDocIssDInd:null,
            docExpiryDate:idDocExpDInd?idDocExpDInd:null
          }
          this._data.changeLoaderVisibility(true);
          this._app.globalDocUploadApi(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() =>{
            let popup ={
              message: this.selectedDocName + ' Uploaded Successfully',
              status: 'success'
            }
            this._data.changeLoaderVisibility(false);
            this._data.toastrShow(popup.message, popup.status);
            this.upload.emit("uploaded successfully");
            this.back.emit(false);
          })
        }
    }

  goback(){
    this.back.emit(false);
  }

}
