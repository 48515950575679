import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { DevOpsService } from '../../services/dev-ops.service';

@Component({
  selector: 'app-update-articles',
  templateUrl: './update-articles.component.html',
  styleUrls: ['./update-articles.component.scss']
})
export class UpdateArticlesComponent implements OnInit {
  articleId: any;
  clientId: any;
  list: any[] = [];

  constructor(private formBuilder: FormBuilder, private services: DevOpsService, private dataService: DataService, private route: ActivatedRoute) { }

  serchForm: FormGroup = this.formBuilder.group({
    search: ['']
  });
  addAdverseForm: FormGroup = this.formBuilder.group({
    entityName: [''],
    author: [''],
    title: [''],
    url: [''],
    body: [''],
    articleId: [''],
    adverseEntity: ['']
  });

  ngOnInit() {
    this.route.params.subscribe(({ clientID }) => this.clientId = clientID)
  }

  searchFilter(){
    const articleId = this.serchForm.value.search;
    this.services.getAdverseMediaEntityById(articleId, this.clientId).subscribe((res) => {
      if(res.length !== 0){
        this.list = res
      }
      else{
        let popup = {
          message: 'No Adverse Entity Name exist for the ' + articleId,
          status: 'warning'
        }
        this.dataService.toastrShow(popup.message, popup.status);
      }
    });
  }

  addAdverseDetais(){
    const articleId = this.addAdverseForm.value.articleId;
    const payload = {
      "articleBody": this.addAdverseForm.value.body,
      "articleUrl": this.addAdverseForm.value.url,
      "articleTitle": this.addAdverseForm.value.title,
      "articleAuthor": this.addAdverseForm.value.author,
      "adverseEntities": null,
    }
    this.dataService.changeLoaderVisibility(true);
    this.services.updateAdverseMediaArticles(articleId, this.clientId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      this.articleId = res.articleId 
      let popup = {
        message : 'Article Body is updated for Article Id' + ' ' + this.articleId,
        status: 'success'
      }
      this.addAdverseForm.reset();
      this.dataService.toastrShow(popup.message, popup.status);
    });
  }

  addAdverseEntity(){
    const payload = {
      "entityName" : this.addAdverseForm.value.adverseEntity
    }
    const articleId = this.addAdverseForm.value.articleId;
    const oldEntityName = this.addAdverseForm.value.entityName;
    this.dataService.changeLoaderVisibility(true);
    this.services.updateAdverseMediaEntity(articleId, this.clientId, oldEntityName, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      // this.dataService.toastrShow
      this.addAdverseForm.reset();
    })
  }

  clearFilter(){
    this.serchForm.get('search').setValue(null);
    this.list.length = 0;
  }

}
