import { Component, OnInit, ViewChild } from '@angular/core';
import { RetailListComponent } from '../retail-list/retail-list.component';

@Component({
  selector: 'app-main-component',
  templateUrl: './main-component.component.html',
  styleUrls: ['./main-component.component.scss']
})
export class MainComponentComponent implements OnInit {
  isList: boolean = true;
  @ViewChild(RetailListComponent) rtlLst:RetailListComponent;
  constructor() { }

  ngOnInit(): void {
  }

  toggleTab(tabName){
    if(tabName === 'list'){
      this.isList = true
    }
    else{
      this.isList = false
    }
  }

  goToLegalList(){
    this.isList = true;
    this.rtlLst.getList('',0,10);
  }

}
