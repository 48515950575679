import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RetailListComponent } from './retail-onboarding/retail-list/retail-list.component';
import { AmlCorporateReportComponent } from './aml-corporate-check/aml-corporate-report/aml-corporate-report.component';
import { SearchListComponent } from './aml-corporate-check/search-list/search-list.component'
import { ClientListComponent } from './client-list/client-list.component';
import { LegalDetailsComponent } from './legal-onboarding/legal-details/legal-details.component';
import { LegalListComponent } from './legal-onboarding/legal-list/legal-list.component';
import { OverallStatusComponent } from './legal-onboarding/overall-status/overall-status.component';
import { RetailDetailsComponent } from './retail-onboarding/retail-details/retail-details.component';
import { RetailOverallStatusComponent } from './retail-onboarding/overall-status/overall-status.component';
import { AddAdverMediaComponent } from './add-adver-media/add-adver-media.component';

const routes: Routes = [
  { path: 'dev-ops', component: ClientListComponent },
  { path: 'retail-list/:clientID/:userName', component: RetailListComponent, },
  { path: "retail-details/:clientID/:userName/:accountId/:name", component: RetailDetailsComponent },
  { path: 'corp-search-list/:clientID/:userName', component: SearchListComponent },
  { path: 'corp-check-report/:clientID/:userName/:checkListId/:checkType', component:AmlCorporateReportComponent},
  { path: 'legal-list/:clientID/:userName', component: LegalListComponent, },
  { path: "legal-details/:clientID/:userName/:accountId/:name", component: LegalDetailsComponent },
  { path: "overall-status/:clientID/:userName/:accountId/:name", component: OverallStatusComponent },
  { path: "retail-overall-status/:clientID/:userName/:accountId/:name", component: RetailOverallStatusComponent },
  { path: 'add-adverse-media/:clientID/:userName', component: AddAdverMediaComponent, },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DevOpsRoutingModule { }
