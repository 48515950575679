export class EndUser {
  identityInfo: string;

  email: string;
  endUserType: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: any;
  gender?: string;
  country?: string;
  countryCode: string;
  mobileNumber: any;

  fatherName?: string;
  individualUserType?: string;
  placeOfBirthCity?: string;
  placeOfBirthCountry?: string;
  placeOfBirthState?: string;

  companyName?: string;
  companyID?: string;
  incorporationDate?: string;
  branchCount?: string;
  isChildCompany?: string;

  officerID?: any;

  checks?: any;
  remoteChecks?: any;
  dashboardChecks?: any;

  company?: any;
}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl
} from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { DataService } from "../../../services/data.service";
import { AppService } from "../../../services/app.service";
import { first } from "rxjs/operators";
@Component({
  selector: "app-individual-form",
  templateUrl: "./individual-form.component.html",
  styleUrls: ["./individual-form.component.scss"]
})
export class IndividualFormComponent implements OnInit, OnChanges {
  @Input() countries: any;
  @Output() onStatusUpdate: EventEmitter<Boolean> = new EventEmitter(false);
  @Output() canUpdateUserEmail: EventEmitter<any> = new EventEmitter();
  @Output() onUserUpdate: EventEmitter<any> = new EventEmitter();
  registerForm: FormGroup;
  submitted: boolean = false;
  @Input() endUser: any;

  allowedDocs: any;

  currentDate: any;

  //ngx-bootstrap
  myDateValue: Date;
  bsConfig: any;

  //for checking previous checks
  hasChecks: {
    hasPendingChecks: boolean;
    isUserExist: boolean;
  } = {
    hasPendingChecks: false,
    isUserExist: false
  };

  // temp var for storing selected country
  countrySelected: any;

  constructor(
    private formBuilder: FormBuilder,
    private _data: DataService,
    private _app: AppService
  ) {
    var date = new Date();
    this.currentDate = date
      .toISOString()
      .substring(0, date.toISOString().indexOf("T"));
  }

  ngOnInit() {
    // this.myDateValue = new Date();
    this.bsConfig = {
      containerClass: "theme-default",
      dateInputFormat: "DD-MM-YYYY"
    };

    console.log(this.myDateValue);

    console.log(this.endUser);
    if (!this.endUser || !Object.keys(this.endUser).length) {
      this.endUser = new EndUser();
    } else {
      if (this.endUser["residenceCountry"]) {
        this.countrySelected = this.endUser.residenceCountry["countryCode"];
        this.endUser.countryCode = this.countrySelected;
      }
    }
    console.log(this.endUser);
    this.registerForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: [""],
      //identityInfo: ["" , [Validators.required, Validators.email]],
      identityInfo: ["", Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$")],
      dob: ["", CustomValidators.maxDate(this.currentDate)],
      mobileNumber: [
        "",
        [Validators.pattern("^[+]{0,1}[0-9]{0,3}[-]{0,1}[0-9]{10}")]
      ],
      // gender   : ["" , [Validators.required]],
      countryCode: [""]
      // countryCode : ["", Validators.required]
    });

    // this.registerForm.valueChanges.subscribe((formData) => {
    // 	console.log(formData);
    // 	formData["firstName"] = formData["firstName"].trim() ;
    // });

    this.registerForm.valueChanges.subscribe(formData => {
      this.onStatusUpdate.emit(this.registerForm.valid);
      if (this.registerForm.valid) {
        // console.log(this.registerForm.value);
        if (this.endUser["officerID"] && this.endUser["companyID"]) {
          this.registerForm.value.officerID = this.endUser["officerID"];
          this.registerForm.value.companyID = this.endUser["companyID"];
        }
        // console.log(this.registerForm.value);
        // console.log(this.registerForm.getRawValue());
        // console.log(this.endUser);
        this.onUserUpdate.emit(this.registerForm.getRawValue());
        // this.onUserUpdate.emit(this.registerForm.value);
      }
    });

    // (<FormGroup>this.registerForm).setValue(this.endUser, {onlySelf: true});
    this.disableNonUpdatebleFields();
    this.registerForm.patchValue(this.endUser);
    console.log(this.registerForm.valid);

    //PATCH for Enabling Next Button Starts
    if (
      this.endUser["identityInfo"] &&
      this.endUser["identityInfoType"] != "GUID" &&
      this.endUser["identityInfo"].length
    ) {
      // this.registerForm.get("identityInfo").disable();
      this.onStatusUpdate.emit(true);
    }
    //PATCH for Enabling Next Button Ends
  }

  onValueChange(value: Date): void {
    this.myDateValue = value;
    console.log(this.myDateValue);
  }

  disableNonUpdatebleFields() {
    var fieldsArr = [
      // "firstName",
      // "lastName",
      // "dob",
      "mobileNumber",
      "countryCode"
    ];
    for (var i = 0; i < fieldsArr.length; i++) {
      if (
        this.endUser[fieldsArr[i]] &&
        this.endUser[fieldsArr[i]].length &&
        this.endUser.isUpdate
      ) {
        this.registerForm.get(fieldsArr[i]);
        // this.registerForm.get(fieldsArr[i]).setValue(null);
      }
    }

    if (
      this.endUser["identityInfo"] &&
      this.endUser["identityInfoType"] != "GUID" &&
      this.endUser["identityInfo"].length
    ) {
      // this.registerForm.get("identityInfo").disable();
      // this.onStatusUpdate.emit(true);
    }
    // else if(this.endUser['identityInfoType'] && this.endUser['identityInfoType'] == "GUID"){
    else if (this.endUser["identityInfoType"] == "GUID") {
      // this.registerForm.get('identityInfo').setValidators([Validators.required , Validators.email]);
      let obj = {
        canUpdate: true,
        guid: this.endUser["guid"],
        username: this.endUser["username"]
      };
      this.canUpdateUserEmail.emit(obj);
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }

  shouldDisableField(field) {
    if (this.endUser[field] && this.endUser[field].length) {
      return true;
    }
    return false;
  }

  showConsole(a) {
    console.log(a);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  // Getter for access to form fields
  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    console.log(this.registerForm);
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    console.log("SUCCESS!! :-)\n\n" + JSON.stringify(this.registerForm.value));
  }

  onCountrySelect() {
    let country = this.registerForm.value.country;
    console.log(country);
    if (country) {
      this._app.getAllowedDocs(country).subscribe(res => {
        (this.allowedDocs["validIdentityDoc"] = res.data["validIdentityDocs"]),
          (this.allowedDocs["validAddressDoc"] = res.data["validAddressDocs"]);
      });
    }
  }

  checkPendingChecks(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let obj = {
      // "email": this.endUser.email ,
      identityInfo: email,
      endUserType: "INDIVIDUAL"
    };
    if (re.test(email)) {
      this.getPendingChecks(obj);
    }
  }

  getPendingChecks(obj) {
    this._app.checkPendingChecks(obj).subscribe(
      res => {
        this.hasChecks = res.data;
        console.log(res);
      },
      err => {
        console.log(err);
        this.hasChecks.isUserExist = false;
        this.hasChecks.hasPendingChecks = false;
      }
    );
  }
}
