import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RetailOnboardingRoutingModule } from './retail-onboarding-routing.module';
import { RetailListComponent } from './retail-list/retail-list.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RetailDetailsComponent } from './retail-details/retail-details/retail-details.component';
import { CustomerInfoComponent } from './retail-details/customer-info/customer-info.component';
import { DigitalUploadComponent } from './retail-details/digital-upload/digital-upload.component';
import { ViewDocComponent } from './retail-details/digital-upload/view-doc/view-doc.component';
import { ChecksListComponent } from './retail-details/checks-list/checks-list.component';
import { RetailReportComponent } from './retail-details/retail-report/retail-report.component';
import { RetailRiskPolicyComponent } from './retail-details/retail-risk-policy/retail-risk-policy.component';
import { SecondaryAmlComponent } from './retail-details/secondary-aml/secondary-aml.component';
import { PrimaryAmlComponent } from './retail-details/primary-aml/primary-aml.component';
import { OverallStatusComponent } from './retail-details/overall-status/overall-status.component';
import { RetailOnboardingComponent } from './retail-onboarding/retail-onboarding.component';
import { ManualOnboardingComponent } from './retail-onboarding/manual-onboarding/manual-onboarding.component';
import { ComponentModuleModule } from '../../components/component-module/component-module.module';
import { AccountInfoComponent } from './retail-onboarding/manual-onboarding/account-info/account-info.component';
import { PrimaryHolderInfoComponent } from './retail-onboarding/manual-onboarding/primary-holder-info/primary-holder-info.component';
import { SecondaryHolderInfoComponent } from './retail-onboarding/manual-onboarding/secondary-holder-info/secondary-holder-info.component';
import { DocUploadComponent } from './retail-onboarding/manual-onboarding/doc-upload/doc-upload.component';
import { AvailableDocsPipe } from './pipes/available-docs.pipe';
import { DocUploadSComponent } from './retail-onboarding/manual-onboarding/doc-upload-s/doc-upload-s.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MainComponentComponent } from './main-component/main-component.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {NgxPaginationModule} from 'ngx-pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
    declarations: [
        RetailListComponent,
        RetailDetailsComponent,
        CustomerInfoComponent,
        DigitalUploadComponent,
        ViewDocComponent,
        ChecksListComponent,
        RetailReportComponent,
        RetailRiskPolicyComponent,
        SecondaryAmlComponent,
        PrimaryAmlComponent,
        OverallStatusComponent,
        RetailOnboardingComponent,
        ManualOnboardingComponent,
        AccountInfoComponent,
        PrimaryHolderInfoComponent,
        SecondaryHolderInfoComponent,
        DocUploadComponent,
        AvailableDocsPipe,
        DocUploadSComponent,
        MainComponentComponent,
    ],
    imports: [
        CommonModule,
        ComponentModuleModule,
        FormsModule,
        NgxPaginationModule,
        BsDropdownModule.forRoot(),
        ReactiveFormsModule,
        RetailOnboardingRoutingModule,
        PaginationModule.forRoot(),
        InfiniteScrollModule,
        NgCircleProgressModule.forRoot({
            backgroundGradient: true,
            backgroundColor: '#ffffff',
            backgroundGradientStopColor: '#c0c0c0',
            backgroundPadding: -10,
            radius: 60,
            maxPercent: 100,
            outerStrokeWidth: 10,
            outerStrokeColor: '#61A9DC',
            innerStrokeWidth: 0,
            subtitleColor: '#444444',
            showBackground: false,
        }),
    ]
})
export class RetailOnboardingModule { }
