import { Component, OnInit } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import { IttsServicesService } from '../services/itts-services.service'

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss']
})
export class SearchListComponent implements OnInit {

  constructor(private ittsService: IttsServicesService, private dataService: DataService) { }
  list: any;
  total: any;
  page = 0;
  itemsPerPage = 10;
  maxSize = 5;
  jump: any;
  checkListId: any;
  isReport:boolean = false;
  checkType:any;

  ngOnInit() {
    this.getList(this.page, this.itemsPerPage)
  }

  getList(page, size){
    this.dataService.changeLoaderVisibility(true);
    this.ittsService.ittsCorporateAmlList(page, size).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) =>{
      this.list = res.content;
      this.total = res.totalElements;
    });
  }

  onPageChange({ page, itemsPerPage }: PageChangedEvent) {
    this.jump = '';
    this.itemsPerPage = itemsPerPage;
    this.page = page-1;
    this.getList(this.page, this.itemsPerPage);
  }

  jumpTo(){
    this.page = this.jump-1
    this.getList(this.jump, this.itemsPerPage);
  }

  getReport(id,type){
    this.checkListId=id;
    this.checkType=type;
    this.isReport=true;
  }

}
