import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DevOpsRoutingModule } from './dev-ops-routing.module';
import { ClientListComponent } from './client-list/client-list.component';
import { LegalListComponent } from './legal-onboarding/legal-list/legal-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ThirdPartyInfoPipe } from './pipes/third-party-info.pipe';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { LegalDetailsComponent } from './legal-onboarding/legal-details/legal-details.component';
import { EntityInfoComponent } from './legal-onboarding/legal-details/entity-info/entity-info.component';
import { ControlOfficersComponent } from './legal-onboarding/legal-details/control-officers/control-officers.component';
import { DocumentsComponent } from './legal-onboarding/legal-details/documents/documents.component';
import { ChecksListComponent } from './legal-onboarding/legal-details/checks-list/checks-list.component';
import { LegalReportComponent } from './legal-onboarding/legal-details/legal-report/legal-report.component';
import { ComponentModuleModule } from '../../components/component-module/component-module.module';
import { AddDetailsComponent } from './legal-onboarding/legal-details/control-officers/add-details/add-details.component';
import { AmlStatusPipe } from './pipes/aml-status.pipe';
import { AdverseMediaStatusPipe } from './pipes/adverse-media-status.pipe';
import { ViewLegalDocumentsComponent } from './legal-onboarding/legal-details/documents/view-legal-documents/view-legal-documents.component';
import { OverallStatusComponent } from './legal-onboarding/overall-status/overall-status.component';
import { SearchListComponent } from './aml-corporate-check/search-list/search-list.component';
import { AmlCorporateReportComponent } from './aml-corporate-check/aml-corporate-report/aml-corporate-report.component';
import { RetailListComponent } from './retail-onboarding/retail-list/retail-list.component';
import { RetailDetailsComponent } from './retail-onboarding/retail-details/retail-details.component';
import { CustomerInfoComponent } from './retail-onboarding/retail-details/customer-info/customer-info.component';
import { DigitalUploadComponent } from './retail-onboarding/retail-details/digital-upload/digital-upload.component';
import { RetailRiskPolicyComponent } from './retail-onboarding/retail-details/retail-risk-policy/retail-risk-policy.component';
import { RetailReportComponent } from './retail-onboarding/retail-details/retail-report/retail-report.component';
import { RetailChecksListComponent } from './retail-onboarding/retail-details/checks-list/checks-list.component';
import { RetailOverallStatusComponent } from './retail-onboarding/overall-status/overall-status.component';
import { PrimaryAmlComponent } from './retail-onboarding/retail-details/primary-aml/primary-aml.component';
import { SecondaryAmlComponent } from './retail-onboarding/retail-details/secondary-aml/secondary-aml.component';
import { ViewDocComponent } from './retail-onboarding/retail-details/digital-upload/view-doc/view-doc.component';
import { AddAdverMediaComponent } from './add-adver-media/add-adver-media.component';
import { AddArticlesComponent } from './add-adver-media/add-articles/add-articles.component';
import { AddTagsComponent } from './add-adver-media/add-tags/add-tags.component';
import { UpdateTagsComponent } from './add-adver-media/update-tags/update-tags.component';
import { UpdateArticlesComponent } from './add-adver-media/update-articles/update-articles.component'

@NgModule({
    declarations: [ClientListComponent,
        LegalListComponent,
        ThirdPartyInfoPipe,
        LegalDetailsComponent,
        EntityInfoComponent,
        ControlOfficersComponent,
        DocumentsComponent,
        ChecksListComponent,
        LegalReportComponent,
        AddDetailsComponent,
        AmlStatusPipe,
        AdverseMediaStatusPipe,
        ViewLegalDocumentsComponent,
        OverallStatusComponent,
        SearchListComponent,
        AmlCorporateReportComponent,
        RetailListComponent,
        RetailDetailsComponent,
        CustomerInfoComponent,
        DigitalUploadComponent,
        RetailRiskPolicyComponent,
        RetailReportComponent,
        RetailChecksListComponent,
        RetailOverallStatusComponent,
        PrimaryAmlComponent,
        SecondaryAmlComponent,
        ViewDocComponent,
        AddAdverMediaComponent,
        AddArticlesComponent,
        AddTagsComponent,
        UpdateTagsComponent,
        UpdateArticlesComponent,
    ],
    imports: [
        CommonModule,
        DevOpsRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        PaginationModule.forRoot(),
        ProgressbarModule,
        ComponentModuleModule
    ]
})
export class DevOpsModule { }
