import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AmlCorporateRoutingModule } from './aml-corporate-routing.module';
import { AmlCorporateComponent } from './aml-corporate.component';
import { EntitySearchComponent } from './entity-search/entity-search.component';
import { SearchListComponent } from './search-list/search-list.component';
import { DownloadHistoryComponent } from './download-history/download-history.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ReportComponent } from './report/report.component';
import { ComponentModuleModule } from '../../components/component-module/component-module.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [ AmlCorporateComponent, EntitySearchComponent, SearchListComponent, DownloadHistoryComponent, ReportComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
    AmlCorporateRoutingModule,
    ComponentModuleModule,
    InfiniteScrollModule,
    NgCircleProgressModule.forRoot({
      backgroundGradient: true,
      backgroundColor: '#ffffff',
      backgroundGradientStopColor: '#c0c0c0',
      backgroundPadding: -10,
      radius: 60,
      maxPercent: 100,
      outerStrokeWidth: 10,
      outerStrokeColor: '#61A9DC',
      innerStrokeWidth: 0,
      subtitleColor: '#444444',
      showBackground: false,
  }),
  ]
})
export class AmlCorporateModule { }
