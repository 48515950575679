import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../services/data.service';
import { DevOpsService } from '../../services/dev-ops.service';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss']
})
export class SearchListComponent implements OnInit {
  clientId: any;
  userName: any;

  constructor(private devOpsService: DevOpsService, private dataService: DataService, private activatedRoute: ActivatedRoute) { }
  list: any;
  total: any;
  page = 0;
  itemsPerPage = 10;
  maxSize = 5;
  jump: any;

  ngOnInit() {
    this.activatedRoute.params.subscribe(({ clientID, userName }) => {
      this.clientId = clientID;
      this.userName = userName;
    })
    this.getList(this.page, this.itemsPerPage)
  }

  getList(page, size){
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService.ittsCorporateAmlList(page, size, this.clientId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) =>{
      this.list = res.content;
      this.total = res.totalElements;
    });
  }

  onPageChange({ page, itemsPerPage }: PageChangedEvent) {
    this.jump = '';
    this.itemsPerPage = itemsPerPage;
    this.page = page-1;
    this.getList(this.page, this.itemsPerPage);
  }

  jumpTo(){
    this.page = this.jump-1
    this.getList(this.jump, this.itemsPerPage);
  }

}
