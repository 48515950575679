import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../../services/data.service';
import { DataInteractionService } from '../../../../legal-onboarding/services/data-interaction.service';
import { DevOpsService } from '../../../services/dev-ops.service';
import { AddDetailsComponent } from './add-details/add-details.component';

@Component({
  selector: 'app-control-officers',
  templateUrl: './control-officers.component.html',
  styleUrls: ['./control-officers.component.scss']
})
export class ControlOfficersComponent implements OnInit {
  accountId: any;
  name: any;
  manualList: any[];
  public bsModalRef: BsModalRef;
  systemList: any[];
  clientId: any;

  constructor(private activatedRoute: ActivatedRoute, private devOpsService: DevOpsService, private modalService: BsModalService, 
    private dataInteraction: DataInteractionService, private dataService: DataService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(({ clientID, accountId, name }) => {
      this.name = name;
      this.accountId = accountId;
      this.clientId = clientID;
      this.devOpsService.getManualUbo(clientID, accountId).subscribe((res) => {
        this.manualList = res;
      });
      this.devOpsService.getSystemUbo(clientID, accountId).subscribe((res) => {
        this.systemList = res;
      });
    })
  }

  editDetails(item: Object){
    this.dataInteraction.officers(item);    
    this.bsModalRef = this.modalService.show(AddDetailsComponent, { class: 'modal-lg' });
  }

  generateUBOs(){
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService.generateCorporateUbo(this.clientId, this.accountId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.devOpsService.getSystemUbo(this.clientId, this.accountId).subscribe((res) => {
        this.systemList = res;
      });
    });
  }

}
