import { Pipe, PipeTransform } from '@angular/core';
import { DevOpsService } from '../services/dev-ops.service';

@Pipe({
  name: 'amlStatus'
})
export class AmlStatusPipe implements PipeTransform {
  constructor(private devOpsService: DevOpsService){}

  async transform(value: any, args1: any) {
    if(value!==null){
      const response = await this.devOpsService.getAMLStatus(args1, value).toPromise()
      return response
    }
  }

}
