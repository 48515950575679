import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LegalOnboardingService } from '../../../../../legal-onboarding/services/legal-onboarding.service';
import { DataInteractionService } from '../../../../../legal-onboarding/services/data-interaction.service'
import { DatePipe } from '@angular/common';
import { DataService } from '../../../../../../services/data.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-details',
  templateUrl: './add-details.component.html',
  styleUrls: ['./add-details.component.scss']
})
export class AddDetailsComponent implements OnInit {

  addMoreDetailsForm: FormGroup = this.formBuilder.group({
    lastName: [''],
    dob: [Date],
    maritalStatus: [''],
    phoneNo: [''],
    address: [''],
    ssn: [''],
    taxID: [''],
    percentage: [''],
    status: [''],
    designation: [''],
    firstName: [''],
    email: [''],
    citizenship: [''],
});
  countries: any[];
  officer: any;

  constructor(public bsModalRef: BsModalRef, private legalServices: LegalOnboardingService, private formBuilder: FormBuilder,
     private dataInteraction: DataInteractionService, private dataService: DataService ) { }

  ngOnInit() {
    this.legalServices.getAllCountries().subscribe((res) => (this.countries = res));
    this.dataInteraction.addOfficers.subscribe((res) => {
      this.officer = res;
      let birthDate = new DatePipe('en-US').transform(res.dob, 'MM/dd/yyyy');
            this.addMoreDetailsForm.patchValue({
                firstName: res.firstName,
                lastName: res.lastName,
                email: res.email,
                dob: birthDate,
                maritalStatus: res.maritalStatus,
                citizenship: res.citizenshipCountryCode,
                address: res.residenceAddress,
                ssn: res.ssn,
                taxID: res.taxId,
                percentage: res.percentageOfOwnership,
                status: res.currentStatus,
                designation: res.designation,
                phoneNo: res.phoneNumber,
            });
    });
  }

  clickClose(){
    this.bsModalRef.hide()
  }

   saveDetails() {
    this.bsModalRef.hide()
        const payload = {
            firstName: this.addMoreDetailsForm.value.firstName,
            lastName: this.addMoreDetailsForm.value.lastName,
            maritalStatus: this.addMoreDetailsForm.value.maritalStatus,
            dob: this.addMoreDetailsForm.value.dob,
            phoneNumber: this.addMoreDetailsForm.value.phoneNo,
            email: this.addMoreDetailsForm.value.email,
            residenceAddress: this.addMoreDetailsForm.value.address,
            ssn: this.addMoreDetailsForm.value.ssn,
            taxId: this.addMoreDetailsForm.value.taxID,
            percentageOfOwnership: this.addMoreDetailsForm.value.percentage,
            currentStatus: this.addMoreDetailsForm.value.status,
            designation: this.addMoreDetailsForm.value.designation,
            controlOfficerType: this.officer && this.officer.controlOfficerType,
            citizenshipCountryCode: this.addMoreDetailsForm.value.citizenship,
        };

          if(this.officer.tpe !== null && this.officer.tpe !== undefined){
            this.dataService.changeLoaderVisibility(true);
            this.legalServices.saveControlOfficer(this.officer.tpe.account.accountId, payload).subscribe(() => {
            this.dataService.toastrShow(`Control officer updated`, 'success');
            this.legalServices.performKYC(this.officer.id).pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
            .subscribe(()=>{
            this.dataService.toastrShow(`KYC Initiated, remote link has been sent`, 'success')
            });
          });
          }
     }

}
