export class filterArray {
  key: string;
  value: string;
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import { LegalOnboardingService } from '../services/legal-onboarding.service'

@Component({
  selector: 'app-legal-list',
  templateUrl: './legal-list.component.html',
  styleUrls: ['./legal-list.component.scss']
})
export class LegalListComponent implements OnInit {
  public bsModalRef: BsModalRef;
  transactionArray: any[] = [];
  accountId: any;
  isDirectlyReport: boolean = false;


  constructor(private dataService: DataService, private legalServices: LegalOnboardingService,
    private modalService: BsModalService, private router: Router) { }
  list: any[] = [];
  array = [];
  sum = 10;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  start: number = 0;
  legalDetails: any =null;
  overallStatus: any = null;
  editManualOnboard: any = null;
  searchKey: any;
  isTrue: boolean = false;
  isFilterByStatus: boolean = false;
  byStatus: any = null;
  filterArray: filterArray[] = [
    { key: 'Initiated', value: 'INITIATED' },
    { key: 'In Progress', value: 'IN_PROGRESS' },
    { key: 'Processing', value: 'PROCESSING' },
    { key: 'Verified', value: 'VERIFIED' },
    { key: 'Failed', value: 'FAILED' },
    { key: 'Approved', value: 'APPROVED' },
    { key: 'Rejected', value: 'REJECTED' },
  ];

  ngOnInit() {
    this.getList(this.start, this.sum);
  }

  getList(page, size) {
    console.log("called");
    this.dataService.changeLoaderVisibility(true);
    this.legalServices
      .getCorporateOnboardList(page, size)
      .pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
      .subscribe((res) => {
        this.list = res.content;
        if(page==0){
          this.transactionArray=[];
        }
        this.list.forEach((element) => {
          this.transactionArray.push(element)
        });
      });
  }

  onScrollDown() {
    console.log("triggered down scroll");
    this.start += 1;
    if (this.isFilterByStatus == true) {
      this.getFilterByChange(this.start, this.sum)
    }
    else {
      this.getList(this.start, this.sum);
    }
    this.direction = "down";
  }

  gotoReport(){
    this.isDirectlyReport = true;
  }

  backFromReport(event){
    if(event === 'out'){
      this.isDirectlyReport = false;
    }
    else{
      this.isDirectlyReport = true;
    }
  }

  filterBySearch(key) {
    this.transactionArray = new Array()
    this.searchKey = key
    this.isTrue = true
    const payload = {
      "name": this.searchKey,
      "overAllStatus": this.byStatus,
      "page": this.start,
      "size": this.sum,
      "orderBy": null,
      "order": 'DESC'
    }
    this.legalServices.corporateFilterBySearch(payload).subscribe((res) => {
      this.list = res.content;
      this.list.forEach((element) => {
        this.transactionArray.push(element)
      });
    })
  }

  getAccountId(accountId){
    this.accountId = accountId    
  }

  getFilter(filter) {
    this.transactionArray = []
    this.isFilterByStatus = true;
    this.byStatus = filter
    const payload = {
      "name": this.searchKey,
      "overAllStatus": this.byStatus,
      "page": this.start,
      "size": this.sum,
      "orderBy": null,
      "order": 'DESC'
    }
    this.legalServices.corporateFilterByStatus(payload).subscribe((res) => {
      this.list = res.content;
      this.list.forEach((element) => {
        this.transactionArray.push(element)
      });
    })
  }

  getFilterByChange(page, itemsPerPage) {
    const payload = {
      "name": this.searchKey,
      "overAllStatus": this.byStatus,
      "page": page,
      "size": itemsPerPage,
      "orderBy": null,
      "order": 'DESC'
    }
    this.legalServices.corporateFilterByStatus(payload).subscribe((res) => {
      this.list = res.content;
      this.list.forEach((element) => {
        this.transactionArray.push(element)
      });
    })
  }

  clearAll() {
    this.transactionArray = new Array();
    this.start = 0;
    this.sum = 10;
    this.getList(this.start, this.sum);
    this.filterArray = new Array(
      { key: 'Initiated', value: 'INITIATED' },
      { key: 'In Progress', value: 'IN_PROGRESS' },
      { key: 'Processing', value: 'PROCESSING' },
      { key: 'Verified', value: 'VERIFIED' },
      { key: 'Failed', value: 'FAILED' },
      { key: 'Approved', value: 'APPROVED' },
      { key: 'Rejected', value: 'REJECTED' },
    );
  }


  childRefresh(){
    this.legalDetails=null;
    this.editManualOnboard=null;
    this.overallStatus=null;
  }
}
