import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageComponentResolver } from '../../resolvers/page-component.resolver';
import { LegalDetailsComponent } from './legal-details/legal-details.component';
import { LegalListComponent } from './legal-list/legal-list.component';
import { LegalMainComponentComponent } from './legal-main-component/legal-main-component.component';
import { ManualOnboardingComponent } from './legal-onboard/manual-onboarding/manual-onboarding.component';
import { OverallStatusComponent } from './overall-status/overall-status.component';

const routes: Routes = [
  { 
    path: 'legal-onboarding', 
    component: LegalMainComponentComponent,
    resolve:{ 
      compList: PageComponentResolver
    },
    runGuardsAndResolvers:'always'
   },
  { path: 'legal-details/:accountId/:name', component: LegalDetailsComponent },
  { path: 'overall-status/:accountId/:name', component: OverallStatusComponent },
  { path: 'manual-onboarding/:accountId', component: ManualOnboardingComponent },
  { path: 'manual-onboarding/:accountId/:type', component: ManualOnboardingComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LegalOnboardingRoutingModule { }
