import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../../services/data.service';
import { RetailOnboardingService } from '../../../services/retail-onboarding.service';

@Component({
  selector: 'app-doc-upload-s',
  templateUrl: './doc-upload-s.component.html',
  styleUrls: ['./doc-upload-s.component.scss']
})
export class DocUploadSComponent implements OnInit {
  @Input() state;
  @Output() step = new EventEmitter<any>();
  @Output() submit = new EventEmitter<any>();
  countries: any[];
  @Input() accountId;
  getAllData: any[];
  entityName: string;
  firstName: string = '';
  lastName: string = '';
  middleName: string = '';
  getAllDocData: any[] = new Array();
  activeCard: any;
  docName: any;
  uploadDoc: any;
  showUpload: boolean = false;
  docType: any;
  checkId: any;
  states: any[] = [];
  indexCard: any;
  docArray: any[] = [
    { key: 'IDENTITY', value: 'IDENTITY' },
    { key: 'ADDRESS', value: 'ADDRESS' },
    { key: 'OTHERS', value: 'UNKNOWN' },
  ]
  public bsModalRef: BsModalRef;
  docNameArray: any[] = [];
  adHockDocType: any;
  adHockDocName: any;
  isTrue: boolean = false;
  adHockDocId: any;
  secondaryKysNo: any;
  isRefresh: boolean = false;
  secondaryCountryCode: any;
  manualDocumentName: any;
  isDocNameOthers: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private retailService: RetailOnboardingService, private dataService: DataService, private route: Router, private modalService: BsModalService) { }

  ngOnInit() {
    this.retailService.getAllCountries().subscribe((res) => (this.countries = res));
    this.retailService.getAllIndividualInfo(this.accountId).subscribe((res) => {
      this.getAllData = res;
      if (this.getAllData.length !== 0) {
        this.secondaryCountryCode = this.getAllData[1].country.countryCode
        if(this.getAllData[1].tpe){
          this.secondaryKysNo = this.getAllData[1].tpe.kysNo;
        }
        this.retailService.getAllStates(this.getAllData[1].country.countryCode).subscribe((res) => this.states = res);
        if (this.getAllData[1].firstName !== null) {
          this.firstName = this.getAllData[1].firstName;
        }
        if (this.getAllData[1].middleName !== null) {
          this.middleName = this.getAllData[1].middleName;
        }
        if (this.getAllData[1].lastName !== null) {
          this.lastName = this.getAllData[1].lastName;
        }
        this.entityName = this.firstName + ' ' + this.middleName + ' ' + this.lastName;
      }
    });
    this.retailService.getAllCheckByAccount(this.accountId).subscribe((res) => {
      if (res.secondary.length !== 0) {
        if (res.secondary[0].allCheck.idvChecks.length !== 0) {
          for (let identity of res.secondary[0].allCheck.idvChecks[0].IDENTITY) {
            const idArray = identity
            this.getAllDocData.push(idArray);
          }
          for (let address of res.secondary[0].allCheck.idvChecks[0].ADDRESS) {
            const addressArray = address
            this.getAllDocData.push(addressArray);
          }
        }
      }
    });
  }

  checkType(type){
    this.adHockDocType = type
    this.retailService.getDocName(this.secondaryCountryCode, type).subscribe((res) => this.docNameArray = res);
    if(type === 'UNKNOWN'){
      this.isDocNameOthers = true;
      this.isTrue = true;
    }
  }

  manualDocName(){
    this.adHockDocName = this.manualDocumentName;
  }

  checkName(item){
    var str = new Array()
    str = item.split(',')
    this.adHockDocName = str[0];
    this.adHockDocId = str[1];
    this.manualDocumentName = this.adHockDocName ;
    if(this.adHockDocName !== '' && this.adHockDocName !== undefined){
      this.isTrue = true;
    }
  }

  goBack() {
    let state = {
      step: 4
    }
    this.step.emit(state);
  }

  gotoNext() {
    let obj;
    this.dataService.changeLoaderVisibility(true);
    this.retailService.getFinalSubmit(this.accountId, obj).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.dataService.toastrShow(`Submitted Successfully`, 'success');
      this.submit.emit('goToList');
      const message = {
        "message": 'Primary Holder Information Submitted Successfully',
        "page": "Primary Holder Submit",
        "geoLocation": null
      }
      this.retailService.addActivityLog(localStorage.getItem("primaryKysNo"), message).subscribe();
      const payload = {
        "message": 'Secondary Holder Information Submitted Successfully',
        "page": "Secondary Holder Submit",
        "geoLocation": null
      }
      this.retailService.addActivityLog(localStorage.getItem("secondaryKysNo"), payload).subscribe();
    },

      (err: HttpErrorResponse) => {
        const message = {
          "message": err,
          "page": "Primary Holder Submit",
          "geoLocation": null
        }
        this.retailService.addActivityLog(localStorage.getItem("primaryKysNo"), message).subscribe();
        const payload = {
          "message": err,
          "page": "Secondary Holder Submit",
          "geoLocation": null
        }
        this.retailService.addActivityLog(localStorage.getItem("secondaryKysNo"), payload).subscribe();
      }
    );
  }

  uploadBack(event) {
    this.showUpload = false;
  }

  uploadedDoc(event) {
    console.log("upload event: ", event);
    this.isRefresh = !this.isRefresh
  }

  getCheckId(checkId) {
    this.checkId = checkId;
  }

  setStateAsActive(state, i) {
    console.log(state);
    this.activeCard = state.docName;
    this.indexCard = i;
    this.docName = state.docName;
    this.uploadDoc = state;
    this.docType = state.docType;
  }

  gotoComp(comp) {
    console.log(comp);
    this.showUpload = true;
  }


  openUploadModal(template) {
    this.bsModalRef = this.modalService.show(template, {
      class: 'modal-lg',
      keyboard: false,
      backdrop: 'static'
    });
  }
}
