import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../../../../services/data.service';
import { DevOpsService } from '../../../services/dev-ops.service';
@Component({
  selector: 'app-retail-checks-list',
  templateUrl: './checks-list.component.html',
  styleUrls: ['./checks-list.component.scss']
})
export class RetailChecksListComponent implements OnInit {
  getAllCheckData: any = {};
  baselIndex : any = "The Basel AML Index is an independent annual ranking that assesses risks of money laundering and terrorist financing (ML/TF) around the world.Published by the Basel Institute on Governance since 2012, the Basel AML Index provides risk scores based on data from 17 publicly available sources such as the Financial Action Task Force (FATF), Transparency International, the World Bank and the World Economic Forum.";
  pepLength: number = 0;
  pepDetails: any[];
  crimeLength: number = 0;
  crimeDetails: any[];
  sancLength: number = 0;
  sancDetails: any[];
  adverseData: any[];
  isTagFound: boolean = false;
  tagsLength: number = 0;
  pepLengthS: number = 0;
  pepDetailsS: any[];
  crimeLengthS: number = 0;
  crimeDetailsS: any[];
  sancLengthS: number = 0;
  sancDetailsS: any[];
  adverseDataS: any[];
  isTagFoundS: boolean = false;
  tagsLengthS: number = 0;
  isSecondary: boolean = false;
  checkAMLType: any = ['CRIME', 'PEP'];
  constructor(private devOpsService: DevOpsService, private activatedRoute: ActivatedRoute,private dataService: DataService,) { }


  ngOnInit() {
    this.activatedRoute.params.subscribe(({ clientID, accountId }) => {
      this.devOpsService.getAllCheckByAccount(clientID, accountId).subscribe((res) => {
        this.getAllCheckData = res.data
        if(this.getAllCheckData?.primary?.allCheck !== null && this.getAllCheckData?.primary?.allCheck !== undefined){
          // AML Data for Primary
        if(this.getAllCheckData?.primary?.allCheck?.amlChecks?.length !== 0 && this.getAllCheckData?.primary?.allCheck?.amlChecks[0]?.amlChecks?.length !== 0){
          this.dataService.changeLoaderVisibility(true);
          for(let item of this.getAllCheckData?.primary?.allCheck?.amlChecks[0]?.amlChecks){
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'PEP' ){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                this.pepDetails = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                if(pep.category.name.toUpperCase() === 'PEP'){
                  this.pepLength += 1;
                }
              }
            }
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'CRIME' ){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                this.crimeDetails = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                if(pep.category.name.toUpperCase() === 'CRIME'){
                  this.crimeLength += 1;
                }
              }
            }
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'SANCTION' ){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                this.sancDetails = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                this.sancLength += 1;
              }
            }
          }
          this.dataService.changeLoaderVisibility(false);
        }

        // Adverse Media for Primary
        if(this.getAllCheckData?.primary?.allCheck?.amlChecks?.length !== 0 && this.getAllCheckData?.primary?.allCheck?.amlChecks[0]?.adversemediaCheck[0]?.adverseMediaFormattedData !== null){
          if(this.getAllCheckData?.primary?.allCheck?.amlChecks[0]?.adversemediaCheck[0]?.adverseMediaFormattedData?.tags?.length !==0){
            this.isTagFound = true
            this.adverseData = this.getAllCheckData?.primary?.allCheck?.amlChecks[0]?.adversemediaCheck[0]?.adverseMediaFormattedData?.tags;
            this.tagsLength = this.adverseData.length
          }
        }
        }
      });
    })
    this.activatedRoute.params.subscribe(({clientID, accountId }) => {
      this.devOpsService.getAllCheckByAccount(clientID, accountId).subscribe((res) => {
        this.getAllCheckData = res.data
        // console.log(this.getAllCheckData.secondary.length)
        if(this.getAllCheckData?.secondary?.length > 0 ){
          this.isSecondary = true;
        if(this.getAllCheckData?.secondary[0]?.allCheck !== null && this.getAllCheckData?.secondary[0]?.allCheck !== undefined){
          // AML Data for Secondary
        if(this.getAllCheckData?.secondary[0]?.allCheck?.amlChecks?.length !== 0 
          && this.getAllCheckData?.secondary[0]?.allCheck?.amlChecks !== null
          && this.getAllCheckData?.secondary[0]?.allCheck?.amlChecks !== undefined
          && this.getAllCheckData?.secondary[0]?.allCheck?.amlChecks[0]?.amlChecks?.length !== 0){
          this.dataService.changeLoaderVisibility(true);
          for(let item of this.getAllCheckData?.secondary[0]?.allCheck?.amlChecks[0]?.amlChecks){
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'PEP' ){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                this.pepDetailsS = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                if(pep.category.name.toUpperCase() === 'PEP'){
                  this.pepLengthS += 1;
                }
              }
            }
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'CRIME' ){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                this.crimeDetailsS = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                if(pep.category.name.toUpperCase() === 'CRIME'){
                  this.crimeLengthS += 1;
                }
              }
            }
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'SANCION' ){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                this.sancDetailsS = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                this.sancLengthS += 1;
              }
            }
          }
          this.dataService.changeLoaderVisibility(false);
        }

        // Adverse Media for Secondary
        if(this.getAllCheckData?.secondary[0]?.allCheck?.amlChecks !== null
          && this.getAllCheckData?.secondary[0]?.allCheck?.amlChecks !== undefined){
          if(this.getAllCheckData?.secondary[0]?.allCheck?.amlChecks.length !== 0 
            && this.getAllCheckData?.secondary[0]?.allCheck?.amlChecks[0]?.adversemediaCheck[0]?.adverseMediaFormattedData !== null){
            if(this.getAllCheckData?.secondary[0]?.allCheck?.amlChecks[0]?.adversemediaCheck[0]?.adverseMediaFormattedData?.tags?.length !==0){
              this.isTagFoundS = true
              this.adverseDataS = this.getAllCheckData?.secondary[0]?.allCheck?.amlChecks[0]?.adversemediaCheck[0]?.adverseMediaFormattedData?.tags;
              this.tagsLengthS = this.adverseData.length
            }
          }
        }
        }
        }
      });
    })
  }

}

