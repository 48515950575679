import { Component, OnInit } from '@angular/core';
import { AppService } from './../../services/app.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-as-dialog',
  templateUrl: './modal-as-dialog.component.html',
  styleUrls: ['./modal-as-dialog.component.scss']
})
export class ModalAsDialogComponent implements OnInit {
  currentTheme: string = localStorage.getItem("currentTheme");
  public onClose: Subject<any> = new Subject();
  filterArray: any[] = [
    { key: 'Initiated', value: 'INITIATED' },
    { key: 'In Progress', value: 'IN_PROGRESS' },
    { key: 'Processing', value: 'PROCESSING' },
    { key: 'Verified', value: 'VERIFIED' },
    { key: 'Failed', value: 'FAILED' },
    { key: 'Approved', value: 'APPROVED' },
    { key: 'Rejected', value: 'REJECTED' },
  ];
  filterStatus: string = 'INITIATED';
  filterAssignee: string = '';
  assignTpe: string = '';
  feaslink:string='';
  storeId: any[] = [];
  clientUserList: any[] = [];
  onboard:string='';
  startDate: any;
  endDate: any;
  tbmlPartyDetails:any;
  caseComments: any;

  constructor(public bsModalRef: BsModalRef, private _app: AppService) { }

  type: 
    | "status"
    | "assignee"
    | "custom-date"
    | "questionnaire"
    | "selectlink"
    | "archive"
    | "assignTpe"
    | "onboardReject"
    | "tbml-party-details"
    | "approve-case"
    | "reject-case"

  ngOnInit(): void {
    this.onClose.next('');
    this._app.currentTheme.subscribe((theme) => {
      this.currentTheme = theme;
    });
    var themeCall = document.getElementsByClassName('modal-content')[0];
    themeCall.classList.add(this.currentTheme ? this.currentTheme : 'theme-light');
    this.filterAssignee = this.clientUserList[0]?.userName;
    this.assignTpe = this.clientUserList[0]?.clientsUserID;
  }

  getFilterStatus(event){
    this.filterStatus = event;
  }

  getFilterAssign(event){
    this.filterAssignee = event;
  }

  getAssignTpe(event){
    this.assignTpe = event;
  }

  getCaseStatusComments(type: string){
    const data = {
      overAllStatus: type,
      decisionRemark: this.caseComments
    }
    this.onClose.next(data);
    this.bsModalRef.hide();
  }

  filterByStatus(){
    const data = {
      status: this.filterStatus,
      assignees: null,
      startDate: null,
      EndDate: null
    }
    this.onClose.next(data);
    this.bsModalRef.hide();
  }

  filterByAssignee(){
    const data = {
      status: null,
      assignees: this.filterAssignee,
      startDate: null,
      EndDate: null
    }
    this.onClose.next(data);
    this.bsModalRef.hide();
  }

  filterByDate(){
    const data = {
      status: null,
      assignees: null,
      startDate: this.startDate,
      EndDate: this.endDate
    }
    this.onClose.next(data);
    this.bsModalRef.hide();
  }

  selectTemplate(template){
    this.onClose.next(template);
    this.bsModalRef.hide();
  }

  closeModal(){
    this.bsModalRef.hide();
  }
  archiveEntries(){
    this.onClose.next(this.storeId);
    this.bsModalRef.hide();
  }

  assignedTo(){
    const data = { ids: this.storeId, clientId: this.assignTpe }
    this.onClose.next(data);
    this.bsModalRef.hide();
  }
 
  onboarding(){
    this.onClose.next("yes");
    this.bsModalRef.hide();
  }
}