import { Pipe, PipeTransform } from '@angular/core';
import { LegalOnboardingService } from '../services/legal-onboarding.service';

@Pipe({
  name: 'adverseMediaStatus'
})
export class AdverseMediaStatusPipe implements PipeTransform {
  constructor(private legalServices: LegalOnboardingService){}

  async transform(value: any) {
    if(value!==null){
      const response = await this.legalServices.getAdverseMediaStatus(value).toPromise()
      return response.riskFactor
    }
  }

}
