import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../../../../services/data.service';
import { LegalOnboardingService } from '../../../services/legal-onboarding.service';

@Component({
  selector: 'app-add-officers-details',
  templateUrl: './add-officers-details.component.html',
  styleUrls: ['./add-officers-details.component.scss']
})
export class AddOfficersDetailsComponent implements OnInit {
  @Input() state: any;
  @Output() step = new EventEmitter<any>();
  maritalStatusArray: any = [
    {key: 'Married'},
    {key: 'Unmarried'},
    {key: 'Divorced'},
    {key: 'Widowed'},
  ];
  isUSCountry: boolean = false;
  addMoreDetailsForm: FormGroup = this.formBuilder.group({
    lastName: [''],
    dob: [''],
    maritalStatus: [''],
    phoneNo: [''],
    address: [''],
    ssn: [''],
    taxID: [''],
    percentage: [''],
    status: [''],
    designation: [''],
    firstName: [''],
    email: [''],
    citizenship: ['', Validators.required]
  });
  countries: any[];
  @Input() accountId: any;

  constructor(private formBuilder: FormBuilder, private legalServices: LegalOnboardingService, private dataService: DataService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.legalServices.getAllCountries().subscribe((res) => (this.countries = res));

    this.addMoreDetailsForm.patchValue({
      firstName: this.state.name,
      email: this.state.email
    })
  }

  onCountrySelect(event){
    if(event === 'US'){
      this.isUSCountry = true
    }
    else{
      this.isUSCountry = false;
    }
  }

  updateDetails() {
    const payload = {
      "firstName": this.addMoreDetailsForm.value.firstName,
      "lastName": this.addMoreDetailsForm.value.lastName,
      "controlOfficerType": this.state.type,
      "maritalStatus": this.addMoreDetailsForm.value.maritalStatus,
      "dob": this.addMoreDetailsForm.value.dob,
      "phoneNumber": parseInt(this.addMoreDetailsForm.value.phoneNo),
      "email": this.addMoreDetailsForm.value.email,
      "residenceAddress": this.addMoreDetailsForm.value.address,
      "ssn": this.addMoreDetailsForm.value.ssn,
      "taxId": this.addMoreDetailsForm.value.taxID,
      "percentageOfOwnership": this.addMoreDetailsForm.value.percentage,
      "currentStatus": this.addMoreDetailsForm.value.status,
      "designation": this.addMoreDetailsForm.value.designation,
      "citizenshipCountryCode": this.addMoreDetailsForm.value.citizenship
    }
    this.dataService.changeLoaderVisibility(true);
    this.legalServices.updateOfficersInfo(this.accountId, payload).subscribe((res) => {
      this.dataService.toastrShow(`Information Updated`, 'success');
      this.legalServices.assignRiskforOfficers(this.state.id).subscribe(() => {
        this.dataService.changeLoaderVisibility(false);
        let state = {
          state: {
            id: this.state.id,
            countryCode: res.citizenshipCountry.countryCode,
            name: this.state.name
          },
          step: 5
        }
        this.step.emit(state);
        const payload = {
          "message": state.state['name'] + "'s Information have been updated",
          "page": "Officer Details Update",
          "geoLocation": null
        }
        this.legalServices.addActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      },
        (err: HttpErrorResponse) => {
          const payload = {
            "message": err,
            "page": "Officer Details Update",
            "geoLocation": null
          }
          this.legalServices.addActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
        }
      )
    })
  }

  gotoBack() {
    let state = {
      step: 3
    }
    this.step.emit(state);
  }

}
