import { Pipe, PipeTransform } from '@angular/core';
import { LegalOnboardingService } from '../services/legal-onboarding.service';

@Pipe({
  name: 'allDocs'
})
export class AllDocsPipe implements PipeTransform {
  constructor(private legalService: LegalOnboardingService){}

  async transform(value: any, args1: any, args2: any ){
    const response = await this.legalService.getDocName(args2, args1).toPromise()
    return response;
  }

}
