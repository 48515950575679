export class FinancialData {
  yearEnded : number;
  totalAssets: string;
  netAssets: string;
  totalLiabilities: string;
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services/data.service'
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, } from 'ng2-charts/ng2-charts';
import { IttsServicesService } from '../services/itts-services.service';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
    baselIndex: string = "With a low score on the Basel Index, the USA plays a huge part in global financial markets. It scores better than the global average in all categories, but clearly the headstart over the rest of the world is very minimal when it comes to the actual AML/CFT framework.";
    companyData: any;
    investigationData: any;
    companyOverview: any=null;
    nationalityRisk: any;
    checkAMLType: any = ['CRIME', 'PEP'];
    adverseMedia: boolean = false;
    aml: boolean = false;
    tags: any[] = [];
    isTagFound: boolean = false;
    articleData: any;
    controlOfficerList: any;
    @Input()checkListId: any;
    articledatas:any[]=[{}];
    advmedialist:boolean=false;
    adverseMediaScore: number = 0;
    adverseMediaInteliigenceScore: any;
    companyAmlData: any;
    arrayPep: any[];
    riskStatusData: any;
    arrayCrime: any[];
    countCrime: number = 0;
    countSanc: number = 0;
    countPep: number = 0;

    finDataResponse:any;
    selectedFinData:any="";
    currency:any="";
    @Input() checkType:any;
    @Input() reportType:any;
    @Output() back = new EventEmitter<boolean>();


    public lineChartData: ChartDataSets[]=[
        { data: [],
          label: 'Total Assests'
        },
        { data: [],
          label: 'Net Assests'
        },
        { data: [],
          label: 'Total Liabilities'
        }
    ];
       public lineChartOptions: any=
         {
          lineChartLegend: { position: 'left' },
          maintainAspectRatio: false,
           scales: { //you're missing this
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'Worth'
                    },
                    ticks: { // and this
                        callback: function(value, index, values) {
                            let convertedValue;
                            if(value >=1000000000000){
                                convertedValue = (value/1000000000000) + 'T'
                            }
                            else if(value >=1000000000){
                                convertedValue = (value/1000000000) + 'B'
                            }
                            else if(value >=1000000){
                                convertedValue = (value/10000000) + 'M'
                            }
                            else{
                                convertedValue=value;
                            }
                            return convertedValue;
                        }
                    }
                }]
            }
         }
       
       public lineChartLabels: any[] = [];
       public lineChartType: ChartType = 'line'
       public lineChartColors: Color[] = [
        { 
            backgroundColor: '#7eebd766',
            borderColor: '#03dfb8',
            pointBackgroundColor: '#03dfb8',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#7eebd7cc',
        },
        {
            backgroundColor: '#02a6e766',
            borderColor: '#02a6e7',
            pointBackgroundColor: '#02a6e7',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#02a6e766',
        },
        {
            backgroundColor: 'rgba(148,159,177,0.2)',
            borderColor: 'rgba(148,159,177,1)',
            pointBackgroundColor: 'rgba(148,159,177,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        }
      ];
  companyAmlCrime: any[];
  companyAmlSanc: any[];
  arraySanc: any[];

    constructor(private ittsService: IttsServicesService, private dataService: DataService, private route: ActivatedRoute) {}

    ngOnInit() {
        // this.route.params.subscribe(({ checkListId, checkType }) => {
            this.getChecklistId(this.checkListId)
            this.ittsService.ittsCompanyInfo(this.checkListId).subscribe((res) =>{
                this.companyData = res
            });
            this.ittsService.ittsInvestigationDetails(this.checkListId).subscribe((res) =>{
                this.investigationData = res
            });
            this.ittsService.ittsCompanyOverview(this.checkListId).subscribe((res) =>{
                this.companyOverview = res
            });
            this.ittsService.ittsFinancialData(this.checkListId).subscribe((financialData: FinancialData[]) => {
              if(financialData !== null && financialData !== undefined){
                this.currency=financialData['currency'];
                this.finDataResponse=financialData['graphData'];
                this.lineChartLabels = this.finDataResponse.map((service) => service.yearEnded);
                this.lineChartData[0].data = this.finDataResponse.map((service) => service.totalAssets);
                this.lineChartData[1].data = this.finDataResponse.map((service) => service.netAssets);
                this.lineChartData[2].data = this.finDataResponse.map((service) => service.totalLiabilities);
              }
            });
            this.ittsService.ittsNationalityRisk(this.checkListId).subscribe((res) =>{
                this.nationalityRisk = res
            });
            if(this.checkType==="ADVERSE_MEDIA"){
                this.adverseMedia = true
                this.getAdverseMedia()
            }else if(this.checkType==="AML"){
                this.aml = true
                this.getAmlForCompany();
            }else{
                this.adverseMedia = true;
                this.aml = true
                this.getAdverseMedia();
                this.getAmlForCompany();
            }
            this.ittsService.ittsAML(this.checkListId).subscribe((res) => {
                this.controlOfficerList = res
            });
            this.ittsService.ittsGetRiskStatus(this.checkListId).subscribe((res) =>{
                this.riskStatusData = res
            });
        // });
    }

    getChecklistId(val){
        this.checkListId = val
    }
    

    chartHovered(e){
        console.log("Event $: ", e);
        
    }
    chartClicked(e){
        // console.log("Event $: ", e);
        let dataIndex=e.active[0]._index;
        let selectedYear=this.lineChartLabels[dataIndex];
        this.selectedFinData=this.finDataResponse.find(item=>item.yearEnded==selectedYear);
        this.selectedFinData['cashInBank']=this.currency+" "+this.amountConverter(this.selectedFinData['cashInBank']);
        this.selectedFinData['netAssets']=this.currency+" "+this.amountConverter(this.selectedFinData['netAssets']);
        this.selectedFinData['totalAssets']=this.currency+" "+this.amountConverter(this.selectedFinData['totalAssets']);
        this.selectedFinData['totalLiabilities']=this.currency+" "+this.amountConverter(this.selectedFinData['totalLiabilities']);
        this.selectedFinData['turnover']=this.currency+" "+this.amountConverter(this.selectedFinData['turnover']);
        console.log("selected fin data: ",this.selectedFinData);
    }

    amountConverter(value){
        let convertedValue;
        if(value >=1000000000000){
            convertedValue = (value/1000000000000) + 'T'
        }
        else if(value >=1000000000){
            convertedValue = (value/1000000000) + 'B'
        }
        else if(value >=1000000){
            convertedValue = (value/10000000) + 'M'
        }
        else{
            convertedValue=value;
        }
        return convertedValue
    }

    getAmlForCompany(){
        this.arrayPep = new Array()
        this.arraySanc = new Array()
        this.arrayCrime = new Array()
        this.ittsService.ittsAMLforCompany(this.checkListId).subscribe((res) =>{
           if(res!==null){
            var details = res.details
            for(var i=0; details.length>i; i++){
                if(details[i].category.name.toUpperCase()==="CRIME"){
                  this.countCrime += 1;
                  this.companyAmlCrime = res.details
                }else{
                  this.countSanc += 1
                  this.companyAmlSanc = res.details
                }
            }
           }
        });
        this.ittsService.ittsAML(this.checkListId).subscribe((res) => {
            for(let content of res){
                if(content.amlCheckList.amlFormattedData!==null){
                    for(let item of content.amlCheckList.amlFormattedData.details){
                        if(item.category.name.toUpperCase()==="PEP"){
                          this.countPep += 1;
                          this.arrayPep.push(item);
                        }
                        if(item.category.name.toUpperCase()==="CRIME"){
                          this.countCrime += 1;
                          this.arrayCrime.push(item);
                        } 
                        if(item.category.name.toUpperCase()==="SANCTION"){
                          this.countSanc += 1
                          this.arraySanc.push(item);
                        }      
                        else{
                          this.countSanc += 1
                          this.arraySanc.push(item);
                        }                 
                    }
                }
            }
        });
    }

    getAdverseMedia(){
        this.ittsService.ittsadverseMediaTags(this.checkListId).subscribe((res) =>{
          if(res !== null && res !== undefined){
            this.tags = res.tags
            if(this.tags.length!==0){
                this.isTagFound = true
                var tags = this.tags
                for(var i=0; tags.length>i; i++){
                    this.adverseMediaScore += tags[i].aggregateScore;
                }
                this.adverseMediaInteliigenceScore = this.adverseMediaScore/tags.length;
            }
          }
        });
    }

    downloadReport(){
        this.dataService.changeLoaderVisibility(true);
        this.ittsService.ittsDownloadReport(this.checkListId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) =>{
            const blob = new Blob([res], { type: 'application/pdf' });
            var file = new File([blob], 'Documents.pdf', { type: 'application/pdf' });
            FileSaver.saveAs(blob, `${this.checkListId}-documents.pdf`);
        });
    }

    getArticle(val){
        this.articledatas=[];
        for (let article of val){
            this.ittsService.ittsArticle(article.id).subscribe((res) =>{
                        this.articleData = res;
                        this.articledatas.push(this.articleData);       
                    });
        }
        this.advmedialist=true;
    }
    
    goBack(){
        this.back.emit(true);
    }

}

