import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-risk-policy',
  templateUrl: './risk-policy.component.html',
  styleUrls: ['./risk-policy.component.scss']
})
export class RiskPolicyComponent implements OnInit {
  @Input() riskTemplateData;

  constructor() { }

  ngOnInit() {
  }

}
