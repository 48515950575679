import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { DevOpsService } from '../../services/dev-ops.service';

@Component({
  selector: 'app-update-tags',
  templateUrl: './update-tags.component.html',
  styleUrls: ['./update-tags.component.scss']
})
export class UpdateTagsComponent implements OnInit {
  clientId: any;
  list: any[] = [];
  taggsArray: any[] = [];

  constructor(private formBuilder: FormBuilder, private services: DevOpsService, private dataService: DataService, private route: ActivatedRoute) { }

  addAdverseForm: FormGroup = this.formBuilder.group({
    entityName: [''],
    tag: [''],
    aggregateScore: [''],
    score: [''],
    articleId: ['']
  });
  serchForm: FormGroup = this.formBuilder.group({
    search: ['']
  });

  ngOnInit() {
      this.route.params.subscribe(({ clientID }) => this.clientId = clientID);
  }

  searchFilter(){
    const entityName = this.serchForm.value.search;
    this.services.getTagsName(entityName).subscribe((res) => {
      if(res.tags.length !== 0){
        this.list = res.tags
      }
      else{
        let popup = {
          message: 'No Tag exist for the ' + entityName,
          status: 'warning'
        }
        this.dataService.toastrShow(popup.message, popup.status);
      }
    });
  }

  addAdverseDetais(){
    this.taggsArray = new Array();
    const entityName = this.addAdverseForm.value.entityName
    this.services.getTagsName(entityName).subscribe((res) => {
      for(let item of res.tags){
        this.taggsArray.push(item);
      }
      const tags = {
        "tag": this.addAdverseForm.value.tag,
        "aggregateScore": this.addAdverseForm.value.aggregateScore,
        "article": [
          {
            "id": this.addAdverseForm.value.articleId,
            "score": this.addAdverseForm.value.score,
          }  
        ]
      }
      this.taggsArray.push(tags);
      const payload = {
        "tags": this.taggsArray
      }
      this.dataService.changeLoaderVisibility(true);
      this.services.updateTagsName(entityName, this.clientId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
        let popup = {
          message: this.addAdverseForm.value.tag + ' is added for ' + entityName,
          status: 'success'
        }
        this.addAdverseForm.reset();
        this.dataService.toastrShow(popup.message, popup.status);
      });
    });
  }

  clearFilter(){
    this.serchForm.get('search').setValue(null);
    this.list.length = 0;
  }

}
