import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RiskReportComponent } from './risk-report.component';
import { ReportComponent } from './report/report.component';
import { PageComponentResolver } from '../../resolvers/page-component.resolver';

const routes: Routes = [
  {
    path: "risk-report", 
    component: RiskReportComponent,
    resolve:{ compList: PageComponentResolver }
  },
  {
    path:"report",
    component:ReportComponent,
    resolve:{ compList: PageComponentResolver }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RiskReportRoutingModule { }
