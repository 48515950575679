import { Component, Input, OnInit } from '@angular/core';
import { LegalOnboardingService } from '../../services/legal-onboarding.service';

@Component({
  selector: 'app-checks-list',
  templateUrl: './checks-list.component.html',
  styleUrls: ['./checks-list.component.scss']
})
export class ChecksListComponent implements OnInit {
  anyOfficerFound: boolean = false;
  checkListEntries: any[] = [];
  @Input() accountId;
  checkListArray: any = [];
  numberData: number = 0;

  constructor(private legalServices: LegalOnboardingService) { }


  ngOnInit() {
    this.legalServices.getCheckList(this.accountId).subscribe((res) => {
      if (res !== null && res !== undefined) {
        this.checkListEntries = res.entries;
        this.anyOfficerFound = true;
      }
      for(let item of this.checkListEntries){
        this.numberData += 1;
        this.getIndexValue(this.numberData-1);
      }
    });    
  }

  getIndexValue(i){
    if(this.checkListArray.indexOf(i) > -1){
      this.checkListArray.splice(this.checkListArray.indexOf(i), 1)
    }
    else{
      this.checkListArray.push(i)
    }    
  }

}
