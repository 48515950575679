import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../../../app/services/data.service';
import { RetailOnboardingService } from '../../services/retail-onboarding.service';

@Component({
  selector: 'app-primary-aml',
  templateUrl: './primary-aml.component.html',
  styleUrls: ['./primary-aml.component.scss']
})
export class PrimaryAmlComponent implements OnInit {
  @Input('type') type:any;
  getAllCheckData: any;
  baselIndex : any = "The Basel AML Index is an independent annual ranking that assesses risks of money laundering and terrorist financing (ML/TF) around the world.Published by the Basel Institute on Governance since 2012, the Basel AML Index provides risk scores based on data from 17 publicly available sources such as the Financial Action Task Force (FATF), Transparency International, the World Bank and the World Economic Forum.";
  pepLength: number = 0;
  pepDetails: any[];
  crimeLength: number = 0;
  crimeDetails: any[];
  sancLength: number = 0;
  sancDetails: any[];
  adverseData: any[];
  isTagFound: boolean = false;
  tagsLength: number = 0;
  checkAMLType: any = ['CRIME', 'PEP'];
  @Input() accountId;
  adverseMediaScore: number = 0;
  adverseMediaInteliigenceScore: number = 0;
  articledatas:any[]=[{}];
  advmedialist:boolean=false;
  articleData: any;
  
  amlCheck:boolean= true;
  adverseMediaCheck:boolean=  true;
  nationalityRisk:boolean=  true;
  idDoc:boolean=  true;
  addressDoc:boolean=  true;
  selfie:boolean=  true;
  liveliness:boolean=  true;

  constructor(private retailServices: RetailOnboardingService, private dataService: DataService) { }

  ngOnInit() {
    this.getAccessList()
      this.retailServices.getAllCheckByAccount(this.accountId).subscribe((res) => {
        this.getAllCheckData = res
        // AML Data for Primary
        if(this.getAllCheckData.primary.allCheck.amlChecks.length !== 0 && this.getAllCheckData.primary.allCheck.amlChecks[0].amlChecks.length !== 0){
          this.dataService.changeLoaderVisibility(true);
          for(let item of this.getAllCheckData.primary.allCheck.amlChecks[0].amlChecks){
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'PEP' && item.kysAmlSearchForAmlCheck.amlFormattedData!=null ){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                this.pepDetails = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                for(let allPep of pep.category){
                  if(allPep.name.toUpperCase() === 'PEP'){
                    this.pepLength += 1;
                  }

                }
                
              }
            }
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'CRIME' && item.kysAmlSearchForAmlCheck.amlFormattedData!=null ){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                this.crimeDetails = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                for(let allPep of pep.category){
                  if(allPep.name.toUpperCase() === 'CRIME'){
                    this.crimeLength += 1;
                  }
                }
                
              }
            }
            if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'SANCTION' && item.kysAmlSearchForAmlCheck.amlFormattedData!=null ){
              for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                this.sancDetails = item.kysAmlSearchForAmlCheck.amlFormattedData.details;
                this.sancLength += 1;
              }
            }
          }
          this.dataService.changeLoaderVisibility(false);
        }

        // Adverse Media for Primary
        if(this.getAllCheckData.primary.allCheck.amlChecks.length !== 0 && this.getAllCheckData.primary.allCheck.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData !== null){
          if(this.getAllCheckData.primary.allCheck.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags.length !==0){
            this.isTagFound = true
            this.adverseData = this.getAllCheckData.primary.allCheck.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags;
            this.tagsLength = this.adverseData.length
          }
        }
      });
  }

  getAccessList(){
    this.retailServices.getAccessList(this.accountId).subscribe((res:any)=>{
      console.log("this is the data for restrictions ",res.data)
      if(res.data!==null){
        this.amlCheck= res.data.amlCheck;
        this.adverseMediaCheck= res.data.adverseMediaCheck;
        this.nationalityRisk= res.data.nationalityRisk;
        this.idDoc= res.data.idDoc;
        this.addressDoc= res.data.addressDoc;
        this.selfie= res.data.selfie;
        this.liveliness= res.data.liveliness;
      }
    })

  }
   // Get Articles
   getArticle(val) {
    console.log("Value: ", val);
    
    this.articledatas = [];
    for (let article of val) {
      this.retailServices.getArticle(article.id).subscribe((res) => {
        if(res.content.length > 0){
          this.articleData = res.content[0];
          this.articledatas.push(this.articleData);
        }
        console.log("Abu Hanzala: ", this.articledatas);
        
      });
    }
    this.advmedialist = true;
  }


}
