import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LegalOnboardingService } from '../../../services/legal-onboarding.service';
import { DataInteractionService } from '../../../services/data-interaction.service'
import { DatePipe } from '@angular/common';
import { DataService } from '../../../../../services/data.service';
import { finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-details',
  templateUrl: './add-details.component.html',
  styleUrls: ['./add-details.component.scss']
})
export class AddDetailsComponent implements OnInit {
  maritalStatusArray: any = [
    { key: 'Married' },
    { key: 'Unmarried' },
    { key: 'Divorced' },
    { key: 'Widowed' },
  ];
  addMoreDetailsForm: FormGroup = this.formBuilder.group({
    lastName: [''],
    dob: [''],
    maritalStatus: [''],
    phoneNo: [''],
    address: [''],
    ssn: [''],
    taxID: [''],
    percentage: [''],
    status: [''],
    designation: [''],
    firstName: [''],
    email: [''],
    citizenship: [''],
  });
  countries: any[];
  officer: any;
  public onClose: Subject<boolean> = new Subject();
  isUSCountry: boolean = false;
  accountId: any;

  constructor(public bsModalRef: BsModalRef, private legalServices: LegalOnboardingService, private formBuilder: FormBuilder,
    private dataInteraction: DataInteractionService, private dataService: DataService) { }

  ngOnInit() {
    this.onClose.next(false);
    this.legalServices.getAllCountries().subscribe((res) => (this.countries = res));
    this.dataInteraction.addOfficers.subscribe((res) => {
      this.officer = res;
      let birthDate = new DatePipe('en-US').transform(res.dob, 'yyyy-MM-dd');
      if (res.citizenshipCountryCode === 'US') {
        this.isUSCountry = true;
      }
      this.addMoreDetailsForm.patchValue({
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        dob: birthDate,
        maritalStatus: res.maritalStatus,
        citizenship: res.citizenshipCountryCode,
        address: res.residenceAddress,
        ssn: res.ssn,
        taxID: res.taxId,
        percentage: res.percentageOfOwnership,
        status: res.currentStatus,
        designation: res.designation,
        phoneNo: res.phoneNumber,
      });
    });
  }

  onCountrySelect(event) {
    if (event === 'US') {
      this.isUSCountry = true
    }
    else {
      this.isUSCountry = false;
    }
  }

  clickClose() {
    this.onClose.next(true);
    this.bsModalRef.hide()
  }

  saveDetails() {
    this.bsModalRef.hide()
    const payload = {
      firstName: this.addMoreDetailsForm.value.firstName,
      lastName: this.addMoreDetailsForm.value.lastName,
      maritalStatus: this.addMoreDetailsForm.value.maritalStatus,
      dob: this.addMoreDetailsForm.value.dob,
      phoneNumber: this.addMoreDetailsForm.value.phoneNo,
      email: this.addMoreDetailsForm.value.email,
      residenceAddress: this.addMoreDetailsForm.value.address,
      ssn: this.addMoreDetailsForm.value.ssn,
      taxId: this.addMoreDetailsForm.value.taxID,
      percentageOfOwnership: this.addMoreDetailsForm.value.percentage,
      currentStatus: this.addMoreDetailsForm.value.status,
      designation: this.addMoreDetailsForm.value.designation,
      controlOfficerType: this.officer && this.officer.controlOfficerType,
      citizenshipCountryCode: this.addMoreDetailsForm.value.citizenship,
    };
    this.dataService.changeLoaderVisibility(true);
    this.legalServices.saveControlOfficer(this.accountId, payload).subscribe((res) => {
      this.dataService.toastrShow(`Control officer updated`, 'success');
      this.onClose.next(true);
      this.bsModalRef.hide();
      this.legalServices.performKYC(this.officer.id).pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
        .subscribe(() => {
          this.dataService.toastrShow(`KYC Initiated, remote link has been sent`, 'success');
        });
      if (res['data'] && res['data'].tpe) {
        const message = {
          "message": "Officer's Information have been updated",
          "page": "Officer Details Update in user provided info",
          "geoLocation": null
        }
        this.legalServices.addActivityLog(res['data'].tpe.kysNo, message).subscribe();
      }
    });
  }

}
