import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import { RetailOnboardingService } from '../services/retail-onboarding.service';

@Component({
  selector: 'app-retail-onboarding',
  templateUrl: './retail-onboarding.component.html',
  styleUrls: ['./retail-onboarding.component.scss']
})
export class RetailOnboardingComponent implements OnInit {
  countries: any[];
  @Output() close = new EventEmitter<any>();
  basicInfoForm: FormGroup = this.formBuilder.group({
    firstName: ['', Validators.required],
    middleName: [''],
    lastName: [''],
    citizenship: ['', Validators.required],
    dob: [''],
    country: [''],
    state:[''],
    email: ['', [Validators.required, Validators.email]],
    ssn: [''],  
  });

  basicInfoFormS: FormGroup = this.formBuilder.group({
    firstName: ['', Validators.required],
    middleName: [''],
    lastName: [''],
    citizenship: ['', Validators.required],
    dob: [''],
    country: [''],
    state:[''],
    email: ['', [Validators.required, Validators.email]],
    ssn: [''],
    firstNameS: ['', Validators.required],
    middleNameS: [''],
    lastNameS: [''],
    citizenshipS: ['', Validators.required],
    emailS: ['', [Validators.required, Validators.email]],
    dobS: [''],
    countryS: [''],
    stateS:[''],
    ssnS: ['']
  });
  accountType: any = 'INDIVIDUAL';
  type: string = 'INDIVIDUAL';
  accountId: any;
  isRegisterationSuccessfull: boolean = false;
  isUSP: boolean = false;
  isUSS: boolean = false;
  statesPrim:any=[];
  statesSecdry:any=[];

  constructor(private retailService: RetailOnboardingService, private formBuilder: FormBuilder, 
    private dataService: DataService, private route: Router) { }

  ngOnInit() {
    this.retailService.getAllCountries().subscribe((res) => (this.countries = res));
  }

  checkType(type){
    this.accountType = type;
    this.statesPrim=[];
    this.statesSecdry=[];
    if(type === 'INDIVIDUAL'){
      this.type = 'INDIVIDUAL';
    }
    else if(type === 'JOINT'){
      this.type = 'JOINT';
    }
  }

  onCountrySelectP(country){
    if(country === 'US'){
      this.isUSP = true
    }   
    else{
      this.isUSP = false;
    } 
  }

  onCountrySelectS(country){
    if(country === 'US'){
      this.isUSS = true
    }   
    else{
      this.isUSS = false;
    } 
  }

  onResidentCountryChange(country,type){    
    this.retailService.getStates(country).subscribe(res=>{
      if(type=="primary"){
        this.statesPrim=res;
      }
      else if(type== "secondary"){
        this.statesSecdry=res;
      }
      console.log("prime: ",this.statesPrim)
    })
    
  }

  individualAccountRegisteration(){
    let userForm = {
      account: {
          accountNumber: null,
          accountType: this.accountType,
      },
      primaryHolder: {
          firstName: this.basicInfoForm.value.firstName,
          lastName: this.basicInfoForm.value.lastName,
          email: this.basicInfoForm.value.email,
          citizenshipCountry: this.basicInfoForm.value.citizenship,
          dateOfBirth: this.basicInfoForm.value.dob,
          middleName : this.basicInfoForm.value.middleName,
          ssn : this.basicInfoForm.value.ssn,
          residentialCountry: this.basicInfoForm.value.country
      },
      secondaryHolder: null,
      investmentProfile: null,
      financialData: null,
    };
    this.accountRegistration(userForm);
  }

  jointAccountRegisteration(){
    let userForm = {
      account: {
          accountNumber: null,
          accountType: this.accountType,
      },
      primaryHolder: {
          firstName: this.basicInfoFormS.value.firstName,
          lastName: this.basicInfoFormS.value.lastName,
          email: this.basicInfoFormS.value.email,
          citizenshipCountry: this.basicInfoFormS.value.citizenship,
          dateOfBirth: this.basicInfoFormS.value.dob,
          middleName : this.basicInfoFormS.value.middleName,
          ssn : this.basicInfoFormS.value.ssn,
          residentialCountry: this.basicInfoFormS.value.country
      },
      secondaryHolder: [
          {
              firstName: this.basicInfoFormS.value.firstNameS,
              lastName: this.basicInfoFormS.value.lastNameS,
              email: this.basicInfoFormS.value.emailS,
              citizenshipCountry: this.basicInfoFormS.value.citizenshipS,
              dateOfBirth: this.basicInfoFormS.value.dobS,
              middleName : this.basicInfoFormS.value.middleNameS,
              ssn : this.basicInfoFormS.value.ssnS ,
              residentialCountry: this.basicInfoFormS.value.countryS
          },
      ],
      investmentProfile: null,
      financialData: null,
    };
    this.accountRegistration(userForm);
  }

  goToBack(event){
    if(event === 'goToList'){
      this.close.emit()
    }
  }


  accountRegistration(payload: Object){
    localStorage.removeItem("primaryKysNo");
    localStorage.removeItem("secondaryKysNo");
    this.dataService.changeLoaderVisibility(true);
    this.retailService.getAccountRegisterForm(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) =>{
      this.dataService.toastrShow(`Account Registeration Successfull, please select any template to proceed further.`, 'success');
      this.isRegisterationSuccessfull = true;
      if(res){
        this.accountId = res.account.accountId;
        if(res.primaryHolder && res.primaryHolder.tpe){
          localStorage.setItem("primaryKysNo", res.primaryHolder.tpe.kysNo);
          const payload = {
            "message": 'New entity created as primary holder',
            "page": "Account Register",
            "geoLocation": null
          }
          this.retailService.addActivityLog(res.primaryHolder.tpe.kysNo, payload).subscribe();
        }
        if(res.secondaryHolder && res.secondaryHolder[0].tpe){
          localStorage.setItem("secondaryKysNo", res.secondaryHolder[0].tpe.kysNo);
          const payload = {
            "message": 'New entity created as secondary holder',
            "page": "Account Register",
            "geoLocation": null
          }
          this.retailService.addActivityLog(res.secondaryHolder[0].tpe.kysNo, payload).subscribe();
        }
      }
    });
  }

}
