import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataInteractionService {

  constructor() { }

  public addOfficers = new BehaviorSubject<any> ('')
  officers(value){
    this.addOfficers.next(value)
  }
}
