import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { DevOpsService } from '../../../services/dev-ops.service';

@Component({
  selector: 'app-legal-report',
  templateUrl: './legal-report.component.html',
  styleUrls: ['./legal-report.component.scss']
})
export class LegalReportComponent implements OnInit {
  primaryData: any;
  summaryInfo: any;
  manualList: any[];
  list: any[];
  checkAMLType: any = ['CRIME', 'PEP'];
  pepDetails: any[] = new Array();
  pepLength: number = 0;
  crimeDetails: any[] = new Array();
  sancDetails: any[] = new Array();
  crimeDetailsCorp: any[] = new Array();
  sancDetailsCorp: any[] = new Array();
  crimeLength: number = 0;
  sancLength: number = 0;
  isTagFound: boolean = false;
  tagsLength: number = 0;
  adverseDataUBO: any;
  adverseMediaArray: any[] = new Array();
  adverseDataDirector: any;
  adverseDataCorporate: any;
  riskStatus: any = 'LOW';
  riskScore: number = 0;
  accountId: any;
  clientID: any;
  public bsModalRef: BsModalRef
  overrideOverviewForm: FormGroup;

  constructor(private devOpsService: DevOpsService, private activatedRoute: ActivatedRoute, private dataService: DataService, 
    private bsModalService: BsModalService, private formBuilder: FormBuilder) { }

  ngOnInit(){
    this.overrideOverviewForm = this.formBuilder.group({
      overview: ['', Validators.required]
    });
    this.activatedRoute.params.subscribe(({ clientID, accountId }) =>{
      this.accountId = accountId;
      this.clientID = clientID;
    });
    this.getAllCheck();
    this.loadAccountInfo();
  }

  openDialog(template: TemplateRef<any>) {
    this.bsModalRef = this.bsModalService.show(template, { class: 'modal-dialog-centered' });
  }

  overrideCompanyOverview(){
    this.bsModalRef.hide()
    let payload = {
        "overview": this.overrideOverviewForm.value.overview
    }
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService.legalOverrideCompanyOverview(this.clientID, this.accountId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
        this.dataService.toastrShow(`Override Company Overview Successfully`, 'success')
        this.loadAccountInfo()
    });
  }

  loadAccountInfo(){
    this.devOpsService.getCorporateAccountInfo(this.clientID, this.accountId).subscribe((res) => {
      this.primaryData = res;
    });
  }

  getAllCheck() {
      this.devOpsService.getManualUbo(this.clientID, this.accountId).subscribe((res) => {
        this.manualList = res;
      });
      this.devOpsService.getDocList(this.clientID, this.accountId).subscribe((res) => {
        this.list = res;
      });
      this.devOpsService.getAllCheck(this.clientID, this.accountId).subscribe((res) => {
        if(res.length !== 0 && res !== null && res !== undefined){
          for(let content of res){

            // For CORPORATE
            if(content.CORPORATE){
              this.devOpsService.getSummary(this.clientID, content.CORPORATE[0].tpe.userName).subscribe((data) => this.summaryInfo = data );
              for(let corporateAML of content.CORPORATE){
                if(corporateAML.amlChecks.length !== 0 && corporateAML.amlChecks !== null && corporateAML.amlChecks !== undefined){
                  // AML Summary
                  for(let item of corporateAML.amlChecks[0].amlChecks){
                    if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'CRIME' ){
                      this.riskStatus = item.riskFactor
                      this.riskScore = item.riskScore
                      for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                        this.crimeDetailsCorp.push(pep);
                        if(pep.category.name.toUpperCase() === 'CRIME'){
                          this.crimeLength += 1;
                        }
                      }
                    }
                    if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'SANCTION' ){
                      this.riskStatus = item.riskFactor
                      this.riskScore = item.riskScore
                      for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                        this.sancDetailsCorp.push(pep);
                        this.sancLength += 1;
                      }
                    }
                  }

                  // Adverse Media
                  if(corporateAML.amlChecks[0].adversemediaCheck.length !== 0 
                    && corporateAML.amlChecks[0].adversemediaCheck.length !== null 
                    && corporateAML.amlChecks[0].adversemediaCheck.length !== undefined){
                      if(corporateAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData !== null){
                        if(corporateAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags.length !== 0){
                          this.isTagFound = true
                          this.adverseDataCorporate = corporateAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags;
                          this.adverseMediaArray.push(this.adverseDataCorporate);
                          this.tagsLength = this.adverseDataCorporate.length
                        }
                      }
                    }
                }
              }
            }

            // For DIRECTOR
            if(content.DIRECTOR){
              for(let directorAML of content.DIRECTOR){
                if(directorAML.amlChecks.length !== 0 && directorAML.amlChecks !== null && directorAML.amlChecks !== undefined){
                  // AML Summary
                  for(let item of directorAML.amlChecks[0].amlChecks){
                    if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'PEP' ){
                      for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                        this.pepDetails.push(pep);
                        if(pep.category.name.toUpperCase() === 'PEP'){
                          this.pepLength += 1;
                        }
                      }
                    }
                    if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'CRIME' ){
                      for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                        this.crimeDetails.push(pep);
                        if(pep.category.name.toUpperCase() === 'CRIME'){
                          this.crimeLength += 1;
                        }
                      }
                    }
                    if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'SANCTION' ){
                      for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                        this.sancDetails.push(pep);
                        this.sancLength += 1;
                      }
                    }
                  }

                  // Adverse Media
                  if(directorAML.amlChecks[0].adversemediaCheck.length !== 0 
                    && directorAML.amlChecks[0].adversemediaCheck.length !== null 
                    && directorAML.amlChecks[0].adversemediaCheck.length !== undefined){
                      if(directorAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData !== null){
                        if(directorAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags.length !== 0){
                          this.isTagFound = true
                          this.adverseDataDirector = directorAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags;
                          this.adverseMediaArray.push(this.adverseDataDirector);
                          this.tagsLength = this.adverseDataDirector.length
                        }
                      }
                    }
                }
              }
            }

            // For UBO
            if(content.UBO){
              for(let uboAML of content.UBO){
                if(uboAML.amlChecks.length !== 0 && uboAML.amlChecks !== null && uboAML.amlChecks !== undefined){
                  // AML Summary
                  for(let item of uboAML.amlChecks[0].amlChecks){
                    if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'PEP' ){
                      for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                        this.pepDetails.push(pep);
                        if(pep.category.name.toUpperCase() === 'PEP'){
                          this.pepLength += 1;
                        }
                      }
                    }
                    if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'CRIME' ){
                      for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                        this.crimeDetails.push(pep);
                        if(pep.category.name.toUpperCase() === 'CRIME'){
                          this.crimeLength += 1;
                        }
                      }
                    }
                    if(item.amlStatus.toUpperCase() === 'FAIL' && item.sourceType.toUpperCase() === 'SANCTION' ){
                      for(let pep of item.kysAmlSearchForAmlCheck.amlFormattedData.details){
                        this.sancDetails.push(pep);
                        this.sancLength += 1;
                      }
                    }
                  }

                  // Adverse Media
                  if(uboAML.amlChecks[0].adversemediaCheck.length !== 0 
                    && uboAML.amlChecks[0].adversemediaCheck.length !== null 
                    && uboAML.amlChecks[0].adversemediaCheck.length !== undefined){
                      if(uboAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData !== null){
                        if(uboAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags.length !== 0){
                          this.isTagFound = true
                          this.adverseDataUBO = uboAML.amlChecks[0].adversemediaCheck[0].adverseMediaFormattedData.tags;
                          this.adverseMediaArray.push(this.adverseDataUBO);
                          this.tagsLength = this.adverseDataUBO.length
                        }
                      }
                    }
                }
              }
            }
          }
        }
      })
  }

  // Override Status and Score
  overrideStatus(){
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService.getStatusScoreUpdate(this.clientID,this.accountId).subscribe(()=>{
        this.dataService.changeLoaderVisibility(false);
        this.dataService.toastrShow('Overrride the status and score successfully', `success`)
        this.getAllCheck();
    })
  }

   // Download Zip File By UserName

   getDocInZip() {
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService.corpDownloadReport(this.clientID, this.accountId).subscribe((res) => {
        const blob = new Blob([res], { type: 'application/zip' });
        var file = new File([blob], 'Documents.zip', { type: 'application/zip' });
        FileSaver.saveAs(blob, `${this.accountId}-documents.zip`);
        this.dataService.changeLoaderVisibility(false);
        console.log(res);
    });
  }


  gotoAccept() {
      this.dataService.changeLoaderVisibility(true);
      this.devOpsService.onboardClient(this.clientID, this.accountId).subscribe(result=>{
        this.dataService.changeLoaderVisibility(false);
        let type=result.infoType.toLowerCase();
        this.dataService.toastrShow(result.message, type);
      });
  }
  gotoReject() {
      this.dataService.changeLoaderVisibility(true);
      this.devOpsService.rejectClient(this.clientID, this.accountId).subscribe(result=>{
        this.dataService.changeLoaderVisibility(false);
        let type=result.infoType.toLowerCase();
        this.dataService.toastrShow(result.message, type);
      });
  }

}
