import { Injectable } from '@angular/core';
// import { AngularFireDatabase } from '@angular/fire/database';
// import { AngularFireAuth } from '@angular/fire/auth';
 import { Messaging } from '@angular/fire/messaging';

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { DataService } from "./data.service";
import { NotificationService } from "./notification.service";

import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import * as firebase from 'firebase/compat';

const config = {
  apiKey: "AIzaSyC2phSu9yOSmvDkZ6niMpJDo2k0pAtml1U",
  authDomain: "transacto-5f079.firebaseapp.com",
  projectId: "transacto-5f079",
  storageBucket: "transacto-5f079.appspot.com",
  messagingSenderId: "196422684069",
  appId: "1:196422684069:web:0b44c3ddc03ab6ad1a2c4c",
  measurementId: "G-17PR2MYVV8"
};


@Injectable()
export class FcmMessagingService {

  // app = initializeApp(config);
  // messging = getMessaging(this.app);

  currentMessage = new BehaviorSubject(null);
   messaging =getMessaging();

  constructor(
    private _data: DataService,
    private _noti: NotificationService,
    ) {
      // firebase.initializeApp(config);
      // if(firebase.messaging && firebase.messaging.isSupported()){
      // this.messaging = firebase.messaging();
      // }
  }
  
  requestPermission(){
    getToken(this.messaging, { vapidKey: 'BDTAKkY-MQNFK9F1zDcHR0PKcL9zdq40PuUZSrT56TaL029BV6stuSXWaA0U1VQbp2mP9laBCu8pq9DEuGIKBDk' }).then((currentToken) => {
      if (currentToken) {
        console.log("fb token received:",currentToken);
          let firebaseToken = this._data.getCookiesByName('fb_token');
          if(!firebaseToken || firebaseToken!=currentToken){
              this.updateToken(currentToken);
          }
         this.receiveMessage();
      } else {
          console.log('Notification permission granted.');
          // this.requestPermission();
      }
    }).catch((err) => {
      console.log('Unable to get permission to notify.', err);
    });
  }

  

  updateToken(firebaseToken) {
    let data={'token':firebaseToken}
        this._noti.registerDeviceId(data).subscribe((response)=>{
            // console.log(response)
            this._data.setCookieByName('fb_token', firebaseToken);
        }, error=>{
            // console.log(error);
        });
    }

  receiveMessage() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log("getting here",payload)
      this.currentMessage.next(payload)
    });
  }
}