import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DevOpsService } from '../../services/dev-ops.service';

@Component({
  selector: 'app-overall-status',
  templateUrl: './overall-status.component.html',
  styleUrls: ['./overall-status.component.scss']
})
export class OverallStatusComponent implements OnInit {
  accountId: any;
  name: any;
  docInfo: any;
  overAllStatus: any;
  controlOfficersList: any;
  officerType: any = 'Control Officers';
  list: any[];
  isType: boolean = false;
  officersList: any;
  officersDueDiligenceType: any = '';
  officersOnboardDate: any = '';
  isName: boolean = false;
  storeValueOfOfficerType: any;
  controlOfficersDocList: any[];
  isDocType: boolean = false;
  anyOfficerFound: boolean = false;
  clientID: any;
  userName: any;

  constructor(private devOpsService: DevOpsService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
      this.activatedRoute.params.subscribe(({ clientID, accountId, name, userName }) => {
        this.accountId = accountId;
        this.name = name;
        this.clientID = clientID;
        this.userName = userName;
        this.devOpsService.getDocumentsInfo(clientID, accountId).subscribe((res) => this.docInfo = res);
        this.devOpsService.getOverallStatus(clientID, accountId).subscribe((res) => this.overAllStatus = res);
        this.devOpsService.getCorporateDetails(clientID, accountId).subscribe((res) => {
          if(res.controlOfficer.length !== 0 && res.controlOfficer !== null && res.controlOfficer !== undefined){
            this.anyOfficerFound = true;
            this.controlOfficersList = res.controlOfficer;
          }
          if(res.entityDocuments.length !== 0 && res.entityDocuments !== null && res.entityDocuments !== undefined){
            this.list = res.entityDocuments;
          }
        });
      });
  }

  getOfficerType(officerType){
    this.isDocType = false
    this.isType = true;
    this.isName = false;
    this.officerType = officerType;
    this.storeValueOfOfficerType = officerType;
    this.devOpsService.getCorporateDetails(this.clientID, this.accountId).subscribe((res) => {
      if(res.controlOfficer.length !== 0 && res.controlOfficer !== null && res.controlOfficer !== undefined){
        for(let item of res.controlOfficer){
          if(item.type === this.officerType){
            this.officersList = item.list
          }
        }
      }
    });    
  }

  getOfficer(officers){
    this.isType = false;
    this.isName = true;
    this.officerType = officers
    this.devOpsService.getCorporateDetails(this.clientID, this.accountId).subscribe((res) => {
      if(res.controlOfficer.length !== 0 && res.controlOfficer !== null && res.controlOfficer !== undefined){
        for(let data of res.controlOfficer){
          if(data.type === this.storeValueOfOfficerType){
            for(let item of data.list){
              if(item.name === officers){                
                this.officersDueDiligenceType = item.dueDiligenceType
                this.officersOnboardDate = item.onBoardingDate
                this.controlOfficersDocList = item.entries
              }
            }
          }
        }
      }
    });    
  }

  getDocList(){
    this.isDocType = true;
  }

}
