import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DevOpsService } from '../../services/dev-ops.service';

@Component({
  selector: 'app-retail-overall-status',
  templateUrl: './overall-status.component.html',
  styleUrls: ['./overall-status.component.scss']
})
export class RetailOverallStatusComponent implements OnInit {

  getInfoData: any;
  accountId: any;
  name: any;
  overAllStatus: any;
  failedDocument$: Observable<number>;
  totalDocument$: Observable<number>;
  score$: Observable<number>;
  primaryData: any;
  secondaryData: any;
  isSecondary: boolean = false;
  list: any;
  userName: any;
  clientId: any;

  constructor(private devOpsService: DevOpsService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(({ clientID, userName, accountId, name }) => {
      this.accountId = accountId;
      this.name = name;
      this.userName = userName;
      this.clientId = clientID;
      this.devOpsService.getIndividualInfoByAccountID(clientID, accountId).subscribe((res) => this.getInfoData = res);
      this.devOpsService.getOverAllAccountStatus(clientID, this.accountId).subscribe((res) => this.overAllStatus = res);
      this.failedDocument$ = this.devOpsService.getOverAllFailedDocs(clientID, this.accountId);
      this.totalDocument$ = this.devOpsService.getOverAllTotalDocs(clientID, this.accountId);
      this.score$ = this.devOpsService.getOverAllScore(clientID, this.accountId);  
      this.devOpsService.getdigitalUploadList(clientID, accountId).subscribe((res) => {
        this.list = res;
        for(let item of res){
          if(item.tpe !== null && item.tpe !== undefined){
            // For Primary Data
            if(item.docType === 'IDENTITY' && item.tpe.kysTpeAccount === 'PRIMARY'){
              this.primaryData = item;              
            }
            // For Secondary Data
            if(item.docType === 'IDENTITY' && item.tpe.kysTpeAccount === 'SECONDARY'){
              this.isSecondary = true;
              this.secondaryData = item;
            }
          }
        }
      });
    })
  }


}

