import { Component, OnInit } from "@angular/core";
import { BulkUploadService } from "../bulk-upload.service";
import { ActivatedRoute , Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { KycModalComponent } from "../../../shared/kyc-modal/kyc-modal.component";

import { DataService } from "../../../services/data.service";
import { TrackingService } from '../../../services/tracking.service';
import { NotificationService } from "../../../services/notification.service";
import * as saveAs from "file-saver";

@Component({
  selector: 'app-notification-log',
  templateUrl: './notification-log.component.html',
  styleUrls: ['./notification-log.component.scss']
})
export class NotificationLogComponent implements OnInit {
  notifications: any[] = [];
  type: 'DELIVERED'|'MARKED_READ'='DELIVERED' //read | unread | all

  //Pagination
  currentPage: number = 0;
  itemsPerPage: number = 10;
  totalItems: number;
  maxSize: number;
  sort: string = "DESC";
  notificationStatus:string;
  
  id:any;
  batchIdd:number;
  buildQuery: any = {
    size: this.itemsPerPage,
    page: this.currentPage,
    sort: null,
    notificationStatus:this.type,
  };

  isApiLoading: boolean = false;
  
  status: any;

  constructor(
    private modalService: BsModalService,
    private _bulkUp     : BulkUploadService,
    private route       : ActivatedRoute,
    public router       : Router ,
    private _data       : DataService,
    private _track:TrackingService,
     private _noti: NotificationService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params["id"];
      // this.status = params["status"];
      console.log(params)
    });

    this.route.queryParams.subscribe(params => {
        this.status = params['status'];
    });
    this.batchIdd=this.id
    console.log(this.status);
    console.log(Object.assign(this.buildQuery, {batchId: this.batchIdd}));
    this.getNotification(true); 
  }
 
goback(){
  this.router.navigate([`/bulk-upload/${this.id}`],{queryParams:{status:this.status}})
}

downloadFile(filename=this.batchIdd): void {
  this._noti
    .download(filename)
    .subscribe(blob => saveAs(blob, "file.csv"));
}
redirectReports(i){
  this.router.navigate([`/user-reports/${i}`])
  console.log(i)
}
getNotification(isPaginated) {
  this.isApiLoading = true;
  this._data.changeLoaderVisibility(true);
  this._noti.getBulkNotification(this.buildQuery).subscribe(res => {
    let notifications = res.data.content;
    this.notifications = [];
    for (var i = 0; i < notifications.length; i++) {
      // notifications[i].status = this.getRandomStatus(); //for randomizing
      this.notifications.push(notifications[i]);
    }
    if (this.currentPage === 0) {
      this.totalItems = res.data.totalElements;
      this.maxSize = res.data.totalPages;
    } else {
      this.isApiLoading = false;
    }
    this.isApiLoading = false;
    this._data.changeLoaderVisibility(this.isApiLoading);
  });
}

alterNotification(index, item) {
  
}

getNotificationIcon(slug, type) {
  let notiDetail = [
    {
      slug: "api-key-generated",
      color: "bg-danger",
      icon: "fa-key"
    },
    {
      slug: "webhook",
      color: "bg-warning",
      icon: "fa-cog"
    }
  ];
  let selectedNoti = notiDetail.filter(obj => obj.slug == slug);
  if (selectedNoti && selectedNoti.length) {
    return selectedNoti[0][type];
  } else {
    return type == "color" ? "bg-primary" : "fa-info-circle";
  }
}

markRead(notificationID) {
  this._noti.markBulkNotifAsRead(notificationID).subscribe(res => {
    this.notifications.map(obj => {
      if (obj.id == notificationID) {
        obj.markedBy = res.data.markedBy;
        obj.isRead = true;
      }
      return obj;
    });
    this._data.toastrShow("Marked as Read!", "info");
  });
}

doFilter(type) {
  this.currentPage = 0;
  this.buildQuery["page"] = 0;
  this.buildQuery["notificationStatus"] = type;
  this.type=type
  console.log(this.buildQuery)
  this.getNotification(true);
}

getPaginatedData(isNext) {
  console.log("getMoreUsersData");
  this.currentPage = isNext ? this.currentPage + 1 : this.currentPage - 1;
  this.buildQuery["page"] = isNext
    ? this.buildQuery["page"] + 1
    : this.buildQuery["page"] - 1;
  if (this.currentPage < this.maxSize) {
    //   this.getNotification(true);
  }
}

getPaginatedText() {
  let a = this.currentPage * this.itemsPerPage + 1;
  let b;
  if (this.currentPage < this.maxSize - 1) {
    b = (this.currentPage + 1) * this.itemsPerPage;
  } else if (this.currentPage == this.maxSize - 1) {
    b = this.totalItems;
  }
  return a + "-" + b;
}

}

