import { Component, OnInit, ViewChild } from '@angular/core';
import { LegalListComponent } from '../legal-list/legal-list.component';

@Component({
  selector: 'app-legal-main-component',
  templateUrl: './legal-main-component.component.html',
  styleUrls: ['./legal-main-component.component.scss']
})
export class LegalMainComponentComponent implements OnInit {
  isList: boolean = true;
  @ViewChild(LegalListComponent,{static:false}) legalLst:LegalListComponent;

  constructor() { }

  ngOnInit(): void {
  }

  toggleTab(tabName){
    if(tabName === 'list'){
      this.isList = true
    }
    else{
      this.isList = false
    }
  }

  goToLegalList(){
    this.isList = true;
    this.legalLst.childRefresh();
    this.legalLst.getList(0, 10);
  }

}
