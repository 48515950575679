import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/Rx';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../services/data.service';
import { RetailOnboardingService } from '../../services/retail-onboarding.service';
import { AppService } from '../../../../services/app.service';

@Component({
  selector: 'app-manual-onboarding',
  templateUrl: './manual-onboarding.component.html',
  styleUrls: ['./manual-onboarding.component.scss']
})
export class ManualOnboardingComponent implements OnInit {
  currentTheme: string = localStorage.getItem('currentTheme');
  step = 1;
  state: any;
  public bsModalRef: BsModalRef
  isManualOnboard: boolean = true;
  riskTemplateData: any;
  isSecondary: boolean = false;
  @Input() accountId;
  @Output() close = new EventEmitter<any>();
  getAllData: any;
  entityName: string;
  firstName: string = '';
  lastName: string = '';
  middleName: string = '';
  checkArr= {
    "identityDoc": true,
    "addressDoc": true,
    "amlCheck": true,
    "adverseMediaCheck": true,
    "nationalityRisk": true,
    "questionCheck": true,
}
  questionnaireId: any=[]
  constructor(private modalService: BsModalService, 
    private _app: AppService,
    private activatedRoute: ActivatedRoute, 
    private retailService: RetailOnboardingService,
    private dataService: DataService, 
    private route: Router) { }

  ngOnInit() {
    this._app.currentTheme.subscribe(theme => {
      this.currentTheme = theme;
    });
    this.getRiskTemplateByAcccountId()
    this.getAllIndividualInfo()
    
  }
  // checkArray(str){
  //   if(this.checkArr.indexOf(str)!=-1){
  //     return true
  //   }else{
  //     return false
  //   }
  // }
  getAllIndividualInfo(){
    this.retailService.getAllIndividualInfo(this.accountId).subscribe((res) => {
      this.getAllData = res;
      if (this.getAllData.length !== 0) {
        if (this.getAllData[0].firstName !== null) {
          this.firstName = this.getAllData[0].firstName;
        }
        if (this.getAllData[0].middleName !== null) {
          this.middleName = this.getAllData[0].middleName;
        }
        if (this.getAllData[0].lastName !== null) {
          this.lastName = this.getAllData[0].lastName;
        }
        this.entityName = this.firstName + ' ' + this.middleName + ' ' + this.lastName;
      }
      for (let item of res) {
        if (item.length !== null) {
          if (item.tpe !== null && item.tpe !== undefined) {
            if (item.tpe.kysTpeAccount === 'SECONDARY') {
              this.isSecondary = true
            }
          }
        }
      }
    })
  }
  closeModal(event){
    this.bsModalRef.hide();
    this.dataService.questionnaireId.subscribe(res=>{
      let id=res;
      this.questionnaireId=[]
      this.questionnaireId.push(id)
    })
  }
  getRiskTemplateByAcccountId(){
    this.retailService.getRiskTemplateByAcccountId(this.accountId).subscribe((res)=>{
      this.riskTemplateData = res;
      this.riskTemplateData= [...this.riskTemplateData.map(e=>({
        ...e,
        checkObj:[...
          [...Object.keys(this.checkArr)].map(p=>({label:p,value:true, title:this.convertToTitle(p)})),
        {label:"liveliness",value:e?.idvCheckContainer?.idvCheckList[0].liveness || false, title:"Liveness Check"},
        {label:"selfie",value:e?.idvCheckContainer?.idvCheckList[0].selfie || false, title:"Selfie Check"}]
         
      }))]
      
  });
  }
  convertToTitle(str){
    if(str=="amlCheck"){
      return "AML Check"
    }
    else if(str=='questionCheck'){
      return "Questionnaire"
    }
    else{
      let a=[]
      a=str.split(/(?=[A-Z])/)
      a[0]=a[0].charAt(0).toUpperCase()+a[0].slice(1)
      str =a.join(" ")
      return str
    }
  }
  customiseTemplate(riskTemplateId){
    let data=this.riskTemplateData.find(e=>e.riskTemplateID===riskTemplateId);
    let temp={}
    data.checkObj.forEach(e=>{
      temp[e.label]= e.value
    })
    if(temp['selfie']==true){
      temp['identityDoc']=true
    }
    let payload={
      kysNo:localStorage.getItem("primaryKysNo"),
      riskTemplateId:riskTemplateId,
      retail:temp,
      questionnaireId:this.questionnaireId,
    }
    this.retailService.updateTemplate(payload).subscribe(res=>{

    })

  }
  abc(){
    console.log(this.riskTemplateData)
  }
  selectTemplate(template) {
    this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' })
  }

  goToMain(event) {
    this.close.emit(event);
  }

  getLinkTemplate(template, remoteLink) {
    this.bsModalRef.hide();
    this.isManualOnboard = false
    const payload = {
      accountId: this.accountId,
      riskTemplateId: template.riskTemplateID,
      remoteCheck: remoteLink,
    };
    this.dataService.changeLoaderVisibility(true);
    this.retailService.getShareLinkByAccountId(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.isManualOnboard = false;
      const payload = {
        "message": 'Risk templete has been assigned',
        "page": "Risk Template",
        "geoLocation": null
      }
      this.retailService.addActivityLog(localStorage.getItem("primaryKysNo"), payload).subscribe();
    },

      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Risk Template",
          "geoLocation": null
        }
        this.retailService.addActivityLog(localStorage.getItem("primaryKysNo"), payload).subscribe();
      }
    );
  }

  assignRiskTemplate(template, remoteLink) {
    this.bsModalRef.hide();
    const payload = {
      accountId: this.accountId,
      riskTemplateId: template.riskTemplateID,
      remoteCheck: remoteLink,
    };
    this.dataService.changeLoaderVisibility(true);
    this.retailService.getShareLinkByAccountId(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.dataService.toastrShow(`Remote link has been sent`, 'success');
      this.close.emit('goToList');
      const payload = {
        "message": 'Remote link has been sent',
        "page": "Remote Link",
        "geoLocation": null
      }
      this.retailService.addActivityLog(localStorage.getItem("primaryKysNo"), payload).subscribe();
    },

      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Remote Link",
          "geoLocation": null
        }
        this.retailService.addActivityLog(localStorage.getItem("primaryKysNo"), payload).subscribe();
      }
    );
  }

  changeStep(event) {
    this.step = event.step;
    this.state = event.state ? event.state : undefined;
    console.log("parent event: ", event.state);
  }

  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.close.emit();
  }
  openDailog(template){
    this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered modal-lg' })
    this.bsModalRef.content.closeBtnName = 'Close'; 
  }

}
