import { Pipe, PipeTransform } from '@angular/core';
import { RetailOnboardingService } from '../services/retail-onboarding.service';

@Pipe({
  name: 'availableDocs'
})
export class AvailableDocsPipe implements PipeTransform {

  constructor(private retailService: RetailOnboardingService){}

  async transform(value: any, args1: any, args2: any ){
    const response = await this.retailService.getDocName(args2, args1).toPromise()
    return response;
  }

}
