import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { finalize } from 'rxjs/operators';
import { AmlSearchService } from '../aml-search/aml-search.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-aml-screening',
  templateUrl: './aml-screening.component.html',
  styleUrls: ['./aml-screening.component.scss']
})
export class AmlScreeningComponent implements OnInit {
  searchData = [];
  searchDone: boolean = false;
  ifsearch: boolean = false;
  fuzzinessArray: any = [
    { key: 'Exact Match', value: 'STRICT' },
    { key: 'Near Match', value: 'FUZZY' },
    { key: 'Broad Match', value: 'MOREFUZZY' },];
  amlSearchForm: FormGroup = this.formBuilder.group({
    firstName: [''],
    fuzzy: ['STRICT'],
  });
  getAllDetails: Object;
  array = [];
  sum = 10;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  start: number = 0;
  transactionArray: any[] = [];

  constructor(private amlSearchService: AmlSearchService, private dataService: DataService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
  }

  clear() {
    this.searchDone = false;
    this.amlSearchForm.get('firstName').setValue('');
    this.transactionArray = new Array();
    this.start = 0;
    this.sum = 10;
  }

  search(page, size) {
    let payload = {
      "size": size,
      "page": page,
      "searchType": this.amlSearchForm.value.fuzzy,
      "name": this.amlSearchForm.value.firstName
    }
    this.dataService.changeLoaderVisibility(true);
    this.amlSearchService.getFullProfileSearch(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      this.searchDone = true;
      this.searchData = res['data']['content'];
      this.searchData.forEach((element) => {
        this.transactionArray.push(element)
      });
    });
  }

  viewReport(item) {
    this.ifsearch = true;
    this.getAllDetails = item;
  }

  onScrollDown() {
    console.log("triggered down scroll");
    this.start += 1;
    this.search(this.start, this.sum)
    this.direction = "down";
  }

}
