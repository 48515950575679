import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../../services/data.service';
import { LegalOnboardingService } from '../../../services/legal-onboarding.service';

@Component({
  selector: 'app-officer-docs',
  templateUrl: './officer-docs.component.html',
  styleUrls: ['./officer-docs.component.scss']
})
export class OfficerDocsComponent implements OnInit {
  @Input() state:any;
  @Output() step = new EventEmitter<any>();
  getAllDocData: any[];
  @Input() accountId: any;
  activeCard : any;
  docName: any;
  showUpload: boolean=false;
  docType: any;
  checkId: any;
  indexCard: any;
  uploadDocuments: any;

  public bsModalRef: BsModalRef;

  constructor(private legalService: LegalOnboardingService, private activatedRoute: ActivatedRoute, private dataService: DataService,private modalService: BsModalService) { }

  ngOnInit() {
      this.legalService.getAllChecksforOfficers(this.state.id).subscribe((res) => {
        this.getAllDocData = res;
      });
  }

  gotoBack(){
    let state = {
      state : {
      name: this.state.name
      },
      step: 4
    }
    this.step.emit(state);
  }

  gotoNext(){
    this.dataService.changeLoaderVisibility(true);
    this.legalService.uboSubmit(this.state.id).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.dataService.toastrShow(`Submitted Successfully`, 'success');
      let state = {
        step: 3
      }
      this.step.emit(state);
      const payload = {
        "message": this.state.name + "'s Information have been Submitted Successfully",
        "page": "Officer Final Submit",
        "geoLocation": null
      }
      this.legalService.addActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Officer Final Submit",
          "geoLocation": null
        }
        this.legalService.addActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    )
  }

  uploadBack(event){
    this.showUpload=false;
  }

  uploadedDoc(event){
    console.log("upload event: ",event);
  }

  setStateAsActive(state, i) {
    console.log(state);
    this.uploadDocuments = state;
    this.activeCard = state.docName;
    this.docName=state.docName;
    this.docType = state.docType;
    this.indexCard = i;
  }

  getCheckId(checkId){
    console.log("ABu Hanzala: ", checkId);
    
    this.checkId = checkId;
  }

  gotoComp(comp) {
    console.log(comp);
    this.showUpload=true;
  }

  openUploadModal(template){
    this.bsModalRef = this.modalService.show(template, { 
      class: 'modal-lg',
      keyboard:false,
      backdrop:'static' 
    });
  }
}
