export class SelectedIdMessage{
    frontPhotoMessage : string;
    rearPhotoMessage  : string;
    constructor( ) {
      this.frontPhotoMessage = "" ;
      this.rearPhotoMessage = "" ;
    }
}

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from "../../../services/data.service";
import { AppService } from "../../../services/app.service";
import { first } from "rxjs/operators";

import { UserDoc } from "../../../models/UserDoc.model";
import { Verification } from "../../../models/Verification.model";

import {
  MatchAttributeStatus,
  VerificationStates,
} from "../../../utils/status";

import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { KycModalComponent } from "../../../shared/kyc-modal/kyc-modal.component";
import { TrackingService } from 'src/app/services/tracking.service';
// import { CustomValidators } from 'ng2-validation';

@Component({
  selector: "app-id-check",
  templateUrl: "./id-check.component.html",
  styleUrls: ["./id-check.component.scss"],
})
export class IdCheckComponent implements OnInit {
  // For individual componentizing of Checks
  // @Input() endUserCheck: any ;

  // For cumulative handling of ID Checks
  @Input() usersIDChecks : any[];
  @Input() basicUserData : any;
  endUserCheck           : any; //only for id-doc-check
  @Input() checkGroup           : any ;  //id-checks , address-checks , aml-checks
  // @Input() allowedDocs: any ;
  allowedDocs            : any;
  @Input() traversalState: any;
  //Emitting the current state of the Selected Check
  @Output() checkState   : EventEmitter<any> = new EventEmitter();
  emitState              : any = {};
  
  selectedId             : string;
  selectedIdMessage      : SelectedIdMessage = new SelectedIdMessage();
  
  idDoc                  : any = {};
  
  //boolean value to check if ID upload process is fresh or ID already uploaded.
  isIDUpdate             : boolean = false;
  verification           : Verification;
  
  endUserDocInfo         : any = null;

  startDate: any;
  endDate: any;

  knOptions = {
    readOnly: true,
    size: 140,
    unit: "%",
    textColor: "#ffffff",
    fontSize: "18",
    fontWeigth: "400",
    fontFamily: "Roboto",
    valueformat: "percent",
    value: 0,
    max: 250,
    trackWidth: 15,
    barWidth: 15,
    trackColor: "#e1e1e1",
    barColor: "#ff0000",
  };

  //OCR result
  ocrdata                 : any = {};
  faceUrl                 : any = null;
  
  // isCountryPresent     : boolean = false ;
  countries               : any[] = [];
  states                  : any[] = [];
  @Input() selectedCountry: any;
  
  extAllowed              : any[] = ["image/png", "image/jpeg", "image/jpg"];
  currentDate             : any;
  
  bsModalRef              : BsModalRef;
  disablegoto: boolean=false;
  disablProceed: boolean=true;
  deviceInfo: any;
  ip: any;
  
  constructor(
    private modalService: BsModalService,
    private _data: DataService,
    private _app: AppService,
    public router: Router,
    private _track:TrackingService
  ) {
    var date = new Date();
    this.currentDate = date
      .toISOString()
      .substring(0, date.toISOString().indexOf("T"));
    console.log(this.currentDate);
  }

  ngOnInit() {
    this.verification                =  new Verification();
    this.idDoc["docType"]            =  "IDENTITY";
    this.idDoc["docSerialNo"]        =  "";
    this.idDoc["docIssueDate"]       =  "";
    this.idDoc["issueYear"]          =  "";
    this.idDoc["expiryYear"]          =  "";
    this.idDoc["docExpiryDate"]      =  "";
    this.idDoc["firstNameAsPerDoc"]  =  "";
    this.idDoc["middleNameAsPerDoc"] =  "";
    this.idDoc["lastNameAsPerDoc"]   =  "";
    this.idDoc["isNameInDocDiff"]    =  "";
     this.idDoc["provinceCode"]      =  "";
    let endUserCheck = this.usersIDChecks.filter(
      obj => obj.standardCheck.slug == "id-doc-check"
    );
    if (endUserCheck.length) {
      this.endUserCheck = endUserCheck[0];
    } else {
      this.endUserCheck = null;
    }

    if (!this.allowedDocs) {
      // this.isCountryPresent = true ;
      this.getAllCountries();
    }
    this.getUserDocsDetails();
    // this.idDoc = new UserDoc();
    // this.idDoc["docType"] = "IDENTITY";
    console.log("check",this.endUserCheck);
    console.log(this.allowedDocs);
    console.log("travelsal",this.traversalState);
    if(this.endUserCheck.verificationStatus!=="CREATED"){
      this.disablegoto=true
      this.disablProceed=false;
    }
    console.log("goto step",this.disablegoto)
    console.log(this.basicUserData);
    this.onCountrySelect(this.selectedCountry);
  console.log("statuscheck",this.checkGroup)  
  }

  //Get User Doc Details , check if it already exists
  getUserDocsDetails() {
    this._data.changeLoaderVisibility(true);
    let checkGuid = this.endUserCheck["endUserCheckID"];
    this._app.getUserDocsDetails(checkGuid).subscribe(res => {
      console.log(res);
      this._data.changeLoaderVisibility(false);
      if (res.data) {
        this.isIDUpdate = true;
        this.getDocFromServer(checkGuid);
        this.idDoc["docExpiryDate"]  = res.data.endUserDocInfo["docExpiryDate"];
        // this.idDoc["docFrontUrl"] = res.data.endUserDocInfo["docFrontUrl"];
        this.idDoc["docIssueDate"]   = res.data.endUserDocInfo["docIssueDate"];
        this.idDoc["issueYear"]   = res.data.endUserDocInfo["issueYear"];
        this.idDoc["expiryYear"]   = res.data.endUserDocInfo["expiryYear"];
        this.idDoc.docExpiryDate = res.data.endUserDocInfo["docExpiryDate"]
        ? new Date(res.data.endUserDocInfo["docExpiryDate"])
        : null;
        this.idDoc.docIssueDate = res.data.endUserDocInfo["docIssueDate"]
        ? new Date(res.data.endUserDocInfo["docIssueDate"])
        : null;
        // this.idDoc["docNameSlug"] = res.data.endUserDocInfo["docNameSlug"]; ///OLD ATTRIBUTE
        this.idDoc["docSlug"]        = res.data.endUserDocInfo.documentInfo["docSlug"];
        // this.idDoc["docRearUrl"]  = res.data.endUserDocInfo["docRearUrl"];
        this.idDoc["docSerialNo"]    = res.data.endUserDocInfo["docSerialNo"];

        this.idDoc["firstNameAsPerDoc"] = res.data.endUserDocInfo["firstNameAsPerDoc"];
        this.idDoc["middleNameAsPerDoc"] = res.data.endUserDocInfo["middleNameAsPerDoc"];
        this.idDoc["lastNameAsPerDoc"] = res.data.endUserDocInfo["lastNameAsPerDoc"];
        this.idDoc["isNameInDocDiff"] = res.data.endUserDocInfo["isNameInDocDiff"];
        this.idDoc["provinceCode"]       = res.data.endUserDocInfo.province["stateCode"];

        this.idDoc["docType"] = "IDENTITY";
        // this.idDoc["countryCode"] = this.selectedCountry;

        this.verification["verificationResult"] =
          res.data["verificationResult"];
        this.verification["verificationScore"] = res.data["verificationScore"];
        this.verification["verificationStatus"] =
          res.data["verificationStatus"];
        console.log(this.verification);

        this.endUserDocInfo = res.data["endUserDocInfo"];
        this.ocrdata = res.data["ocrdata"];
        // this.faceUrl = res.data["faceUrl"];
        this.selectedId = res.data.endUserDocInfo.documentInfo["docName"];
        this.faceUrl = `data:image/png;base64,${res.data["faceUrl"]}`;

        this.selectedCountry =
          res.data.endUserDocInfo.documentInfo.country["countryName"];
        this.onCountrySelect(this.selectedCountry); //for fetching allowed docs
      } else {
        this.isIDUpdate = false;
        this.idDoc = new UserDoc();
        this.idDoc["docType"] = "IDENTITY";
      }
    });
  }

  getAllCountries() {
    this._app
      .getAllCountries()
      .pipe(first())
      .subscribe(
        res => {
          this.countries = res["data"];
        },
        err => {
          console.log(err);
        }
      );
  }

  shouldShowInputField(attr) {
    let selectedDocSlug = this.idDoc["docSlug"];
    let allowedID = this.allowedDocs.validIdentityDocs;
    let isAttrAllowed = allowedID.filter(obj => obj.docSlug == selectedDocSlug);
    if (isAttrAllowed.length) {
      if (isAttrAllowed[0].mandatoryField.indexOf(attr) > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  onCountrySelect(country) {
    console.log(country);
    let countryCode = this.countries.filter(obj=>obj.countryName == country);
    let countryObj = {
      "countryCode": countryCode[0].countryCode 
    }
    if (country) {
      this._data.changeLoaderVisibility(true);
      this._app.getAllowedDocs(country).subscribe(res => {
        // this.allowedDocs["validIdentityDoc"] = res.data["validIdentityDocs"],
        // this.allowedDocs["validAddressDoc"] = res.data["validAddressDocs"] ,
        this._data.changeLoaderVisibility(false);
        this.allowedDocs = res.data;
        console.log(this.allowedDocs);
      });

      this._app.getProvinceList(countryObj)
      .subscribe(
        (res)=>{ console.log(res); this.states = res.data ;}
      );

    }
  }

  getDocFromServer(checkGuid) {
    this._app.getDocFromServer(checkGuid).subscribe(res => {
      this.idDoc["docFrontUrl"] = `data:image/jpeg;base64,${
        res.data["frontDoc"]
      }`;
      this.idDoc["docRearUrl"] = `data:image/jpeg;base64,${
        res.data["rearDoc"]
      }`;
    });
  }

  getVerificationText(key, value, isIcon?: any) {
    let matched = VerificationStates.filter(obj => {
      if (obj.field == key && obj.status == value) {
        return obj;
      }
    });
    if (matched.length) {
      return isIcon ? matched[0].icon : matched[0].text;
    }
  }

  selectIdType(doc) {
    this.selectedId = doc.docName;
    this.idDoc.docSlug = doc.docSlug;

    let allowedID = this.allowedDocs.validIdentityDocs;
    let isAttrAllowed = allowedID.filter(obj => obj.docSlug == doc.docSlug);
    this.selectedIdMessage.rearPhotoMessage =  isAttrAllowed[0].rearPhotoMessage;
    this.selectedIdMessage.frontPhotoMessage =  isAttrAllowed[0].frontPhotoMessage;
    // this.idDoc.docName = doc.docName ;
    console.log(this.selectedId);
    console.log(this.selectedIdMessage);
  }

  fileChangeListener($event, docSlug, docUrlType) {
    var image: any = new Image();
    var file: File = $event[0] ? $event[0] : $event.target.files[0];
    console.log("FIle TOuched");
    console.log(file);
    if (file) {
      var name = file.name;
      if (this.extAllowed.indexOf(file.type) >= 0) {
        var reader = new FileReader();
        var that = this;
        that.openCropModal($event, docSlug, docUrlType);
      } else if (file.type === "application/pdf") {
        console.log("Selected pdf");
        var fileReader = new FileReader();
        var base64;
        var that = this;
        // Onload of file read the file content
        fileReader.onload = function(fileLoadedEvent) {
          base64 = fileReader.result;
          // Print data in console
          //   console.log(base64);
          // that.idDoc[docUrlType] = base64;
          that.idDoc[docUrlType+'Pdf'] = {name : file.name, file : base64};
        };
        // Convert data to base64
        fileReader.readAsDataURL(file);
        // this.idDoc[docUrlType] = result["image"];
        this.idDoc["docSlug"] = docSlug;
        console.log("::::::::;;;;", this.idDoc);
      }else {
        this._data.toastrShow(
          "File extension not supported , we support png , jpeg , jpg & pdf.",
          "error"
        );
      }
    }
  }

  onFilesChange($event, docSlug, docUrlType) {
    this.fileChangeListener($event, docSlug, docUrlType);
  }

  openCropModalCamera(event, docSlug, docUrlType) {
    const initialState = {
      title: `Crop Image`,
      type: "crop-image-b64",
      imgAsb64: event,
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
      // console.log("results", result);
      if (result && Object.keys(result).length) {
        console.log(result);
        this.idDoc[docUrlType] = result["image"];
        this.idDoc["docSlug"] = docSlug;
      }
    });
  }
  openCameraModal(type, docSlug, docUrlType) {
    // console.log({ selfie: this.selfieObj, liveness: this.livenessObj });
    const initialState = {
      title: `Capture Document`,
      type: "capture-doc",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
      //get image from camera
      if (result && Object.keys(result).length) {
        console.log(result);
        this.openCropModalCamera(result, docSlug, docUrlType);
      }
    });
  }

  openCropModal(event, docSlug, docUrlType) {
    const initialState = {
      title: `Crop Image`,
      type: "crop-image",
      loadedImageEvent: event,
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
      // console.log("results", result);
      if (result && Object.keys(result).length) {
        // console.log(result);
        this.idDoc[docUrlType] = result["image"];
        this.idDoc["docSlug"] = docSlug;
      }
    });
  }

  saveIdDocs() {
    // if(this.verification["verificationStatus"] =="SUBMITTED" ){
    //   if (!this.checkForDateValidations()) {
    //     return;
    //   }
    // }
    let dateStart = this.startDate ? new Date(this.startDate) : null;
    let dateStartOffset = dateStart ? dateStart.getTimezoneOffset() * 60000 : null;
    let normalizeStartDate = dateStart ? new Date(dateStart.getTime() - dateStartOffset).toISOString().split('T')[0]:null;

    let dateExpiry = this.endDate ? new Date(this.endDate) : null;
    let dateExpiryOffset = dateExpiry ? dateExpiry.getTimezoneOffset() * 60000 : null;
    let normalizeExpiryDate = dateExpiry ? new Date(dateExpiry.getTime() - dateExpiryOffset).toISOString().split('T')[0] : null;
    
    
    this.idDoc["docIssueDate"] = normalizeStartDate;
    this.idDoc["docExpiryDate"] = normalizeExpiryDate;

    if (!this.checkForDateValidations()) {
      return;
    }
    
    var docData = Object.assign({},this.idDoc);
    if(this.idDoc.docFrontUrlPdf || this.idDoc.docRearUrlPdf){
      docData.docFrontUrl = (this.idDoc.docFrontUrlPdf) ? this.idDoc.docFrontUrlPdf.file : ((this.idDoc.docFrontUrl) ? this.idDoc.docFrontUrl : null);
      docData.docRearUrl = (this.idDoc.docRearUrlPdf) ? this.idDoc.docRearUrlPdf.file : ((this.idDoc.docRearUrl) ? this.idDoc.docRearUrl : null);
    }else{
      docData.docFrontUrl = this.idDoc.docFrontUrl;
      docData.docRearUrl = this.idDoc.docRearUrl;
    }
    console.log(docData);
    // this.setEmitState("completed");
    let countryCode = this.countries.filter(
      obj => obj.countryName == this.selectedCountry
    );
    this.idDoc["countryCode"] = countryCode[0].countryCode;
    docData["countryCode"] = countryCode[0].countryCode;

    this._data.changeLoaderVisibility(true);
    console.log(this.idDoc);
    this._app
      .saveUserDoc(docData, this.endUserCheck["endUserCheckID"])
      .subscribe(
        res => {
          console.log(res);
          this._data.changeLoaderVisibility(false);
          this._data.toastrShow(res["message"], "info");
          // this.setEmitState("completed");

          this.verification["verificationResult"] =
            res.data["verificationResult"];
          this.verification["verificationScore"] =
            res.data["verificationScore"];
          this.verification["verificationStatus"] =
            res.data["verificationStatus"];

          this.endUserDocInfo = res.data["endUserDocInfo"];
          this.ocrdata = res.data["ocrdata"];
          this.faceUrl = `data:image/png;base64,${res.data["faceUrl"]}`;
          this._track.activityLog();
          // if(res.data["verificationStatus"] == "FAILED"){
          // 	this.setEmitState("failed");
          // }
          // else{
          // 	this.setEmitState("completed");
          // }
        },
        err => {
          // this.setEmitState("failed");
        }
      );
  }


  checkForDateValidations() {
    console.log(this.basicUserData);
    var dob =
      this.basicUserData && this.basicUserData["dob"]
        ? new Date(this.basicUserData["dob"])
        : null;
    var today = new Date();
    if (this.shouldShowInputField("SN") && !this.idDoc["docSerialNo"]) {
      this._data.toastrShow("Serial Number Cannot be Empty", "error");
      return false;
    }
    // if (this.shouldShowInputField("ISSUEDATE") && !this.idDoc["docIssueDate"]) {
    //   this._data.toastrShow("Issue Date is Required", "error");
    //   return false;
    // }
    // if (this.shouldShowInputField("ISSUEDATE") && this.idDoc["docIssueDate"]) {
    //   var docIssueDate = new Date(this.idDoc["docIssueDate"]);
    //   if (docIssueDate < dob) {
    //     this._data.toastrShow(
    //       `Issue Date cannot be below User's DOB , ${dob}`,
    //       "error"
    //     );
    //     return false;
    //   }
    //   if (docIssueDate > today) {
    //     this._data.toastrShow(
    //       `Issue Date cannot greater than Present Date`,
    //       "error"
    //     );
    //     return false;
    //   }
    // }
    // if (
    //   this.shouldShowInputField("EXPIRYDATE") &&
    //   !this.idDoc["docExpiryDate"]
    // ) {
    //   this._data.toastrShow("Expiry Date is Required", "error");
    //   return false;
    // }
    // if (
    //   this.shouldShowInputField("EXPIRYDATE") &&
    //   this.idDoc["docExpiryDate"]
    // ) {
    //   var docExpiryDate = new Date(this.idDoc["docExpiryDate"]);
    //   if (docExpiryDate < dob) {
    //     this._data.toastrShow(
    //       `Expiry Date cannot be below User's DOB , ${dob}`,
    //       "error"
    //     );
    //     return false;
    //   }
    //   if (docExpiryDate < today) {
    //     this._data.toastrShow(
    //       `Expiry Date should be greater than Present Date`,
    //       "error"
    //     );
    //     return false;
    //   }
    // }
    // if (
    //   this.shouldShowInputField("ISSUEDATE") &&
    //   this.shouldShowInputField("EXPIRYDATE")
    // ) {
    //   var docIssueDate = new Date(this.idDoc["docIssueDate"]);
    //   var docExpiryDate = new Date(this.idDoc["docExpiryDate"]);
    //   if (docExpiryDate < docIssueDate) {
    //     this._data.toastrShow(
    //       `Issue Date cannot greater than Expiry Date`,
    //       "error"
    //     );
    //     return false;
    //   }
    // }

   if(this.shouldShowInputField("state") && !this.idDoc.provinceCode){
      this._data.toastrShow(
          `Please Select the State`,
          "error"
        );
        return false;
    }
    return true;
  }

  updateCheckState($event) {
    this.checkState.emit($event);
  }

  setEmitState(state) {
    this.emitState["standardCheckGroup"] = this.endUserCheck[
      "standardCheckGroup"
    ];
    this.emitState["endUserCheckID"] = this.endUserCheck["endUserCheckID"];
    if (state == "completed") {
      this.emitState["state"] = "completed";
    } else if (state == "failed") {
      this.emitState["state"] = "failed";
    }
    this.checkState.emit(this.emitState);
  }

  goToStep(stepValue){
    console.log("called");
    let state =  this.traversalState.filter(obj=>obj.standardCheckGroup == this.traversalState[0].standardCheckGroup);
    console.log(state)
		if(state.length){
			console.log(this.traversalState);
			console.log(state[0]);
      if(!this.traversalState[0].isFinalStep){
        let traversalState = {
          "allowedState" : state[0] ,
          "goToStep" : stepValue
        }
        this.checkState.emit(traversalState);
      }else{
        {
          let traversalState = {
            "allowedState" : state[0] ,
            "goToStep" : "isFinalStep"
          }
          this.checkState.emit(traversalState);
        }
      }
		}
		// this.checkState.emit(this.traversalState);
	}
}
