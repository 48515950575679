import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../../../../services/data.service';
import * as FileSaver from 'file-saver';
import { DevOpsService } from '../../../services/dev-ops.service';
@Component({
  selector: 'app-retail-report',
  templateUrl: './retail-report.component.html',
  styleUrls: ['./retail-report.component.scss']
})
export class RetailReportComponent implements OnInit {
  primaryData: any[];
  secondaryData: any[];
  name: any;
  summaryInfo: any = {};
  faceImageUrl: any;
  docData: any = {};
  primaryIDData: Object;
  isPrimaryID: boolean = false;
  isSecondaryID: boolean = false;
  secondaryIDData: Object;
  isPrimaryAddress: boolean = false;
  isPrimaryAddressData: any[] = new Array();
  isSecondaryAddress: boolean = false;
  secondaryAddressData: any[] = new Array();
  isSecondary: boolean = false;
  getAllCheckData: any;
  riskScore: number;
  riskStatus: string;
  accountID: any;
  userNameP: any;
  isHighEntity: boolean = false;
  amlRiskScore: number;
  amlRiskStatus: string;
  clientID: any;
  
  constructor(private devOpsService: DevOpsService, private activatedRoute: ActivatedRoute,
  private dataService: DataService) { }

  ngOnInit() {
    this.getAllCheck();
  }

  getAllCheck(){
    this.activatedRoute.params.subscribe(({ clientID, accountId, name }) =>{
      this.name = name;
      this.accountID = accountId;
      this.clientID = clientID;
      this.devOpsService.getAllCheckByAccount(clientID, accountId).subscribe((res) => {
        this.getAllCheckData = res

        if(this.getAllCheckData?.primary?.allCheck !== null && this.getAllCheckData?.primary?.allCheck !== undefined){
          // AML Data to Calculate Risk Score and Risk Status
        if(this.getAllCheckData?.primary?.allCheck?.amlChecks?.length !== 0 && this.getAllCheckData?.primary?.allCheck?.amlChecks[0]?.amlChecks?.length !== 0){
          for(let item of this.getAllCheckData?.primary?.allCheck?.amlChecks[0]?.amlChecks){
            if(item.amlStatus.toUpperCase() === 'FAIL'){
              this.amlRiskScore = item.riskScore;
              this.amlRiskStatus = item.riskFactor;
              this.isHighEntity = true;
            }
          }
          if(this.getAllCheckData?.primary?.allCheck?.amlChecks[0]?.nationalityRiskCheck){
            this.riskScore = this.getAllCheckData.primary.allCheck.amlChecks[0].nationalityRiskCheck[0]?.riskScore;
            this.riskStatus = this.getAllCheckData.primary.allCheck.amlChecks[0].nationalityRiskCheck[0]?.riskStatus
          }
        }
        }
      });
      this.devOpsService.getIndividualInfoByAccountID(clientID, accountId).subscribe((res) => {
        if(res[0] !== undefined && res[0] !== null){
          this.primaryData = res[0]
          if(res[0].tpe !== null && res[0].tpe !== undefined){
            this.userNameP = res[0].tpe.userName
          }
        }
        if(res[1] !== undefined && res[1] !== null){
          this.isSecondary = true;
          this.secondaryData = res[1]
        }
        if(res[0]?.tpe !== null && res[0]?.tpe !== undefined){
          this.devOpsService.getSummary(clientID, res[0]?.tpe?.userName).subscribe((res) => this.summaryInfo = res);
        }
      });
      this.devOpsService.getdigitalUploadList(clientID, accountId).subscribe((res) =>{
        for(let item of res){
          if(item.tpe !== null && item.tpe !== undefined){
            
            // For Primary Data
            if(item.docType === 'IDENTITY' && item.tpe.kysTpeAccount === 'PRIMARY'){
              this.primaryIDData = item;
              this.isPrimaryID = true;
              this.faceImageUrl = item.extractedFace;
            }

            if(item.docType === 'ADDRESS' && item.tpe.kysTpeAccount === 'PRIMARY'){
              this.isPrimaryAddress = true;
              this.isPrimaryAddressData.push(item);
            }
            // For Secondary Data
            if(item.docType === 'IDENTITY' && item.tpe.kysTpeAccount === 'SECONDARY'){
              this.secondaryIDData = item;
              this.isSecondaryID = true;
            }
            if(item.docType === 'ADDRESS' && item.tpe.kysTpeAccount === 'SECONDARY'){
              this.isSecondaryAddress = true;
              this.secondaryAddressData.push(item);
            }
          }
        }
      })
    })
  }

  // Override Status and Score
  overrideStatus(){
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService.getStatusScoreUpdate(this.clientID,this.accountID).subscribe(()=>{
        this.dataService.changeLoaderVisibility(false);
        this.dataService.toastrShow('Overrride the status and score successfully', `success`)
        this.getAllCheck();
    })
  }

  // Download Zip File By UserName

  downloadZip() {
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService.retailDownloadReportZip(this.clientID, this.userNameP).subscribe((res) => {
        const blob = new Blob([res], { type: 'application/zip' });
        var file = new File([blob], 'Documents.zip', { type: 'application/zip' });
        FileSaver.saveAs(blob, `${this.userNameP}-documents.zip`);
        this.dataService.changeLoaderVisibility(false);
    });
  }

  gotoAccept() {
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService.onboardClient(this.clientID, this.accountID).subscribe(result=>{
      this.dataService.changeLoaderVisibility(false);
      let type=result.infoType.toLowerCase();
      this.dataService.toastrShow(result.message, type);
    });
  }

  gotoReject() {
      this.dataService.changeLoaderVisibility(true);
      this.devOpsService.rejectClient(this.clientID, this.accountID).subscribe(result=>{
        this.dataService.changeLoaderVisibility(false);
        let type=result.infoType.toLowerCase();
        this.dataService.toastrShow(result.message, type);
      });
  }

}
