import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { RetailOnboardingService } from '../../../services/retail-onboarding.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {
  @Input() state;
  @Output() step = new EventEmitter<any>()
  @Input() accountId;
  getAllData: any;
  entityName: string;
  firstName: string = '';
  lastName: string = '';
  middleName: string = '';
  financialDataIndiForm: FormGroup;
  genderArray: any[] = [
    { key: 'MALE', value: 'MALE' },
    { key: 'FEMALE', value: 'FEMALE' },
    { key: 'OTHER', value: 'OTHER' },
];
netWorthArray: any[] =[
    { key: 'Under $50,000' },
    { key: '$50,000 - $149,999' },
    { key: '$150,000 - $499,999' },
    { key: 'Over $5,000,000' },
];
riskToleranceArray: any[] = [
    { key: 'Low' },
    { key: 'Moderate' },
    { key: 'Speculative' },
    { key: 'Aggressive' },
]
docArray: any[] = [
    { key: 'IDENTITY', value: 'IDENTITY' },
    { key: 'ADDRESS', value: 'ADDRESS' },
    { key: 'LEGAL', value: 'LEGAL' },
    { key: 'FINANCE', value: 'FINANCE' },
    { key: 'CORPORATE', value: 'CORPORATE' },
];
taxArray: any[] = [
    { key: '0%-5%' },
    { key: '5%-10%' },
    { key: '10%-15%' },
    { key: '15%-above' }
]

  constructor(private retailService: RetailOnboardingService, private formBuilder: FormBuilder, private dataService: DataService) { }

  ngOnInit() {
    this.financialDataIndiForm = this.formBuilder.group({
      earningsInd: [''],
      liquidNetWorthInd: ['', Validators.required],
      taxBracketInd: [''],
      annualIncomeInd: [''],
      totalNetWorthInd: ['', Validators.required],
      capitalPreservationInd: [''],
      incomeInd: [''],
      riskToleranceInd: ['', Validators.required],
      timeHorizonInd: [''],
      stocksInd: [''],
      marginTradingInd: [''],
      mutulFundsInd: [''],
      capitalAppreciationInd: [''],
      speculationInd: [''],
      liquidityNeedsInd: [''],
      investmentKnowledgeInd: [''],
      optionTradingInd: [''],
      shortSalesInd: [''],
    });
  }

  getUpdateAccountInfo() {
    let accountForm = {
        accountNo: null,
        capitalPreservation: this.financialDataIndiForm.value.capitalPreservationInd,
        capitalAppreciation: this.financialDataIndiForm.value.capitalAppreciationInd,
        income: this.financialDataIndiForm.value.incomeInd,
        specialization: this.financialDataIndiForm.value.speculationInd,
        riskTemplate: this.financialDataIndiForm.value.riskToleranceInd,
        liquidityNeeds: this.financialDataIndiForm.value.liquidityNeedsInd,
        timeHorizon: this.financialDataIndiForm.value.timeHorizonInd,
        investmentKnowledge: this.financialDataIndiForm.value.investmentKnowledgeInd,
        stocks: this.financialDataIndiForm.value.stocksInd,
        optionTrading: this.financialDataIndiForm.value.optionTradingInd,
        marginTrading: this.financialDataIndiForm.value.marginTradingInd,
        shortSales: this.financialDataIndiForm.value.shortSalesInd,
        mutualFunds: this.financialDataIndiForm.value.mutulFundsInd,
        earning: this.financialDataIndiForm.value.earningsInd,
        annualIncome: this.financialDataIndiForm.value.annualIncomeInd,
        liquidNetWorth: this.financialDataIndiForm.value.liquidNetWorthInd,
        totalNetWorth: this.financialDataIndiForm.value.totalNetWorthInd,
        taxBracket: this.financialDataIndiForm.value.taxBracketInd,
    };

    this.dataService.changeLoaderVisibility(true);
    this.retailService.getUpdateAccountInfo(this.accountId, accountForm).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.dataService.toastrShow(`Account Information Successfully Updated`, 'success');
      let state = {
        step : 2
      }
      this.step.emit(state)
    });
  }


}
