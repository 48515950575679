export class Role{
	firstName 		 : string ;
	middleName		 : string ;
	lastName 		 : string ;
	email 			 : string ;
	// password		 : string ;
	designation 	 : string ;
	contactNumber	 : string ;
	role			?: roleAccess;
	image 			?:string ;

	cssClass 		?:  string ;
}
export class fuzziness {
    key: string;
    value: string;
}
export class roleAccess{
	"description": string ;
	"displayName": string ;
	"slug"       : string ;
}
