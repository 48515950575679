import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { DataService } from "../../../services/data.service";
@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

	pageType	: any[]	= [
		{
			"route" : "/forgot-password",
			"type" : "send-reset-email"
		},
		{
			"route" : "/reset-password",
			"type" : "reset-by-token"
		},
		{
			"route" : "/change-password",
			"type" : "change-password"
		},
		{
			"route" : "/verify-email",
			"type" : "verify-email"
		},
		{
			"route" : "/setup-account",
			"type" : "setup-account"
		}
	];

	type	: string ;
	isVerifyEmail 	: boolean = false ;

	//URL Token Based
	queryToken  : string ;

	//Send Reset Link
	email 	: string = "";

	//Change Password
	passwordObj : {
		"oldPassword" :  string ;
		"password" : string ;
		"confirmPassword" : string ;
	} = {
		"oldPassword" : "" ,
		"password" : "" ,
		"confirmPassword" : "" 
	}

	//Reset Password
	resetPasswordObj : {
		"newPassword" :  string ;
		"confirmNewPassword" : string ;
		"token" ?: string ;
	} = {
		"newPassword" : "" ,
		"confirmNewPassword" : "" ,
		"token" : "" 
	}

	emailPattern: any = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"; 

	constructor(
		public _auth : AuthService ,
		public _data : DataService ,
		public router: Router,
		public activatedRoute: ActivatedRoute,){
  		this.activatedRoute.queryParams.subscribe((params) => {
            if(params.token){
                this.queryToken = params.token ;
				// this._data.setCookieByName("token",this.queryToken);
                if(this.removeQueryParams(this.router.url) == '/verify-email'){
                	this.isVerifyEmail = true;
                }
            }
        });
	}

	ngOnInit() {
		// console.log(this.router.url);
		// console.log(this.queryToken);
		if(this.isVerifyEmail){
			this.verifyEmail();
		}
	}

	removeQueryParams(url){
		let currentUrl = url.substring(0,url.indexOf('?')).length ? url.substring(0,url.indexOf('?')) : url;
		return currentUrl ;
	}

	getCurrentUrl(url){
		let currentUrl = this.removeQueryParams(url);
		// console.log(currentUrl);
		let type =  this.pageType.filter(function(obj){
			if(obj.route == currentUrl){
				return obj;
			}
		})[0].type;
		// console.log(type);
		// let type =  this.pageType.filter((obj)=>{obj.route == currentUrl})[0].type;
		return type;
	}

	verifyEmail(){
		this._auth.verifyEmail(this.queryToken)
		.subscribe(
			(res)=>{
				this._data.toastrShow(res["message"], "info") ,
				this.router.navigate(['/login']);
			}
		);
	}


	sendResetLink(){
		var emailObj = {
			"email" :  this.email
		}
		this._auth.sendResetLink(emailObj)
		.subscribe(
			(res)=>{
				this._data.toastrShow(res["message"], "info") ,
				this.router.navigate(['/login']);
			}
		);
	}

	changePassword(){
		this._auth.changePassword(this.passwordObj)
		.subscribe(
			(res)=>{
				this._data.toastrShow(res["message"], "info") ,
				this.router.navigate(['/login']);
			}
		);
	}

	
	resetPassword(){
		this.resetPasswordObj["token"] = this.queryToken ;
		this._auth.resetPassword(this.queryToken , this.resetPasswordObj)
		.subscribe(
			(res)=>{
				this._data.toastrShow(res["message"], "info") ,
				this.router.navigate(['/login']);
			}
		);
	}

	setUpAccount(){
		this.resetPasswordObj["token"] = this.queryToken ;
		this._auth.setUpAccount(this.queryToken , this.resetPasswordObj)
		.subscribe(
			(res)=>{
				this._data.toastrShow(res["message"], "info") ,
				this.router.navigate(['/login']);
			}
		);
	}
	isPasswordCorrect(check){
		if (check.length < 8) {
	        return false; 
	    }else
	    if (check.search(/[a-z]/i) < 0) {
	        return false
	    }else
	    if (check.search(/[0-9]/) < 0) {
	        return false; 
	    }else
	    if (check.length > 0) {
	        return true
	    }
	}
}
