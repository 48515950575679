import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DevOpsService } from '../../services/dev-ops.service';

@Component({
  selector: 'app-retail-details',
  templateUrl: './retail-details.component.html',
  styleUrls: ['./retail-details.component.scss']
})
export class RetailDetailsComponent implements OnInit {
  getInfoData: any;
  accountId: any;
  name: any;
  userName: any;
  clientId: any;

  constructor(private devOpsService: DevOpsService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(({ clientID, userName, accountId, name }) => {
      this.accountId = accountId;
      this.name = name;
      this.clientId = clientID;
      this.userName = userName;
      this.devOpsService.getIndividualInfoByAccountID(clientID, accountId).subscribe((res) => this.getInfoData = res);
    })
  }

}
