import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RetailOnboardingService {

  constructor(private http: HttpClient, @Inject('API_URL') public KYS_API_URL: any) { }

  getIndividualOnboardingFilterByAccount(id, page, size) {
    return this.http
        .post<any>(`${this.KYS_API_URL}/kys/v2.0/tpe/onboardingfilter/${id}`, {
            page: page,
            size: size,
            orderBy: 'createdDate',
            order: 'DESC',
        })
        .pipe(map((res) => res["data"]));
  }
  retailFilterBySearch(obj){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/tpe/retail/onboarding/search`, obj).pipe(map((res: any) => res["data"]));
  }

  getIndividualOnboardingFilterByStatus(id, page, size) {
    return this.http
        .post<any>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/filter-by-overallstatus/${id}`, {
            page: page,
            size: size,
            orderBy: 'createdDate',
            order: 'DESC',
        })
        .pipe(map((res) => res["data"]));
  }

  getDocumentVerificationResult(docId: any) {
    return this.http.get<number>(`${this.KYS_API_URL}/kys/v2.0/document/check/verification-result?kysDocumentCheckV1Id=${docId}`).pipe(map((res: any) => res["data"]));
  }

  getIndividualOnboardingFilterAccount(id, page, size) {
    return this.http
        .post<any>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/filter-by-accounttype/${id}`, {
            page: page,
            size: size,
            orderBy: 'createdDate',
            order: 'DESC',
        })
        .pipe(map((res) => res["data"]));
  }

  getIndividualInfoByAccountID(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/individual/${id}`).pipe(map((res) => res["data"]));
  }

  getAccountInfoById(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/account-id/${id}`).pipe(map((res) => res["data"]));
  }

  getdigitalUploadList(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/document/check/get-by-account/${id}`).pipe(map((res) => res["data"]));
  }

  reverifyDocument(documentId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/document/check/extract-and-verify?kysDocumentCheckV1Id=${documentId}`);
}

  getAllCheckByAccount(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/account/allcheck/${id}`).pipe(map((res) => res["data"]));
  }

  getArticle(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/adverse-media/article/${id}?adverseMediaSourceType=DEVOPS`).pipe(map((res: any) => res.data));
  }

  getSummary(userName: any) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/tpe/summary/${userName}`).pipe(map((res: any) => res["data"]));
  }

  getDocumentUrl(url: string) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/document/check/imageurl`, { s3url: url }).pipe(map((res: any) => res["data"]));
  }

  getVerifiedResult(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/document/check/verification-result?kysDocumentCheckV1Id=${id}`).pipe(map((res) => res["data"]));
  }

  getFinalSubmit(id, obj){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/submit/${id}`, obj).pipe(map((res: any) => res["data"]));
  }

  getOverAllAccountStatus(accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/accountinfo/overall-status/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getOverAllFailedDocs(accountId: string) {
      return this.http.get<number>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/failed-document/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getOverAllScore(accountId: string) {
      return this.http.get<number>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/generate-score/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getOverAllTotalDocs(accountId: string) {
      return this.http.get<number>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/total-documents-uploaded/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getAllCountries() {
    return this.http.get<any>(`${this.KYS_API_URL}/country/all `).pipe(map((res) => res["data"]));
  }

  getAccountRegisterForm(payload: Object) {
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v2.0/account/register`, payload).pipe(map((res) => res["data"]));
  }

  getRiskTemplateByAcccountId(accountId) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/account/risk-policy/${accountId}`).pipe(map((res) => res["data"]));
  }

  getShareLinkByAccountId(payload: Object) {
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/populate`, payload).pipe(map((res) => res["data"]));
  }

  getAllIndividualInfo(accountId){
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/account/individual/${accountId}`).pipe(map((res) => res["data"]));
  }

  getUpdateAccountInfo(accountId, payload: Object){
    return this.http.put<any>(`${this.KYS_API_URL}/kys/v2.0/account/info/${accountId}`, payload);
  }

  getAllStates(id){
    return this.http.get(`${this.KYS_API_URL}/provinces/country/${id}`).pipe(map((res: any) => res["data"]));
  }

  getIndividualInformationByKysNo(kysNo){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/individual/kysNo/${kysNo}`).pipe(map((res: any) => res["data"]));
  }

  getUpdatePersonalInfo(accountId, personalId, payload: Object){
    return this.http.put<any>(`${this.KYS_API_URL}/kys/v2.0/account/individual/${accountId}/${personalId}`, payload);
  }

  getDocName(country, type){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/document/available/docs/${country}?type=${type}`).pipe(map((res: any) => res["data"]))
  }

  userSpecificRiskTemplate(userName){
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/user/risk/policy/${userName}`).pipe(map((res) => res["data"]));
  }

  resendRemoteLink(kysNo){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/remote/resend-link/${kysNo}`).pipe(map((res: any) => res["data"]))
  }

  onboardClient(id){
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/accept-button/${id}`).pipe(map((res) => res));
  }

  rejectClient(id){
      return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/reject-button/${id}`).pipe(map((res) => res));
  }

  retailDownloadReportZip(username) {
    let headers = new HttpHeaders({
        'Content-Type': 'application/octet-stream',
    });

    let authHttpOptions = {
        headers: headers,
        responseType: 'blob',
    };

    return this.http
        .get(`${this.KYS_API_URL}/kys/v2.0/download/${username}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/octet-stream',
            }),
            responseType: 'blob',
        })
        .pipe(
            map((res) => {
                return res;
            })
        );
  } 

  retailDownloadPdf(userName){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/individual/report/${userName}`,{
      headers: new HttpHeaders({'Content-Type' : 'application/octet-stream'}), responseType: 'blob'
    }).pipe(map((res: any) => res));
  }

  getStates(country){
    return this.http.get(`${this.KYS_API_URL}/provinces/country/${country}`).pipe(map((res: any) => res["data"]));
  }

  addActivityLog(kysNo, payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v1/tpe-activity/add/${kysNo}`, payload);
  }

  fetchAllActivityLog(accountId, payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v1/tpe-activity/get/all-activity/${accountId}`, payload);
  }
  updateTemplate(payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v1/customised-template/edit`, payload);
  }
  getAccessList(id){
    return this.http.post(`${this.KYS_API_URL}/kys/v1/customised-template/get/${id}`, null);
  }

}
